import React from 'react';
import { FaRegStar } from 'react-icons/fa';
import CodonUrls from 'urls';
import { arrayToSentence } from 'utils';
import { rexItemNomenclature, studentItemNomenclature, studentAssessmentStatusStrings } from 'sharedStrings';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';

import { UserApi, UserApiIdNameEmail } from 'types/backend/users.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { UnitApi } from 'types/backend/units.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { InstructionEnum, PositionEnum } from 'types/common.types';
import { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';

const getCustomUnitPlaceholder = (subject: string, firstName?: string | null, lastName?: string | null) => {
  if (!!(firstName && lastName)) {
    return `${firstName} ${lastName}'s Custom ${subject} Unit`;
  } else if (firstName === null || lastName === null) {
    return `Coinstructor's Custom ${subject} Unit`;
  }
  return `My Custom ${subject} Unit`;
};

export const getUnitName = ({
  unit,
  unitBelongsToThisUser,
  instructors,
  subjectName,
}: {
  unit: UnitApi
  unitBelongsToThisUser: boolean
  instructors: Array<UserApiIdNameEmail>
  subjectName: string
}) => {
  const { name, type: unitType, userId: unitUserId } = unit;
  let unitName = name;
  if (unitType === LibraryTypeEnum.User) {
    if (!unitBelongsToThisUser) {
      const otherInstructor = instructors.find(({ id }) => id === unitUserId) as UserApiIdNameEmail;
      unitName = getCustomUnitPlaceholder(subjectName, otherInstructor.firstName || null, otherInstructor.lastName || null);
    } else {
      unitName = getCustomUnitPlaceholder(subjectName);
    }
  }
  return unitName;
};

export const externalLink = (url: string, text?: string) => {
  const linkText = text || url;
  return (
    <a href={url} className="external-link" target="_blank" rel="noopener noreferrer">
      {linkText}
    </a>
  );
};

export const mailToHref = (email: string, subject?: string, body?: string) => {
  const uriSubject = subject ? encodeURIComponent(subject) : '';
  const uriBody = body ? encodeURIComponent(body) : '';
  return `mailto:${email}?subject=${uriSubject}&body=${uriBody}`;
};

export const mailToLink = (email: string, linkText?: string, subject?: string, body?: string) => {
  const linkTextDefault = linkText || email;
  const href = mailToHref(email, subject, body);
  return externalLink(href, linkTextDefault);
};

export const createMuddyLoString = (arr: Array<string | undefined>) => {
  if (arr.length === 0) {
    return '';
  }
  const { text } = arrayToSentence(arr);
  return `The Muddy ${formatAssessmentItemPlural(arr.length)} ${formatPlural('correspond', arr.length, true)} to ${text}.`;
};

export function renderRecap(assignment: EnrichedStudentAssessment, position?: PositionEnum) {
  // don't show recap for REX assessments
  if (assignment.assessType === AssessTypeEnum.Readiness) {
    return null;
  }
  return assignment.recapPointsAvailable > 0 && (
    <BetterTooltip
      content={() => (
        <>
          Recapture points on this assignment by retrying missed questions in the Study Path before the assignment {!!assignment.lateDate ? 'late' : 'due'} date.<br/>
          {externalLink(CodonUrls.HowToRecaptureKB, 'Learn More.')}
        </>
      )}
      position={position || PositionEnum.Right}
    >
      <div className="class-session-card-recap-icon">
        <FaRegStar size={18} />
      </div>
    </BetterTooltip>
  );
}

type ArrayOfThreeOrMore<T> = [T, T, T, ...Array<T>];
export function calculateHeroFontSize(len: number, sizeArray: ArrayOfThreeOrMore<number>) {
  switch (len) {
    case 1:
      return sizeArray[0];
    case 2:
      return sizeArray[1];
    case 3:
      return sizeArray[2];
    default:
      return sizeArray[sizeArray.length - 1];
  }
}

// take array of class session days and return a string that groups consecutive days together
// e.g. [1, 2, 3, 5, 7, 8, 9, 10] => '1-3, 5, 7-10'
export function formatClassDaysCoveredString(classDaysCoveredArray: Array<number>) {
  const sortedClassDaysCoveredArray = classDaysCoveredArray.sort((a, b) => a - b);
  const result = sortedClassDaysCoveredArray.reduce((acc, val, i, a) => {
    if (!i || val !== a[i - 1] + 1) {
      return [...acc, [val]];
    }
    acc[acc.length - 1].push(val);
    return acc;
  }, [] as Array<Array<number>>);
  return result.map((dayArray) => {
    if (dayArray.length === 1) {
      const [num] = dayArray;
      return `${num}`;
    }
    const [firstDay, ...rest] = dayArray;
    const lastDay = rest.pop();
    return `${firstDay}-${lastDay}`;
  }).join(', ');
}

export function fullClassesCoveredString(daysCovered: Array<number>) {
  return `${formatPlural('Class', daysCovered.length)} ${formatClassDaysCoveredString(daysCovered)}`;
}

export function uppercaseFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const numberNameArray: Array<string> = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
];

export function numberToText(n: number, capitalize?: boolean) {
  const numberName = numberNameArray[n];
  // numberName not in array, just stringify
  if (!numberName) {
    return n.toString();
  }
  return capitalize ? uppercaseFirstLetter(numberName) : numberName;
}

export function formatPlural(wordToPluralize: string, count: number, invert?: boolean) {
  const isFirstUpperCase = /^[A-Z]/.test(wordToPluralize);
  const shouldPluralize = count !== 1;
  let pluralizedWord;

  pluralizedWord = shouldPluralize ? `${wordToPluralize}s` : wordToPluralize;
  // LO is a special case
  if (new RegExp(/^LO$/, 'gi').test(wordToPluralize)) {
    pluralizedWord = shouldPluralize ? 'LOs' : 'LO';
  } else if (new RegExp(/^ha(ve|s)$/, 'gi').test(wordToPluralize)) {
    pluralizedWord = shouldPluralize ? 'have' : 'has';
  } else if (new RegExp(/^th(is|ese)$/, 'gi').test(wordToPluralize)) {
    pluralizedWord = shouldPluralize ? 'these' : 'this';
  } else if (new RegExp(/^(is|are)$/, 'gi').test(wordToPluralize)) {
    pluralizedWord = shouldPluralize ? 'are' : 'is';
  }
  // if word ends in double s (ss), pluralize with es
  if (new RegExp(/\w+ss$/, 'gi').test(wordToPluralize)) {
    pluralizedWord = shouldPluralize ? `${wordToPluralize}es` : wordToPluralize;
  }
  if (invert) {
    // handle situations where when the count is plural the word should be singular
    // e.g. questionS correspond vs question correspondS
    pluralizedWord = shouldPluralize ? wordToPluralize : `${wordToPluralize}s`;
  }
  return isFirstUpperCase ? uppercaseFirstLetter(pluralizedWord) : pluralizedWord;
}

/**
 * formatAssessmentItemPlural takes a count and returns either `question(s)` or `section(s)`
 **/
export const formatAssessmentItemPlural = (count: number, assessType?: AssessTypeEnum) => {
  const itemNomenclature = assessType === AssessTypeEnum.Readiness
    ? rexItemNomenclature
    : studentItemNomenclature;
  return formatPlural(itemNomenclature, count);
};

//this function takes points as returns a consistent number of decimal places for display
export function formatPoints(points: number) {
  if (isNaN(points)) {
    console.error(`Invalid number passed to formatPoints: ${points}`);
    return '0.00';
  }
  const formattedPoints = typeof points === 'number' ? points.toFixed(2) : parseFloat(points).toFixed(2);
  return formattedPoints;
}

export function prettifyAssessType(as: AssessTypeEnum) {
  const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
  if (as.includes('_')) {
    return as.split('_').map((word) => capitalizeFirstLetter(word)).join(' ');
  }
  return capitalizeFirstLetter(as);
}

export const getShortAssessType = (assessType: AssessTypeEnum) => {
  switch (assessType) {
    case AssessTypeEnum.Homework: return 'HW';
    case AssessTypeEnum.Preclass: return 'Pre';
    case AssessTypeEnum.Readiness: return 'Read';
    case AssessTypeEnum.PracticeTest: return 'PT';
    case AssessTypeEnum.Prep: return 'Prep';
    default: return null;
  }
};

export function getAssessTypeShortName(assessType: AssessTypeEnum) {
  switch (assessType) {
    case AssessTypeEnum.Preclass: return 'Pre-class';
    case AssessTypeEnum.Readiness: return 'Readiness';
    case AssessTypeEnum.Homework: return 'Homework';
    case AssessTypeEnum.Summative: return 'Study Path';
    default:
      return assessType;
  }
}

export function getInstructionTypeShortName(clrType: InstructionEnum) {
  switch (clrType) {
    case InstructionEnum.InClass: return 'In-class Materials';
    case InstructionEnum.OutOfClass: return 'Pre-class Resources';
    default:
      return clrType;
  }
}

export const formatName = (lastName: string | null, firstName: string | null): string => {
  if (firstName || lastName) {
    return `${lastName ? lastName : '[unknown]'}, ${firstName ? firstName : '[unknown]'}`;
  }
  return '[unknown]';
};

export const formatStringsWithAnd = (strings: Array<string>): string => {
  return strings.reduce((acc, name, i) => {
    if (i === 0) {
      return name;
    }
    if (i === strings.length - 1) {
      return `${acc} and ${name}`;
    }
    return `${acc}, ${name}`;
  });
};


export const sortUsersByFullNames = (names: Array<UserApi>) => {
  const modifiedStringSort = (a: string | null, b: string | null) => {
    if (a === b || (a && b && a.toLowerCase() === b.toLowerCase())) { return 0;}
    if (a === null) { return 1; }
    if (b === null) { return -1; }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return -1;
  };
  return names.sort((a, b) => {
    let result = modifiedStringSort(a.lastName, b.lastName);
    if (result === 0) {
      result = modifiedStringSort(a.firstName, b.firstName);
      if (result === 0) {
        result = a.id < b.id ? -1 : 1;
      }
    }
    return result;
  });
};

export const getStatusMessageForAssessmentStatus = (assessmentStatus: StudentAssessmentStatus): string => {
  switch (assessmentStatus) {
    case StudentAssessmentStatus.NotStartedBeforeDue:
    case StudentAssessmentStatus.NotStartedBeforeLate:
      return studentAssessmentStatusStrings.NOT_STARTED;
    case StudentAssessmentStatus.InProgressBeforeDue:
    case StudentAssessmentStatus.InProgressBeforeLate:
      return studentAssessmentStatusStrings.IN_PROGRESS;
    case StudentAssessmentStatus.CompletedBeforeDue:
      return studentAssessmentStatusStrings.COMPLETED_ON_TIME;
    case StudentAssessmentStatus.CompletedBeforeLate:
      return studentAssessmentStatusStrings.COMPLETED_LATE;
    case StudentAssessmentStatus.NotStartedAfterLate:
    case StudentAssessmentStatus.InProgressAfterLate:
      return studentAssessmentStatusStrings.INCOMPLETE;
    case StudentAssessmentStatus.CompletedAfterLate:
      return studentAssessmentStatusStrings.COMPLETED_FOR_PRACTICE;
    // no default
  }
};

export function appendStartedToAssessmentName(assessmentName: string, isStarted: boolean) {
  if (!isStarted) {
    return assessmentName;
  }
  return `${assessmentName} (started)`;
}
