import React, { useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import apiNext from 'api-next';
import sharedStrings from 'sharedStrings';
import CodonUrls from 'urls';
import { externalLink } from 'utils/commonFormattingFunctions';
import LoginBox from './LoginBox';

enum ResetStatusEnum {
  Unsubmitted = 'unsubmitted',
  Success = 'success',
  Failure = 'failure'
}

export default function PasswordResetRequest() {
  const [initEmail] = useQueryParam('email', StringParam);
  const [resetSuccess, setResetSuccess] = useState<ResetStatusEnum>(ResetStatusEnum.Unsubmitted);
  const [email, setEmail] = useState(initEmail || '');

  async function doResetRequest(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setResetSuccess(ResetStatusEnum.Unsubmitted);

    if (email) {
      try {
        await apiNext.requestPasswordReset(email.toLowerCase());
        setResetSuccess(ResetStatusEnum.Success);
      } catch (err: any) {
        console.warn(`password reset error message: ${err.message}`);
        setResetSuccess(ResetStatusEnum.Failure);
      }
    }
    return false;
  }

  return (
    <LoginBox title="Reset Password">
      <form onSubmit={doResetRequest}>
        <input
          type="text"
          value={email}
          placeholder="Email"
          aria-label="Enter the email address associated with your Codon account"
          onChange={e => setEmail(e.target.value)}
          required
          readOnly={!!initEmail}
        />
        <input type="submit" value="Request password reset" />
        <div role="alert">
          {resetSuccess === ResetStatusEnum.Success && (
            <div className="login__info">
              If there is an account associated with this email address, we have sent instructions to reset your password. If you don’t receive an email from us within 5 minutes, check out our
              &nbsp;{externalLink(CodonUrls.LoginSupportArticle, 'Login Support Article')} or contact us at {sharedStrings.CODON_SUPPORT_EMAIL}.
            </div>
          )}
          {resetSuccess === ResetStatusEnum.Failure && (
            <div className="login__info">
              There was an error sending the reset email, please try again.
            </div>
          )}
        </div>
      </form>
      <div className="login__email_block"><a href="/login">Back to Login</a></div>
    </LoginBox>
  );
}
