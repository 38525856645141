import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import apiNext from 'api-next';
import sharedStrings from 'sharedStrings';
import { determineAssessmentWindow, determineMultipleAttemptPolicy, handleFinishInstructor } from 'utils/assessmentFunctions';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import createStudentAssessment from './shared/createStudentAssessment';
import getVatHashesFromSaqa from 'utils/getVatHashesFromSaqa';
import logExecutionTime from 'utils/logExecutionTime';

import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveEnrichedStudentAssessments from 'store/selectors/retrieveEnrichedStudentAssessments';

import AssessmentTakerHeader from './shared/AssessmentTakerHeader';
import AssessmentTakerHomework from './AssessmentTakerHomework';
import AssessmentTakerPreclass from './AssessmentTakerPreclass';
import AssessmentTakerPracticeTest from './AssessmentTakerPracticeTest';

import { MappedAssessmentQuestion } from 'types/common.types';
import { Store } from 'types/store.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { FirstAttemptedEnum, StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { RoleEnum } from 'types/backend/roles.types';
import { PageStates, Steps } from './AssessmentTaker.types';
import { AssessmentTakerRootPropTypes, AssessmentTakerProps, AssessmentTakerRootProps } from './shared/AssessmentTakerProps';
import { AssessmentContainerExitPayload } from './shared/AssessmentContainerProps';
import './AssessmentTaker.scss';


function AssessmentTakerRoot({
  assessmentData,
  refreshAssessmentQuestions,
  questions,
  userRoleId,
  userId,
}: AssessmentTakerRootProps) {
  const {
    gradingPolicy,
    enrollmentAssessmentDueDate,
    dueDate,
    freeAttempts,
    id: assessmentId,
    lateDate,
    mergedDueDate,
    pointPenalty,
  } = assessmentData;

  const attemptPolicy = determineMultipleAttemptPolicy(freeAttempts, pointPenalty, gradingPolicy);
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const isInstructor = useSelector((store: Store) => !!store.state.instructorStudentViewCourseId);
  const assessmentQuestionMaps: Array<MappedAssessmentQuestion> = useSelector(retrieveActiveAssessmentQuestionMaps);
  const enrichedStudentAssessments = useSelector(retrieveEnrichedStudentAssessments);
  const questionL8yIdArray = questions.map(({ l8yId }) => l8yId);
  const [currentStep, setCurrentStep] = useState(Steps.Intro);
  const [correctQuestionCount, updateCorrectQuestionCount] = useState(0);
  const [earnedPoints, updateEarnedPoints] = useState(0);
  const [l8ySessionId, setL8ySessionId] = useState('');
  const [attemptsHash, setAttemptsHash] = useState({});
  const [clarityHash, setClarityHash] = useState({});
  const [correctHash, setCorrectHash] = useState({});
  const [everCorrectHash, setEverCorrectHash] = useState({});
  const [latePointsDeductedHash, setLatePointsDeductedHash] = useState({});
  const [pointsHash, setPointsHash] = useState({});
  const [recapHash, setRecapHash] = useState({});
  const [vatFrozenHash, setVatFrozenHash] = useState({});
  const [studentAssessmentId, setStudentAssessmentId] = useState(-1);
  const [unansweredQuestionCount, setUnansweredQuestionCount] = useState(-1);
  const [totalLatePointsDeducted, setTotalLatePointsDeducted] = useState(-1);

  const assessmentQuestionMapsForAssessment = assessmentQuestionMaps.filter((aqm: AssessmentQuestionApi) => aqm.assessmentId === assessmentId);
  const totalQuestions = questions.length;
  const filteredAssessmentQuestionMap = assessmentQuestionMapsForAssessment.filter(({ _derived: { questionData: { l8yId } } }) => questionL8yIdArray.includes(l8yId));
  const totalPoints = filteredAssessmentQuestionMap.map(({ points }) => points).reduce((a, b) => a + b, 0);
  const enrollmentData = useSelector((store: Store) => store.active.enrollment);
  console.debug('AssessmentTakerRoot', userId, userRoleId, questions, enrollmentData, assessmentData);

  const dueString = DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.WeekdayDateAtTime);
  const currentWindow = determineAssessmentWindow(dueDate, lateDate, enrollmentAssessmentDueDate);
  const isAfterLate = currentWindow === FirstAttemptedEnum.AfterLate;
  const handleStep = (step: Steps, force?: boolean) => {
    setCurrentStep(force ? step : PageStates[step].next);
  };

  const handleStartSession = async () => {
    const startTime = performance.now();
    // get the very latest assessmentQuestions in case there has been a last minute change
    const refreshedQuestions = await refreshAssessmentQuestions(assessmentData.id);
    if (!refreshedQuestions.length) {
      triggerConfirmationPrompt({
        title: 'Error Starting Assignment',
        message: 'There are no questions in this assignment. Please check back later.',
        onConfirm: () => {},
      });
      return;
    }
    let studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas> = [];
    // Get role from enrollment
    if (enrollmentData.roleId === RoleEnum.Student) {
      const studentAssessmentData = await createStudentAssessment({ assessmentData, enrollmentData }) as Required<StudentAssessmentApi>;
      setStudentAssessmentId(studentAssessmentData.id);
      setL8ySessionId(studentAssessmentData.l8ySessionId);
      setUnansweredQuestionCount(studentAssessmentData.unansweredQuestionCount);

      studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions([studentAssessmentData.id]) as Array<StudentAssessmentQuestionApiWithSaqas>;
    } else if (isInstructor) {
      setL8ySessionId(assessmentId);
    }
    // get initial state of attempt-related data based on latestStudentAssessmentQuestionAttempt for student or default hashes for instructor
    const saqaHashes = getVatHashesFromSaqa(refreshedQuestions, studentAssessmentQuestions, isInstructor, isAfterLate);
    setClarityHash(saqaHashes.clarityHash);
    setAttemptsHash(saqaHashes.attemptsHash);
    setCorrectHash(saqaHashes.correctHash);
    setEverCorrectHash(saqaHashes.everCorrectHash);
    setLatePointsDeductedHash(saqaHashes.latePointsDeductedHash);
    setPointsHash(saqaHashes.pointsHash);
    setRecapHash(saqaHashes.recapHash);
    setVatFrozenHash(saqaHashes.vatFrozenHash);
    logExecutionTime(startTime, 'AssessmentTakeRoot handleStartSession');
    handleStep(Steps.Intro);
  };

  const handleReviewAssessment = async () => {
    if (isInstructor) {
      window.location.reload();
    }
    handleStep(Steps.Intro);
  };

  const onExit = async ({ correctQuestionsArray, clarityHash: clarity, attemptsHash: attempts, correctHash: correct, everCorrectHash: everCorrect, latePointsDeductedHash: latePointsDeducted, pointsHash: points, vatFrozenHash: vatFrozen }: AssessmentContainerExitPayload) => {
    let newTotalPointsEarned = -1;
    let newUnansweredQuestionCount = -1;
    let newCorrectQuestionCount = -1;
    if (isInstructor) {
      newUnansweredQuestionCount = 0;
      ({ totalPointsEarned: newTotalPointsEarned, correctQuestionCount: newCorrectQuestionCount } = handleFinishInstructor(points, correct));
    } else {
      const updatedStudentAssessments = await apiNext.getStudentAssessment(assessmentData.id, enrollmentData.id);
      const saData = updatedStudentAssessments.find((sa) => sa.assessmentId === assessmentData.id) as StudentAssessmentApi;
      ({ totalPointsEarned: newTotalPointsEarned, unansweredQuestionCount: newUnansweredQuestionCount } = saData);
      newCorrectQuestionCount = correctQuestionsArray.length;
    }
    if (newTotalPointsEarned !== -1) {
      updateEarnedPoints(newTotalPointsEarned);
    }
    const newTotalLatePointsDeducted = Object.values(latePointsDeducted).reduce((acc, cur) => {
      acc = acc + cur;
      return acc;
    }, 0);
    if (newTotalLatePointsDeducted !== -1) {
      setTotalLatePointsDeducted(newTotalLatePointsDeducted);
    }
    setClarityHash(clarity);
    setAttemptsHash(attempts);
    setCorrectHash(correct);
    setEverCorrectHash(everCorrect);
    setLatePointsDeductedHash(latePointsDeducted);
    setPointsHash(points);
    updateCorrectQuestionCount(newCorrectQuestionCount);
    setVatFrozenHash(vatFrozen);
    setUnansweredQuestionCount(newUnansweredQuestionCount);
    handleStep(Steps.Outro, true);
  };

  const renderAssessment = (assessType: AssessTypeEnum) => {
    const { courseId } = enrollmentData;
    const assessmentProps = {
      assessmentData,
      attemptPolicy,
      attemptsHash,
      clarityHash,
      correctHash,
      correctQuestionCount,
      courseId,
      currentStep,
      dueString,
      earnedPoints,
      enrollmentData,
      everCorrectHash,
      handleReviewAssessment,
      handleStartSession,
      l8ySessionId,
      latePointsDeductedHash,
      onExit,
      pointsHash,
      questions,
      recapHash,
      studentAssessmentId,
      totalLatePointsDeducted,
      totalPoints,
      totalQuestions,
      unansweredQuestionCount,
      userId,
      vatFrozenHash,
    } as AssessmentTakerProps;
    switch (assessType) {
      case AssessTypeEnum.Preclass: {
        return <AssessmentTakerPreclass {...assessmentProps} />;
      }
      case AssessTypeEnum.Homework: {
        return <AssessmentTakerHomework {...assessmentProps} />;
      }
      case AssessTypeEnum.PracticeTest: {
        return <AssessmentTakerPracticeTest {...assessmentProps} />;
      }
      default: return null;
    }
  };

  let assessmentStatus = undefined;
  if (enrichedStudentAssessments) {
    const currentAssessment = enrichedStudentAssessments.find(assign => assign.id === assessmentData.id);
    if (currentAssessment) {
      assessmentStatus = currentAssessment.assessmentStatus;
    }
  }

  if (assessmentStatus === undefined) {
    if (currentWindow === FirstAttemptedEnum.BeforeDue) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeDue;
    } else if (currentWindow === FirstAttemptedEnum.BeforeLate) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeLate;
    } else { // after late
      assessmentStatus = StudentAssessmentStatus.NotStartedAfterLate;
    }
  }

  return (
    <div className="assessment-taker-root page-centered">
      <AssessmentTakerHeader
        assessmentData={assessmentData}
        assessmentStatus={assessmentStatus}
        totalPoints={totalPoints}
      />
      {isInstructor && (
        <div className="assessment-taker-root__instructor-banner">
          {sharedStrings.INSTRUCTOR_PREVIEW_ASSESSMENT}
          <button onClick={() => window.location.reload()}>
            {sharedStrings.INSTRUCTOR_PREVIEW_RESTART_ASSESSMENT}
          </button>
        </div>
      )}
      <div className="assessment-taker-root__wrap">
        { renderAssessment(assessmentData.assessType) }
      </div>
    </div>
  );
}

AssessmentTakerRoot.propTypes = AssessmentTakerRootPropTypes;

export default AssessmentTakerRoot;
