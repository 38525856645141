import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import apiNext from 'api-next';
import useEffectOnce from 'hooks/useEffectOnce';
import CourseQACheck from 'shared-components/CourseQACheck/CourseQACheck';
import { Store } from 'types/store.types';
import { CourseHealthCheckResponseMulti } from 'types/backend/courseHealthCheck.types';
import './SingleQADashboard.scss';

export default function SingleQADashboardController() {
  const course = useSelector((store: Store) => store.active.course);
  const { id: courseId } = course;
  const adminUser = useSelector((store: Store) => store.adminUser);
  const [qaResults, setQAResults] = useState<CourseHealthCheckResponseMulti>();

  useEffectOnce(() => {
    async function getQAResults() {
      const [ result ] = await apiNext.getCourseHealthChecks([courseId]);
      setQAResults(result);
    }
    getQAResults();
  });

  if (!adminUser) {
    return null;
  }

  const renderQAResults = () => {
    if (!!qaResults) {
      const { qaChecks } = qaResults;
      return (
        <CourseQACheck
          qaCheckData={qaChecks}
        />
      );
    }
  };

  return (
    <div className="single-course-qa-dashboard">
      {renderQAResults()}
    </div>
  );
}
