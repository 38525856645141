import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaRegCircle, FaRegStar } from 'react-icons/fa';

import { getShortAssessType } from 'utils/commonFormattingFunctions';
import { filterTodoPastQuestions } from '../../sharedStudyPathFunctions';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import retrieveSimpleEnrichedAssessmentQuestionMaps from 'store/selectors/retrieveSimpleEnrichedAssessmentQuestionMaps';
import Icon, { IconEnum } from 'shared-components/Icon';
import { EnrichedStudentTopicCardLearningObjective, StudyPathEnrichedQuestion } from '../../StudyPathController.types';
import { StudyPathAssessmentTooltip } from '../StudyPathTooltipContent/StudyPathAssessmentTooltip';
import { ClarityEnum, CheckpointColumn, YesNo } from 'types/backend/shared.types';
import { Store } from 'types/store.types';
import { PositionEnum } from 'types/common.types';

interface StudyPathTopicCardLoItemSharedProps {
  checkpointType: CheckpointColumn
  launchAssessmentTaker: (l8yId: string) => void
}

interface StudyPathTopicCardLoItemProps extends StudyPathTopicCardLoItemSharedProps {
  learningObjective: EnrichedStudentTopicCardLearningObjective
}

interface QuestionItemRowProps extends StudyPathTopicCardLoItemSharedProps {
  questionData: StudyPathEnrichedQuestion
}

const QuestionItemRow = ({ checkpointType, launchAssessmentTaker, questionData }: QuestionItemRowProps) => {
  const {
    assessmentNumber,
    assessment,
    id,
    question,
    questionNumber,
    studentAssessmentQuestion,
  } = questionData;
  const { latestStudentAssessmentQuestionAttempt, pointsAvailableToRecap } = studentAssessmentQuestion || {};
  const { clarity, isCorrect } = latestStudentAssessmentQuestionAttempt || {};
  const isChecked = isCorrect === YesNo.Yes;
  const tooltipPos = checkpointType === CheckpointColumn.Test ? PositionEnum.Left : PositionEnum.Right;
  const showRecap = !!(pointsAvailableToRecap && pointsAvailableToRecap > 0);
  return (
    <button
      data-l8yid={question.l8yId}
      className="lo-question-row"
      key={`assessmentQuestion_${id}`}
      onMouseEnter={() => console.debug(`Question Data ${questionData.id}`, questionData)}
      onClick={() => launchAssessmentTaker(question.l8yId)}
    >
      <div className="lo-question-row__indicator">
        {isChecked ? <FaCheckCircle /> : <FaRegCircle /> }
      </div>
      <div className="lo-question-row__content">
        <div className="lo-question-row__text">
          <BetterTooltip
            content={() => (
              <StudyPathAssessmentTooltip
                title={assessment.title}
                assessType={assessment.assessType}
                assessNumber={assessmentNumber}
                questionNum={questionNumber}
              />
            )}
            position={tooltipPos}
          >
            {getShortAssessType(assessment.assessType)} {assessmentNumber} - Q{questionNumber}
          </BetterTooltip>
        </div>
        <div className="lo-question-row__status">
          <>
            {clarity === ClarityEnum.Muddy && <span className="status-icon"><Icon size={18} which={IconEnum.Muddy} /></span>}
            {showRecap && <span className="status-icon"><FaRegStar size={18} /></span>}
          </>
        </div>
      </div>
    </button>
  );
};

const StudyPathTopicCardLoItem = ({ learningObjective, ...sharedProps }: StudyPathTopicCardLoItemProps) => {
  const { title, stringId, loNumber, assessmentQuestions } = learningObjective;
  const { checkpointType } = sharedProps;
  const studentAssessments = useSelector((store: Store) => store.active.studentAssessments);
  const simpleAqms = useSelector(retrieveSimpleEnrichedAssessmentQuestionMaps);
  const { todoQuestions, pastQuestions } = filterTodoPastQuestions(assessmentQuestions, checkpointType, [learningObjective], studentAssessments, simpleAqms);

  const questionCorrectness = [...todoQuestions, ...pastQuestions].map(({ studentAssessmentQuestion }) => {
    const { latestStudentAssessmentQuestionAttempt } = studentAssessmentQuestion || {};
    return !!(latestStudentAssessmentQuestionAttempt && latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes);
  });
  const loIsCompleted = questionCorrectness.every((b) => b === true);

  const noQuestions = !todoQuestions.length && !pastQuestions.length;

  return (
    <div data-stringid={stringId} className="learning-objective-expando">
      <div className="topic-card-learning-objective__content-wrap">
        <div className="topic-card-learning-objective__header-wrap">
          <div className="topic-card-learning-objective__indicator">
            { loIsCompleted
              ? <FaCheckCircle size={24} />
              : <FaRegCircle size={24} />
            }
          </div>
          <div className="topic-card-learning-objective__description">
            <div className="topic-card-learning-objective__description-title">
              {title}
            </div>
            <div className="topic-card-learning-objective__description-lo-number">
              { loNumber.replace('LO', 'Learning Objective') }
            </div>
          </div>
        </div>
        <div className="lo-questions-list">
          {noQuestions ? <div className="no-questions">No Questions Assigned</div> : (
            <div className="lo-questions-list">
              <div className="todo-questions" data-showlist={!!todoQuestions.length}>
                {todoQuestions.map((questionData) => (
                  <QuestionItemRow
                    key={questionData.id}
                    questionData={questionData}
                    {...sharedProps}
                  />
                ))}
              </div>
              <div className="past-questions" data-showlist={!!pastQuestions.length}>
                {pastQuestions.map((questionData) => (
                  <QuestionItemRow
                    key={questionData.id}
                    questionData={questionData}
                    {...sharedProps}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StudyPathTopicCardLoItem.propTypes = {
  checkpointType: PropTypes.oneOf(Object.values(CheckpointColumn)).isRequired,
  launchAssessmentTaker: PropTypes.func.isRequired,
  learningObjective: PropTypes.object.isRequired,
};

export default StudyPathTopicCardLoItem;
