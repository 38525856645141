import React from 'react';
import PropTypes from 'prop-types';
import { FaArrowLeft } from 'react-icons/fa';

import CodonUrls from 'urls';
import { externalLink } from 'utils/commonFormattingFunctions';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import {
  ButtonAlias,
  ButtonTypeEnum,
  QuestionBuilderFooterButton,
  QuestionBuilderStep,
} from './QuestionBuilderController.types';
import { LeftRightEnum } from 'types/common.types';

function QuestionBuilderFooter({
  editingExistingQuestion = false,
  errorMsg = '',
  navButtons,
  step,
}: {
  editingExistingQuestion: boolean
  errorMsg: string
  navButtons: Array<QuestionBuilderFooterButton>
  step: QuestionBuilderStep
}) {
  const renderButtons = (buttonsToRender: Array<QuestionBuilderFooterButton>) => buttonsToRender.map(({
    alias,
    buttonType = ButtonTypeEnum.Secondary,
    disabled = false,
    isLoading = false,
    loadingText = '',
    label,
    onClick,
    show = true,
  }) => {
    if (!show) {
      return null;
    }
    let icon;
    switch (alias) {
      case ButtonAlias.SaveAndGoBack:
      case ButtonAlias.GoBack: {
        icon = () => <FaArrowLeft size={16}/>;
        break;
      }
      // no default
    }
    const className = `${step}__${alias}`;
    const buttonProps = {
      className,
      key: className,
      disabled,
      onClick,
      icon,
    };
    switch (buttonType) {
      case ButtonTypeEnum.Primary: {
        return (
          <BetterButton
            primary
            text={label}
            {...buttonProps}
          />
        );
      }
      case ButtonTypeEnum.Loading: {
        return (
          <LoadingButton
            loading={isLoading}
            loadingText={loadingText}
            text={label}
            {...buttonProps}
          />
        );
      }
      case ButtonTypeEnum.Secondary:
      default: {
        return (
          <BetterButton
            secondary
            text={label}
            {...buttonProps}
          />
        );
      }
    }
  });

  const [leftButtons, rightButtons] = navButtons.reduce((acc: [Array<QuestionBuilderFooterButton>, Array<QuestionBuilderFooterButton>], cur) => {
    const [left, right] = acc;
    if (cur.align === LeftRightEnum.Left) {
      return [[...left, cur], right];
    }
    return [left, [...right, cur]];
  }, [[], []]);

  return (
    <div className="question-builder__footer form-control row">
      <div className="button-wrap">
        {renderButtons(leftButtons)}
      </div>
      {errorMsg !== '' && (
        <div className="question-builder__error-msg">
          {errorMsg}
        </div>
      )}
      <div className="button-wrap button-wrap__right-side">
        {step === QuestionBuilderStep.ChooseLO && !editingExistingQuestion && externalLink(CodonUrls.SurveyItemsKB, 'Learn more about our different item types.')}
        {renderButtons(rightButtons)}
      </div>
    </div>
  );

}

QuestionBuilderFooter.propTypes = {
  editingExistingQuestion: PropTypes.bool,
  errorMsg: PropTypes.string,
  navButtons: PropTypes.array.isRequired,
  step: PropTypes.oneOf(Object.values(QuestionBuilderStep)).isRequired,
};

export default QuestionBuilderFooter;
