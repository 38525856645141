import React, {
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { truncate } from 'utils';
import useElementSize from 'hooks/useElementSize';
import useEffectOnce from 'hooks/useEffectOnce';
import { calculateAssessmentTotalPoints, getAllAssessmentCourseLos, sortAssessmentsByWeightedAssessType } from 'utils/assessmentFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { useAppSelector } from 'store';
import retrieveAssessmentsWithEnrollment, { SummativeAssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import DayHeaderButton from './DayHeaderButton';
import TopicPill from './TopicPill';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { YesNo } from 'types/backend/shared.types';
import { BreakpointWidth } from 'types/breakpoint.types';
import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import './BetterTimeline.scss';

// styled-component to dynamically handle the width and height of the timeline, responds to the height of better-timeline__content div
const BetterTimelineView = styled.div<{
  columns: number
  offset: number
  width: number
  height: number
}>`
  width: ${p => p.width}px;
  min-height: 300px;
  height: ${p => p.height}px;
  .better-timeline__chevron-btn-wrap {
    height: ${p => p.height}px;
  }
  > .better-timeline__content {
    position: absolute;
    left: -${p => p.offset}px;
    .better-timeline__day-wrap {
      width: ${p => p.width / p.columns}px;
    }
  }
`;

// takes course startDate and endDate in ISO and returns an array of DateTime objects
// representing every date from the start of week 1 to the end of the last week
const getArrayOfCourseDates = (startDate: string, endDate: string) => {
  // luxon startOf week returns monday, minus 1 day to make the timeline start on sunday
  const start = DateTime.fromISO(startDate).startOf('week').minus({ days: 1 });
  // pad the end of the timeline with 1 week after last class week
  const end = DateTime.fromISO(endDate).plus({ weeks: 1 }).endOf('week').minus({ days: 1 });
  const totalDays = Math.round(end.diff(start, 'days').days);
  return Array.from({ length: totalDays }, (x, i) => start.plus({ days: i }));
};

export interface AssessmentPillProps {
  assessType: AssessTypeEnum
  classSessionId?: number
  id: string
  inWindow?: boolean
  mergedOpenDate: string
  mergedDueDate: string
  name: string
  published: YesNo
  totalPoints: number
  courseLos: Array<EnrichedCourseLearningObjective>
  prep?: AssessmentApiBase
  practiceTest?: AssessmentApiBase
  returnToCsId: number
}

const BetterTimeline = ({
  currentClassSessionId,
  renderAssessmentPill,
  setClassSessionId,
}: {
  currentClassSessionId: number
  renderAssessmentPill: (assessment: AssessmentPillProps) => React.ReactNode
  setClassSessionId?: (csid: number) => void
}) => {
  // currently the offset system is based on weeks but ideally I'd like to make it more robust, which would enable additional functions like zooming the timeline
  const [squareRef, { width }] = useElementSize();
  const [innerTimelineRef, { height }] = useElementSize();
  const classSessions = useAppSelector(retrieveBetterClassSessions);
  const course = useAppSelector((store) => store.active.course);
  const assessmentQuestionMaps = useAppSelector(retrieveActiveAssessmentQuestionMaps);
  const assessmentsWithEnrollment = useAppSelector(retrieveAssessmentsWithEnrollment);

  const [totalWeeks, setTotalWeeks] = useState(0);
  const [enableTransition, setEnableTransition] = useState(false);
  // this is necessary in order that the timeline can be affected by the class session navigation but not the other way around
  const [previousClassSessionId, setPreviousClassSessionId] = useState(currentClassSessionId);

  // only show one week at a time
  const isNarrow = width <= BreakpointWidth.XsMax;
  const weeksToView = isNarrow ? 1 : 2;
  const daysToShow = 7 * weeksToView;
  const arrayOfDatesWithClassSessions = useMemo(() => {
    if (!course.startDate || !course.endDate) {
      return [];
    }
    const now = DateTime.local();
    const courseDateArray = getArrayOfCourseDates(course.startDate, course.endDate);
    // totalWeeks needs to always be an integer
    setTotalWeeks(Math.ceil(courseDateArray.length / daysToShow));
    return courseDateArray.map((date) => {
      const classSession = classSessions.find(({ luxonDate }) => luxonDate.hasSame(date, 'day'));
      const { id: classSessionId, classType, label: classDayLabel } = classSession || {};
      const isToday = now.hasSame(date, 'day');
      const filteredAssessmentsWithScore = assessmentsWithEnrollment.reduce((acc: Array<AssessmentPillProps>, cur) => {
        // don't show Prep or Practice Test Pill, just the Summative/Study Path
        const dueOnThisDate = DateTime.fromISO(cur.mergedDueDate).hasSame(date, 'day');
        if ([AssessTypeEnum.Prep, AssessTypeEnum.PracticeTest].includes(cur.assessType) || !dueOnThisDate) {
          return acc;
        }
        const totalPoints = calculateAssessmentTotalPoints(cur.id, assessmentQuestionMaps);
        const courseLos = getAllAssessmentCourseLos(cur.id, assessmentQuestionMaps);
        const { prep, practiceTest } = cur as SummativeAssessmentWithEnrollment;
        return [
          ...acc,
          {
            ...cur,
            classSessionId,
            totalPoints,
            courseLos,
            prep,
            practiceTest,
            returnToCsId: currentClassSessionId,
          },
        ];
      }, []);
      const sortedPillAssessments = sortAssessmentsByWeightedAssessType(filteredAssessmentsWithScore);
      return {
        assessmentsByDueDate: sortedPillAssessments,
        classDayLabel,
        classSession,
        classType,
        dateString: date.toFormat(DateFormatEnum.DateShortYear),
        isToday,
        nonClassDay: classType === ClassTypeEnum.Special,
        weekday: date.weekdayShort,
      };
    });
  }, [
    assessmentQuestionMaps,
    assessmentsWithEnrollment,
    classSessions,
    course,
    currentClassSessionId,
    daysToShow,
  ]);

  const indexOfClassSessionId = arrayOfDatesWithClassSessions.findIndex(({ classSession }) => !!classSession && classSession.id === currentClassSessionId);
  const offsetWeeksByClassSessionId = Math.floor(indexOfClassSessionId / 7);
  const [weekOffset, setWeekOffset] = useState(offsetWeeksByClassSessionId);

  // this calculates the current visible window of days within the timeline so we can tell pills outside the current view not to take focus/read on screenreaders
  const firstVisibleDay = weekOffset * daysToShow;
  const lastVisibleDay = firstVisibleDay + daysToShow;
  const visibleIndexRange = [firstVisibleDay, lastVisibleDay];

  useEffectOnce(() => {
    const timer = setTimeout(() => setEnableTransition(true), 1500);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    // only update the timeline position based on props when the class session also changes, which happens when navigating between class sessions in ClassSessionView
    if (previousClassSessionId !== currentClassSessionId) {
      setWeekOffset(offsetWeeksByClassSessionId);
      setPreviousClassSessionId(currentClassSessionId);
    }
  }, [currentClassSessionId, offsetWeeksByClassSessionId, previousClassSessionId]);

  const handleTimeTravel = (toWeek: number) => {
    if (toWeek < 0) {
      setWeekOffset(0);
    } else {
      setWeekOffset(toWeek);
    }
  };

  // const renderClassDayHeaderButton

  const pixelOffset = weekOffset * (width / weeksToView);
  const prevDisabled = weekOffset === 0;
  const totalTimelineWidth = totalWeeks * width;
  const nextDisabled = (totalTimelineWidth - width) <= pixelOffset;
  return (
    <div role="region" aria-label="Timeline navigation" className="better-timeline" ref={squareRef}>
      <div className="better-timeline__chevron-btn-wrap better-timeline__decrement-wrap">
        <button
          aria-label="Timeline navigate back"
          className="better-timeline__chevron-btn better-timeline__decrement"
          disabled={prevDisabled}
          onClick={() => handleTimeTravel(weekOffset - weeksToView)}
        >
          <FaChevronLeft />
        </button>
      </div>
      <BetterTimelineView
        columns={daysToShow}
        offset={pixelOffset}
        width={width}
        height={height + 20}
        className="better-timeline__view"
      >
        <div className={`better-timeline__content ${enableTransition ? 'enable-transition' : ''}`} ref={innerTimelineRef}>
          {arrayOfDatesWithClassSessions.map(({
            assessmentsByDueDate,
            classDayLabel,
            classSession,
            classType,
            dateString,
            isToday,
            nonClassDay,
            weekday,
          }, idx) => {
            const {
              id: dayClassSessionId,
              classNumber,
              topics,
            } = classSession || {};
            const isSelected = dayClassSessionId === currentClassSessionId;
            const inWindow = visibleIndexRange[0] <= idx && idx <= visibleIndexRange[1];
            return (
              <div
                key={`${dateString}_${weekday}`}
                data-datestring={dateString}
                data-isclassday={!!classSession}
                className={`better-timeline__day-wrap ${!!classSession ? 'is-class-day' : 'is-off-day'}`}
              >
                <div className="better-timeline__header" data-istoday={isToday}>
                  <div className="better-timeline__weekday">
                    {weekday}
                  </div>
                  <div className="better-timeline__date">
                    {dateString}
                  </div>
                </div>
                <div className="better-timeline__day-body">
                  <DayHeaderButton
                    classType={classType}
                    classSessionId={dayClassSessionId}
                    classNumber={classNumber}
                    disabled={isSelected || !setClassSessionId}
                    inWindow={inWindow}
                    isSelected={isSelected}
                    classDayLabel={classDayLabel}
                    onClick={() => !!dayClassSessionId && !!setClassSessionId && setClassSessionId(dayClassSessionId)}
                  />
                  <div className="better-timeline__pill-stack">
                    {!nonClassDay && !!classDayLabel && (
                      <button
                        className="better-timeline__class-session-header__day-label-pill"
                        onClick={() => !!dayClassSessionId && !!setClassSessionId && setClassSessionId(dayClassSessionId)}
                        aria-hidden={!inWindow}
                      >
                        {truncate(classDayLabel, 40)}
                      </button>
                    )}
                    {!!topics && topics.map((topic) => (
                      <TopicPill key={topic.id} topic={topic} />
                    ))}
                    {!!assessmentsByDueDate.length && assessmentsByDueDate.map((assessment) => (
                      <Fragment key={`${assessment.mergedDueDate}_${dayClassSessionId}_${assessment.id}`}>
                        {renderAssessmentPill({ classSessionId: dayClassSessionId, inWindow, ...assessment })}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </BetterTimelineView>
      <div className="better-timeline__chevron-btn-wrap better-timeline__increment-wrap">
        <button
          aria-label="Timeline navigate forward"
          className="better-timeline__chevron-btn better-timeline__increment"
          disabled={nextDisabled}
          onClick={() => handleTimeTravel(weekOffset + weeksToView)}
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

BetterTimeline.propTypes = {
  currentClassSessionId: PropTypes.number.isRequired,
  setClassSessionId: PropTypes.func,
  renderAssessmentPill: PropTypes.func.isRequired,
};

export default BetterTimeline;
