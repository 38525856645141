import React, { useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import apiNext from 'api-next';
import LoginBox from './LoginBox';

export default function PasswordResetAction() {
  const [token] = useQueryParam('token', StringParam);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [infoMsg, setInfoMsg] = useState<string | null>(null);

  async function doChangePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setInfoMsg(null);
    setErrorMsg(null);

    try {
      if (!password || !confirmPassword) {
        setErrorMsg('Password or confirm password fields missing');
        return false;
      }

      if (password !== confirmPassword) {
        setErrorMsg('Password does not match confirm password');
        return false;
      }

      if (!token) {
        setErrorMsg('Reset token invalid');
        return false;
      }

      await apiNext.changePassword(token, password);

      setInfoMsg('Password changed, please login with new password');
      return false;
    } catch (error: any) {
      let errMsg = 'Something went wrong, please try to reset password again.';
      if (error?.response?.data?.message === 'Password reset token has expired.') {
        errMsg = `${error.response.data.message} Please reset again.`;
      }
      setErrorMsg(errMsg);
    }
  }

  return (
    <LoginBox title="Change Password">
      <form onSubmit={doChangePassword}>
        <input
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          aria-label="New Password"
          required
          type="password"
          value={password}
        />
        <input
          onChange={e => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          aria-label="Confirm Password"
          required
          type="password"
          value={confirmPassword}
        />
        <input type="submit" value="Change password" />
        <div role="alert">
          {!!infoMsg && <div className="login__info">{infoMsg}</div>}
          {!!errorMsg && <div className="login__error">{errorMsg}</div>}
        </div>
      </form>
      <div className="login__email_block"><a href="/login">Back to Login</a></div>
    </LoginBox>
  );
}
