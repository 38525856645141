import React from 'react';

import CodonUrls from 'urls';
import {
  createMuddyLoString,
  externalLink,
  formatAssessmentItemPlural,
  formatPlural,
  formatPoints,
} from 'utils/commonFormattingFunctions';
import calculateClarity from './calculateClarity';
import sharedStrings from 'sharedStrings';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import IntroOutro from './IntroOutro';
import ProgressCircle from 'shared-components/ProgressCircle/ProgressCircle';
import TextButton from 'shared-components/BetterButton/TextButton';
import { StudentCoursePath } from 'types/student.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClarityHash } from 'types/common.types';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import styles from 'style-config.scss';
import './IntroOutro.scss';

function OutroContent({
  assessmentControllerQuestions,
  assessType,
  clarityHash,
  correctQuestionCount,
  courseId,
  dueString,
  earnedPoints,
  handleReviewAssessment,
  isInstructor,
  recapPoints,
  totalLatePointsDeducted,
  totalPoints,
  totalQuestions,
}: {
  assessmentControllerQuestions: Array<AssessmentControllerQuestion>
  assessType: AssessTypeEnum
  clarityHash: ClarityHash
  correctQuestionCount: number
  courseId: string
  dueString?: string
  earnedPoints: number
  handleReviewAssessment: () => void
  isInstructor: boolean
  recapPoints?: number
  totalLatePointsDeducted: number
  totalPoints: number
  totalQuestions: number
}) {
  if (assessType === AssessTypeEnum.Homework && (recapPoints === undefined || !dueString)) {
    console.error('Missing dueString and/or recapPoints for Homework Outro, dueString and recapPoints are required for Homework Outro', dueString, recapPoints);
    return null;
  }

  // set up some display variables that are different for Homework vs Prep/Readiness
  let nextStepButtonText = 'Return to Course Home';
  let nextStepButtonLink = `/student/course/${courseId}/${StudentCoursePath.Home}`;
  if (assessType === AssessTypeEnum.Homework && recapPoints !== undefined) {
    nextStepButtonText = recapPoints > 0 ? 'Go Recapture Points in the Study Path' : 'Head to Study Path';
    nextStepButtonLink = `/student/course/${courseId}/${StudentCoursePath.StudyPath}`;
  }
  const progress = (correctQuestionCount / totalQuestions) * 100;
  const { muddyLOArray, muddyQuestionCount } = calculateClarity({ clarityHash, assessmentControllerQuestions });
  const muddyLOString: string = createMuddyLoString(muddyLOArray.map(({ _derived: { loNumber } }: { _derived: { loNumber: string }}) => loNumber));


  return (
    <IntroOutro>
      <div className="assessment-taker-outro__content">
        <div className="assessment-taker-outro__box row">
          <div className="assessment-taker-outro__box-left col-xs-2">
            <ProgressCircle
              size={42}
              progress={progress}
              strokeWidth={10}
              color={styles.grayDark}
              bgColor={styles.grayLight}
            />
          </div>
          <div className="assessment-taker-outro__box-right col-xs-10">
            <div className="assessment-taker-outro__content-title">COMPLETION</div>
            <ul>
              <li>{correctQuestionCount} of {totalQuestions} {formatAssessmentItemPlural(totalQuestions, assessType)} answered correctly</li>
              <li>{formatPoints(earnedPoints)} of {formatPoints(totalPoints)} {formatPlural('point', totalPoints)} earned</li>
              {totalLatePointsDeducted > 0 && (
                <li><em>{formatPoints(totalLatePointsDeducted)} {formatPlural('point', totalLatePointsDeducted)} deducted for late work</em></li>
              )}
            </ul>
            {recapPoints !== undefined && recapPoints > 0 && (
              <div className="assessment-intro-outro__infotip">
                <div className="assessment-intro-outro__infotip-icon"><img src="/recapture.svg" alt="points available for recapture" /></div>
                <div>
                  You can earn more points in this assignment by retrying missed questions in the Study Path before {dueString}.&nbsp;
                  {externalLink(CodonUrls.HowToRecaptureKB, 'Learn More.')}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="assessment-taker-outro__box row">
          <div className="assessment-taker-outro__box-left col-xs-2">
            <img src="/muddy.svg" alt="cute lil muddy piggy" />
          </div>
          <div className="assessment-taker-outro__box-right col-xs-10">
            <div className="assessment-taker-outro__content-title">METACOGNITION</div>
            <ul>
              <li>
                {muddyQuestionCount} {formatAssessmentItemPlural(muddyQuestionCount, assessType)} marked as 'Muddy'
              </li>
            </ul>
            {muddyQuestionCount > 0 && (
              <div className="assessment-intro-outro__infotip">
                <div className="assessment-intro-outro__infotip-text">
                  {muddyLOString} We've marked these Learning Objectives accordingly in the Study Path so you can get extra practice on them.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="assessment__action">
        <div>
          <BetterButton
            className="gray-button big-button"
            text={nextStepButtonText}
            linkTo={nextStepButtonLink}
          /><br />
        </div>
        <div>
          or
          <div className="assessment-outro__review">
            <TextButton className="assessment-outro__review-assessment" onClick={handleReviewAssessment}>Review Assignment Info</TextButton>
            {isInstructor && <div className="assessment-outro__review-assessment-instructor">{sharedStrings.INSTRUCTOR_PREVIEW_REVIEW_ASSIGNMENT}</div>}
          </div>
        </div>
      </div>
    </IntroOutro>
  );
}

export default OutroContent;
