import apiNext from 'api-next';
import sortAssessments from 'utils/sortAssessments';
import logExecutionTime from 'utils/logExecutionTime';
import activeSlice from 'store/slices/active';
import { AssessmentApiBase, AssessTypeEnum, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import { TopicApi } from 'types/backend/topics.types';
import { ClassSessionIclrApi } from 'types/backend/classSessionIclr.types';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { UnitApi } from 'types/backend/units.types';
import { EnrollmentAssessmentApi } from 'types/backend/enrollmentAssessments.types';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApi, UserApiIdNameEmail } from 'types/backend/users.types';
import { AppDispatch, Store } from 'types/store.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { QuestionGroupQuestionWithGroupApi } from 'types/backend/questionGroupQuestions.types';
import { YesNo } from 'types/backend/shared.types';
import { IclrApi } from 'types/backend/iclr.types';
import { OoclrApi } from 'types/backend/ooclr.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';

export default function getInitialData(courseId: string, launchingAsStudent = false) {
  console.debug('getInitialData courseId', courseId);
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const {
      active,
      passive,
      state,
      user,
    } = getStore();
    const startTime = performance.now();
    const { instructorCourses, studentCourses, units, topics, coinstructorsFromEnrolledCourses } = passive;
    const { instructorStudentViewCourseId } = state;
    const allCourses = instructorCourses.concat(studentCourses);
    // always get latest user enrollments when getting initial course data, important when redirecting back from payment page
    const enrollments = await apiNext.getUserEnrollmentsByCourseId(user.id, courseId);
    let enrollment = enrollments.find(({ roleId }) => roleId === RoleEnum.Instructor) as EnrollmentApi;
    if (launchingAsStudent) {
      enrollment = enrollments.find(({ roleId }) => roleId === RoleEnum.Student) as EnrollmentApi;
    }
    if (!enrollment) {
      console.debug('Oops. Trying to getInitialData without enrollment data.');
      return;
    }

    if (!courseId || !allCourses.length || !user) {
      console.debug('Oops. Trying to getInitialData with insufficient passive state');
      return;
    }
    const isStudentInCourse = enrollment.roleId === RoleEnum.Student;
    if (!isStudentInCourse && (!units.length || !topics.length)) {
      console.warn('units or topics empty but required for instructor loading', units, topics);
      return;
    }
    if (!!active.course?.id && courseId !== active.course.id) {
      console.debug('TRYING TO LOAD A DIFFERENT COURSE', active.course.id, courseId);
      // if current state course id does not match passed courseId, clear out storage to prevent contamination of state between courses
      dispatch(activeSlice.actions.clear());
    }
    const course = allCourses.find((c) => c.id === courseId);
    if (!course?.id) {
      console.error(`Course ${courseId} not found in courses`, allCourses);
      return;
    }
    const availableSubjectIds = [course.subjectId, ...course.additionalSubjectIds];
    const classSessions: Array<ClassSessionApi> = await apiNext.getSortedCourseClassSessions(courseId);

    // get all the classSession join data
    const classSessionTopics = await apiNext.getClassSessionTopicsByCourse(courseId);
    const classSessionLearningObjectives = await apiNext.getClassSessionLosByCourseId(courseId);

    // placeholders for LOs
    let templateLearningObjectives: Array<LearningObjectiveApi> = [];
    let additionalLearningObjectives: Array<LearningObjectiveApi> = [];

    // load assessment data.
    let templateQuestions: Array<QuestionApiOut> = [];
    const allAssessments = await apiNext.getAssessmentsByCourseId(courseId);
    const sortedAssessments = sortAssessments(allAssessments);
    let filteredAssessments = sortedAssessments;
    console.debug(`all assessments length = ${filteredAssessments.length}`);
    if (isStudentInCourse || instructorStudentViewCourseId === courseId) {
      filteredAssessments = sortedAssessments.filter(a => {
        if (a.published === YesNo.Yes) {
          return true;
        } else if (a.assessType === AssessTypeEnum.PracticeTest) {
          const relatedSummative = sortedAssessments.find(asum => asum.assessType === AssessTypeEnum.Summative && (asum as SummativeAssessmentApi).practiceTest.id === a.id) as AssessmentApiBase;
          return relatedSummative.published === YesNo.Yes;
        }
        return false;
      });
    }
    console.debug(`filtered assessments length = ${filteredAssessments.length}`);

    const sortedAssessmentIds = filteredAssessments.map(a => a.id);

    let assessmentQuestionMaps: Array<AssessmentQuestionApi> = [];
    if (filteredAssessments.length > 0) {
      assessmentQuestionMaps = await apiNext.getAssessmentQuestionMaps(sortedAssessmentIds);
    }
    const summativeAssessmentIds = filteredAssessments.reduce((acc: Array<string>, cur: AssessmentApiBase) => {
      if (cur.assessType === AssessTypeEnum.Summative) {
        return [...acc, cur.id];
      }
      return acc;
    }, []);
    const summativeAssessmentSupplements = await apiNext.getSummativeAssessmentSupplements(summativeAssessmentIds);

    // Load instructions (iclr & ooclr) data
    const classSessionsIds: Array<number> = classSessions.map((cs: ClassSessionApi) => cs.id);
    const classSessionIclrs: Array<ClassSessionIclrApi> = await apiNext.getClassSessionIclr(classSessionsIds);
    const iclrIds = [...new Set(classSessionIclrs.map((csi: ClassSessionIclrApi) => csi.iclrId))];
    let iclrs: Array<IclrApi> = [];
    if (!!iclrIds.length) {
      iclrs = await apiNext.getIclrsByIds(iclrIds);
    }

    const classSessionOoclrs: Array<ClassSessionOoclrApi> = await apiNext.getClassSessionOoclr(classSessionsIds);
    const ooclrIds = [...new Set(classSessionOoclrs.map((csi: ClassSessionOoclrApi) => {return csi.ooclrId;}))];
    let ooclrs: Array<OoclrApi> = [];
    if (!!ooclrIds.length) {
      ooclrs = await apiNext.getOoclrsByIds(ooclrIds);
    }

    //ASSEMBLE UNITS AND TOPICS ***************
    // For student and for other non-student roles
    // Students only get to see units/topics in the course.
    // Other roles get library topics and user topics too.
    // Though there is still not support for multiple instructors.
    let libraryUnits: Array<UnitApi> = [];
    let libraryTopics: Array<TopicApi> = [];

    let studentAssessments: Array<StudentAssessmentApi> = [];
    let studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas> = [];
    let studentEnrollmentAssessments: Array<EnrollmentAssessmentApi> = [];

    // students, instructors, courseAssessmentPreset and userQuestions are instructor-only
    let students: Array<UserApi> = [];
    let userQuestions: Array<QuestionApiOut> = [];
    const questionGroups: Array<QuestionGroupQuestionWithGroupApi> = [];
    let courseAssessmentPreset = {} as CourseAssessmentPresetApi;
    let instructors: Array<UserApiIdNameEmail> = [];

    if (isStudentInCourse) {
      // load studentAssessments and studentEnrollmentAssessments
      if (enrollment) {
        studentAssessments = await apiNext.getStudentAssessmentsByEnrollment(enrollment.id);
        if (studentAssessments.length) {
          studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions(studentAssessments.map(sa => sa.id)) as Array<StudentAssessmentQuestionApiWithSaqas>;
        }
        studentEnrollmentAssessments = await apiNext.getEnrollmentAssessmentsByEnrollment(enrollment.id);
      }
      // TODO: this is not a clear name as for students they are not "template" questions
      // but changing will require a fairly large state refactor
      // probably do away with template and user questions and store them together
      templateQuestions = await apiNext.getCourseQuestions(course.id);

      if (!!classSessionTopics.length) {
        const topicsSet = [...new Set(classSessionTopics.map(cst => cst.topicId))];
        libraryTopics = await apiNext.getTopicsByIds(topicsSet);
      }
      if (!!classSessionLearningObjectives.length) {
        const learningObjectiveIdsFromClassSessions = classSessionLearningObjectives.map(({ learningObjectiveId }) => learningObjectiveId);
        additionalLearningObjectives = await apiNext.getLosByLoIds(learningObjectiveIdsFromClassSessions);
      }
    } else {
      const allInstructorsInCourse = coinstructorsFromEnrolledCourses.filter((ci) => ci.courseId === courseId);
      allInstructorsInCourse.push(user); //since the current uses is not a co-instructor, add them to back in
      instructors = allInstructorsInCourse.map(({ id, lastName, firstName, email }) => ({
        id,
        lastName,
        firstName,
        email,
      }));
      const instructorUserIds = instructors.map(({ id: uId }) => uId);
      const instructorsSubjects = instructorUserIds.map(uId => {
        return availableSubjectIds.map(sid => ({
          userId: uId,
          subjectId: sid,
        }));
      }).flat();

      //LOAD QUESTIONS AND QUESTION GROUPS ***************

      templateQuestions = (await Promise.all(availableSubjectIds.map((id) => {
        return apiNext.getTemplateQuestionsBySubjectIdWithQloUserIds(id, instructorUserIds);
      }))).flat();
      const questionGroupsRaw = await apiNext.getQuestionGroups();
      questionGroupsRaw.forEach(({ groupTitle, subjectId, questions }) => {
        questions.forEach(question => {
          questionGroups.push({
            ...question,
            groupTitle,
            subjectId,
          });
        });
      });

      //ASSEMBLE UNITS ********************
      //build up the units of both template and user types that are available for this course. This includes all the template ones and all the user ones for the course creator and co-instructors
      const templateUnits = units.filter((unit: UnitApi) => availableSubjectIds.includes(unit.subjectId));
      const userUnits = await apiNext.getUserUnitsBySubjectsAndUsers(availableSubjectIds, instructorUserIds);
      userUnits.sort((a, b) => {
        if (a.userId === user.id) {
          // put this instructor's units first
          return -1;
        } else if (a.userId < b.userId) {
          return -1;
        } else if (a.userId === b.userId) {
          return 0;
        } else {
          return 1;
        }
      });

      libraryUnits = [...templateUnits, ...userUnits ];

      //ASSEMBLE TOPICS ********************

      //build up the topics of both template and user types that are available for this course. This includes all the template ones and all the user ones for the course creator and co-instructors
      const templateTopics: Array<TopicApi> = topics.filter((topic: TopicApi) => templateUnits.find((tu: UnitApi) => tu.id === topic.unitId));
      const userTopics = (await Promise.all(instructorsSubjects.map(({ userId, subjectId }) => {
        return apiNext.getUserTopicsBySubjectAndUser(subjectId, userId);
      }))).flat();

      // loop through all the library units and pull in first the templateTopics and then the userTopics for each
      libraryUnits.forEach(unit => {
        const templateTopicsForUnit = templateTopics.filter(tt => tt.unitId === unit.id);
        const userTopicsForUnit = userTopics.filter(ut => ut.unitId === unit.id);
        libraryTopics = [...libraryTopics, ...templateTopicsForUnit, ...userTopicsForUnit ];
      });

      students = await apiNext.getUsersByCourseAndRole(courseId, RoleEnum.Student);

      userQuestions = (await Promise.all(instructorsSubjects.map(({ userId, subjectId }) => {
        return apiNext.getUserQuestionsBySubjectId(userId, subjectId);
      }))).flat();

      additionalLearningObjectives = (await Promise.all(instructorsSubjects.map(({ userId, subjectId }) => {
        return apiNext.getUserLosBySubjectAndUser(subjectId, userId);
      }))).flat();

      courseAssessmentPreset = await apiNext.getCourseAssessmentPreset(course.id);
    }

    // ASSEMBLE LEARNING OBJECTIVES ******************
    await Promise.all(availableSubjectIds.map(async (subjectId) => {
      const apiResult = await apiNext.getTemplateLosBySubjectId(subjectId);
      templateLearningObjectives = [...templateLearningObjectives, ...apiResult];
    }));

    //TODO: this next line creates a byRef link between courseLibraryLos and TemplateLOs that we don't want and should be removed at some point
    const courseLibraryLos = [...templateLearningObjectives, ...additionalLearningObjectives];

    logExecutionTime(startTime, 'getInitialData');

    return dispatch(activeSlice.actions.setActiveInitialData({
      assessmentQuestionMaps,
      assessments: filteredAssessments,
      classSessionIclrs,
      classSessionLearningObjectives,
      classSessionOoclrs,
      classSessions,
      classSessionTopics,
      course,
      courseAssessmentPreset,
      enrollment,
      enrollmentAssessments: studentEnrollmentAssessments,
      iclrs,
      instructors,
      learningObjectives: courseLibraryLos,
      ooclrs,
      questionGroups,
      studentAssessmentQuestions,
      studentAssessments,
      students,
      summativeAssessmentSupplements,
      templateLearningObjectives,
      templateQuestions,
      topics: libraryTopics,
      units: libraryUnits,
      userQuestions,
    }));

  })();
}
