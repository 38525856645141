import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import apiNext, { SimplifiedErrorResponse } from 'api-next';
import { saveLogMessage } from 'utils/saveLogMessage';
import { validateInstructorVatAttempt } from 'utils/assessmentFunctions';
import { assessmentTakerWarningStrings } from 'sharedStrings';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { L8yContainerEvents } from 'shared-components/LearnosityContainer/LearnosityContainer.types';

import LearnosityController from 'shared-components/LearnosityContainer/LearnosityController';

import { AssessmentModeEnum, RenderingTypeEnum } from 'types/backend/assessmentInit.types';
import { ConfirmationTypeEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTaker/AssessmentTaker.types';
import { AssessmentLocation, GenericObject, YesNo } from 'types/backend/shared.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { ScoreData } from '../AssessmentTaker.types';
import { isInThePast } from 'utils/dateFormattingFunctions';
import { AssessmentContainerProps, AssessmentContainerPropTypes } from './AssessmentContainerProps';
import { AssessmentContainerWrap } from './AssessmentContainer.styled';
import { VatHashes } from 'utils/getVatHashesFromSaqa';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';

function AssessmentContainer({
  assessmentData,
  assessmentType,
  attemptPolicy,
  attemptsHash: initAttemptsHash,
  clarityHash: initClarityHash,
  correctHash: initCorrectHash,
  courseId,
  everCorrectHash: initEverCorrectHash,
  latePointsDeductedHash: initLatePointsDeductedHash,
  recapHash: initRecapHash,
  vatFrozenHash: initVatFrozenHash,
  enableClarity,
  handleExit,
  inReviewMode,
  l8ySessionId,
  pointsHash: initPointsHash,
  questions,
  studentAssessmentId,
  userId,
}: AssessmentContainerProps) {
  const isInstructor = useSelector((store: Store) => !!store.state.instructorStudentViewCourseId);
  const [clarityHash, updateClarityHash] = useState({});
  const [scoreHash, updateScoreHash] = useState<ScoreData | {}>({});
  const [currentStage, setCurrentStage] = useState(AssessmentTakerQuestionStage.INIT);
  const { attemptsInAt, mergedDueDate, lateDate, name: l8yName } = assessmentData;
  const isAfterLate = isInThePast(!!lateDate ? lateDate : mergedDueDate);

  const { triggerConfirmationPrompt } = useConfirmationPrompt();

  const handleL8yEvents = async ({ type, data }: { type: string; data: GenericObject }) => {
    console.debug(`LearnosityContainer event:: ${type}`, data);
    switch (type) {
      case L8yContainerEvents.ITEM_CHANGED: {
        setCurrentStage(AssessmentTakerQuestionStage.INIT);
        break;
      }
      case L8yContainerEvents.QUESTION_CHANGED: {
        setCurrentStage(AssessmentTakerQuestionStage.ANSWER_CHANGED);
        break;
      }
      case L8yContainerEvents.HANDLE_CLARITY: {
        updateClarityHash(data.clarityHash);
        break;
      }
      case L8yContainerEvents.HANDLE_FINISHED: {
        const exitHashes = data as VatHashes;
        let correctQuestionsArray: Array<StudentAssessmentQuestionApiWithSaqas> = [];
        if (!isInstructor) {
          const studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions([studentAssessmentId]) as Array<StudentAssessmentQuestionApiWithSaqas>;
          correctQuestionsArray = studentAssessmentQuestions.filter(s => s.gradedStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes);
        }
        handleExit({
          correctQuestionsArray,
          ...exitHashes,
        });
        break;
      }
      case L8yContainerEvents.VALIDATED: {
        const { activeL8yRef, score, isCorrect, assessmentQuestionId, clarity, attemptData, rawMaxScore } = data;
        updateScoreHash({
          ...scoreHash,
          [activeL8yRef]: {
            score,
            isCorrect,
            clarity,
          },
        });
        const newStudentAttempt = {
          studentAssessmentId,
          assessmentQuestionId,
          location: AssessmentLocation.AT,
          isCorrect,
          rawPointsEarned: score,
          attemptData,
          clarity,
          rawMaxScore,
        };
        // handle update attempt
        console.debug('VALIDATED data, assessmentData, newStudentAttempt', data, assessmentData, newStudentAttempt);
        if (isInstructor) {
          return validateInstructorVatAttempt(assessmentData, data, studentAssessmentId);
        }
        const studentAttemptSubmitted = await apiNext.createStudentAttempt(newStudentAttempt);
        console.debug('TODO: use this for improving debouncing', studentAttemptSubmitted);

        const { error } = studentAttemptSubmitted as SimplifiedErrorResponse;
        if (!!error) {
          console.warn('Error creating student attempt in the VAT', error);
          if (error.errorCode === CodonErrorCode.CreateSAQAAQNotFound) {
            saveLogMessage(`A student encountered an error in the VAT where a question was removed after the VAT was loaded,
              user: ${userId} in course: ${courseId}, AQId: ${assessmentQuestionId}, SAId: ${studentAssessmentId}`);
            triggerConfirmationPrompt({
              title: assessmentTakerWarningStrings.IN_PROGRESS_QUESTION_REMOVAL_WARNING_TITLE,
              message: assessmentTakerWarningStrings.IN_PROGRESS_QUESTION_REMOVAL_WARNING_MESSAGE,
              confirmationType: ConfirmationTypeEnum.Warn,
              onConfirm: () => {return;},
            });
            return {};
          } else {
            throw new Error(`Unexpected error in studentAttemptSubmitted ${JSON.stringify(error)}`);
          }
        } else {
          return studentAttemptSubmitted;
        }
      }
      case L8yContainerEvents.QUESTIONS_LOADED: {
        const { questionIds } = data;
        console.debug(L8yContainerEvents.QUESTIONS_LOADED, questionIds);
        break;
      }
    }
  };

  const items = questions.map(({ l8yId, type }) => ({ l8yId, type }));

  return (
    <AssessmentContainerWrap stage={currentStage} muddyClear={clarityHash} className={`assessment-container-wrap vat-${assessmentType}`}>
      <div className="assessment-wrap">
        <LearnosityController
          activityId={studentAssessmentId.toString()}
          assessmentMode={AssessmentModeEnum.SubmitPractice}
          assessmentType={assessmentType}
          attemptLimit={attemptsInAt}
          attemptPolicy={attemptPolicy}
          courseId={courseId}
          enableClarity={enableClarity}
          handleEvents={handleL8yEvents}
          initAttemptsHash={initAttemptsHash}
          initClarityHash={initClarityHash}
          initCorrectHash={initCorrectHash}
          initEverCorrectHash={initEverCorrectHash}
          initLatePointsDeductedHash={initLatePointsDeductedHash}
          initPointsHash={initPointsHash}
          initRecapHash={initRecapHash}
          initVatFrozenHash={initVatFrozenHash}
          inReviewMode={inReviewMode}
          isInstructor={isInstructor}
          isAfterLate={isAfterLate}
          items={items}
          l8ySessionId={l8ySessionId}
          location={AssessmentLocation.AT}
          name={l8yName}
          questionData={questions}
          renderingType={RenderingTypeEnum.Assess}
          userId={userId}
        />
      </div>
    </AssessmentContainerWrap>
  );
}

AssessmentContainer.props = AssessmentContainerPropTypes;

export default AssessmentContainer;
