import React from 'react';
import PropTypes from 'prop-types';

import { AssessmentCourse, CourseHealthCheckQAChecks, UnaffiliatedQuestion } from 'types/backend/courseHealthCheck.types';
import './CourseQACheck.scss';


interface CourseQACheckProps {
  qaCheckData: CourseHealthCheckQAChecks
}

const CourseQACheck = ({ qaCheckData }: CourseQACheckProps) => {
  const {
    unpublishedAssessments,
    assessmentsWithZeroQuestions,
    assessmentsWithoutStudyPath,
    assessmentsOnWrongStudyPath,
    unaffiliatedQuestions,
  } = qaCheckData;

  const renderQAAssessmentList = (listArray: Array<AssessmentCourse>) => {
    if (!listArray.length) {
      return 'none';
    }
    return (
      <ul>
        {
          listArray.map((item) => (<li key={item.id}>{item.name}</li>))
        }
      </ul>
    );
  };

  const renderUnaffiliatedQuestionList = (listArray: Array<UnaffiliatedQuestion>) => {
    if (!listArray.length) {
      return 'none';
    }
    return (
      <ul>
        {listArray.map(item => {
          const { assessmentQuestionId, assessmentName, questionTitle, questionLearningObjectives } = item;
          return (
            <li key={assessmentQuestionId}>
              {questionTitle} (<em>{assessmentName}</em>)
              <ul>
                {questionLearningObjectives.map(qlo => {
                  return (
                    <li key={qlo.loId}>
                      {qlo.loStringId}: {qlo.loTitle}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="course-qa-check">
      <table className="course-qa-check__table">
        <thead>
          <tr>
            <th className="course-qa-check__check">
              QA Check
            </th>
            <th className="course-qa-check__results">
              Results
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Unpublished assessments that are already open but not yet past due OR opening in the next 2 weeks
            </td>
            <td>
              {renderQAAssessmentList(unpublishedAssessments)}
            </td>
          </tr>
          <tr>
            <td>
              Assessments with zero questions that are already open but not yet past due OR opening in the next 2 weeks
            </td>
            <td>
              {renderQAAssessmentList(assessmentsWithZeroQuestions)}
            </td>
          </tr>
          <tr>
            <td>
              Assessments that won't show up on any Study Path
            </td>
            <td>
              {renderQAAssessmentList(assessmentsWithoutStudyPath)}
            </td>
          </tr>
          <tr>
            <td>
              Assessments that might be on the wrong Study Path
            </td>
            <td>
              {renderQAAssessmentList(assessmentsOnWrongStudyPath)}
            </td>
          </tr>
          <tr>
            <td>
              Unaffiliated questions with no course LOs
            </td>
            <td>
              {renderUnaffiliatedQuestionList(unaffiliatedQuestions)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CourseQACheck.propTypes = {
  qaCheckData: PropTypes.shape({
    unpublishedAssessments: PropTypes.array.isRequired,
    assessmentsWithZeroQuestions: PropTypes.array.isRequired,
    assessmentsWithoutStudyPath: PropTypes.array.isRequired,
    assessmentsOnWrongStudyPath: PropTypes.array.isRequired,
    unaffiliatedQuestions: PropTypes.array.isRequired,
  }).isRequired,
};

export default CourseQACheck;
