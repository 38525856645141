import apiNext from 'api-next';
import { comparePrepPracticeAssessments } from 'utils/assessmentFunctions';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { SummativeAssessmentApi } from 'types/backend/assessments.types';
import validateAssessmentUpdatesForWorker from 'utils/assessments/validateAssessmentUpdatesForWorker';

export default function editStudyPathAssessmentsList(editedSummativeAssessment: SummativeAssessmentApi, startedAssessmentIds: Array<string>, triggerAssessmentWorker: Function) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { assessments } } = getStore();
    // get all assessments from store to start with
    const currentSummativeAssessment = assessments.find(({ id }) => id === editedSummativeAssessment.id) as SummativeAssessmentApi;
    if (!currentSummativeAssessment) {
      return false;
    }
    const { prep: currentFullPrepAssessment, prep: { id: prepAssessmentId }, practiceTest: currentFullPracticeAssessment, practiceTest: { id: practiceAssessmentId } } = currentSummativeAssessment;

    const {
      published: oldPublished,
    } = currentSummativeAssessment;
    const {
      prep: newPrep,
      practiceTest: newPractice,
      published: newPublished,
    } = editedSummativeAssessment;
    if (!currentFullPrepAssessment || !currentFullPracticeAssessment || !newPrep || !newPractice) {
      throw new Error(`updateStudyPath missing required data ${JSON.stringify({ currentFullPrepAssessment, currentFullPracticeAssessment, newPrep, newPractice })}`);
    }

    const summativeDetailsChanged = oldPublished !== newPublished;
    const prepHasChanged = comparePrepPracticeAssessments(currentFullPrepAssessment, newPrep);
    const practiceHasChanged = comparePrepPracticeAssessments(currentFullPracticeAssessment, newPractice);

    // aggregate data for update request
    const { prep, practiceTest, ...currentAssessmentData } = currentSummativeAssessment;
    let newSummativeAssessment: SummativeAssessmentApi = {
      ...currentAssessmentData,
      prep,
      practiceTest,
    };
    if (summativeDetailsChanged) {
      console.debug('summativeDetailsChanged', newPublished);
      newSummativeAssessment = {
        ...newSummativeAssessment,
        published: newPublished,
      };
    }
    if (prepHasChanged) {
      const { gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, latePolicy, lateDate, latePenalty, published } = newPrep;
      console.debug('prepPolicyChanged', gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, latePolicy, lateDate, latePenalty, published);
      const { prep: newPrepAssessment } = newSummativeAssessment;
      newSummativeAssessment = {
        ...newSummativeAssessment,
        prep: {
          ...newPrepAssessment,
          ...newPrep,
        },
      };
    }
    if (practiceHasChanged) {
      const { gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, latePolicy, lateDate, latePenalty, published } = newPractice;
      console.debug('practicePolicyChanged', gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, lateDate, latePenalty, latePolicy, published);
      const { practiceTest: newPracticeAssessment } = newSummativeAssessment;
      newSummativeAssessment = {
        ...newSummativeAssessment,
        practiceTest: {
          ...newPracticeAssessment,
          ...newPractice,
        },
      };
    }

    const isWorkerProcessingEligible = validateAssessmentUpdatesForWorker(currentSummativeAssessment, newSummativeAssessment, startedAssessmentIds) ||
    validateAssessmentUpdatesForWorker(prep, newSummativeAssessment.prep, startedAssessmentIds) ||
    validateAssessmentUpdatesForWorker(practiceTest, newSummativeAssessment.practiceTest, startedAssessmentIds);

    if (isWorkerProcessingEligible) {
      return await triggerAssessmentWorker(currentSummativeAssessment, newSummativeAssessment, { prepHasChanged, practiceHasChanged });
    }
    const updatedSummative = await apiNext.editAssessment(newSummativeAssessment.id, newSummativeAssessment) as SummativeAssessmentApi;
    console.debug('updatedSummative', updatedSummative);

    const { prep: updatedPrep, practiceTest: updatedPractice } = updatedSummative;
    dispatch(activeSlice.actions.editActiveAssessment({ id: prepAssessmentId, delta: updatedPrep }));
    dispatch(activeSlice.actions.editActiveAssessment({ id: practiceAssessmentId, delta: updatedPractice }));
    dispatch(activeSlice.actions.editActiveAssessment({
      id: currentSummativeAssessment.id,
      delta: {
        ...updatedSummative,
        prep: updatedPrep,
        practiceTest: updatedPractice,
        studyPathId: currentSummativeAssessment.studyPathId,
      },
    }));
    return {
      ...updatedSummative,
      prep: updatedPrep,
      practiceTest: updatedPractice,
      studyPathId: currentSummativeAssessment.studyPathId,
    };
  })();
}
