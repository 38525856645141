import React from 'react';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';


export const renderBadge = (assessmentStatus: StudentAssessmentStatus, lateString: string | null, latePenalty: number | null) => {
  if (assessmentStatus === StudentAssessmentStatus.CompletedBeforeDue) {
    return <span className="completed-on-time-badge">COMPLETED ON TIME</span>;
  } else if (assessmentStatus === StudentAssessmentStatus.CompletedBeforeLate) {
    return <span className="completed-late-badge">COMPLETED LATE</span>;
  } else if ([StudentAssessmentStatus.NotStartedAfterLate, StudentAssessmentStatus.InProgressAfterLate].includes(assessmentStatus)) {
    return <span className="past-due-badge">INCOMPLETE</span>;
  } else if ([StudentAssessmentStatus.NotStartedBeforeLate, StudentAssessmentStatus.InProgressBeforeLate].includes(assessmentStatus) && latePenalty !== null && !!lateString) {
    return <span className="completed-late-badge">Late: Earning {100 - latePenalty}% points until {lateString}</span>;
  } else if (assessmentStatus === StudentAssessmentStatus.CompletedAfterLate) {
    return <span className="completed-late-badge">COMPLETED FOR PRACTICE</span>;
  } else {
    return null;
  }
};

export default renderBadge;


