import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDocumentTitle from 'hooks/useDocumentTitle';
import getAssessmentControllerQuestions, { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import loadStudentAssessmentsAndStudentAssessmentQuestions from 'store/actions/loadStudentAssessmentsAndStudentAssessmentQuestions';
import reloadAssessmentQuestions from 'store/actions/reloadAssessmentQuestions';
import { useAppDispatch } from 'store';
import retrieveActiveCourseLearningObjectives from 'store/selectors/retrieveActiveCourseLearningObjectives';
import retrieveAssessmentsWithEnrollment from 'store/selectors/retrieveAssessmentsWithEnrollment';
import AssessmentTakerRoot from './AssessmentTaker/AssessmentTakerRoot';
import AssessmentTakerReadinessExperience from './AssessmentTaker/ReadinessExperience/ReadinessExperience';
import { Store } from 'types/store.types';
import { AssessmentLocation } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';

function AssessmentController() {
  const dispatch = useAppDispatch();
  const { assessmentId: currentAssessmentId } = useParams<{ assessmentId: string }>();
  const activeAQMs: Array<AssessmentQuestionApi> = useSelector((store: Store) => store.active.assessmentQuestionMaps);
  const allQuestions = useSelector((store: Store) => [...store.active.templateQuestions, ...store.active.userQuestions]);
  const enrichedAssessments = useSelector(retrieveAssessmentsWithEnrollment);
  const courseLearningObjectives = useSelector(retrieveActiveCourseLearningObjectives);
  const currentAssessmentData = enrichedAssessments.find(({ id }) => id === currentAssessmentId);
  const [assessmentControllerQuestions, setAssessmentControllerQuestions] = useState([] as Array<AssessmentControllerQuestion>);
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const user = useSelector((store: Store) => store.user);
  useDocumentTitle(`${currentAssessmentData?.name || ''} Assessment Taker`);

  // Update studentAssessments and studentAssessmentQuestions when a user leaves an assessment (unloads Assessments Controller)
  // over time this could be more efficient to update only the assessment that is exiting
  // or even be done question by question during the assessment
  // But for pilot this should be just fine
  useEffect(() => {
    return () => {
      dispatch(loadStudentAssessmentsAndStudentAssessmentQuestions());
    };
  }, [dispatch]);


  useEffect(() => {
    // rely on questionsLoaded state rather than presence of questions to account for assessments wiyh noo qs
    if (!!activeAQMs.length && !!allQuestions.length && !questionsLoaded) {
      const updatedQuestionsState = getAssessmentControllerQuestions({
        assessmentId: currentAssessmentId,
        assessmentQuestionMaps: activeAQMs,
        courseLearningObjectives,
        questions: allQuestions,
      });
      setAssessmentControllerQuestions(updatedQuestionsState);
      setQuestionsLoaded(true);
    }
  }, [currentAssessmentId, activeAQMs, allQuestions, questionsLoaded, courseLearningObjectives]);


  async function refreshAssessmentQuestions(assessmentIdToReload: string) {
    let updatedQuestions = [] as Array<AssessmentControllerQuestion>;
    await dispatch(reloadAssessmentQuestions([assessmentIdToReload])).then(updatedAQs => {
      const updatedQuestionsState = getAssessmentControllerQuestions({
        assessmentId: currentAssessmentId,
        assessmentQuestionMaps: updatedAQs,
        courseLearningObjectives,
        questions: allQuestions,
      });
      setAssessmentControllerQuestions(updatedQuestionsState);
      updatedQuestions = updatedQuestionsState;
    });
    return updatedQuestions;
  }

  if (!currentAssessmentData) {
    return null;
  }

  if (currentAssessmentData.assessType === AssessTypeEnum.Readiness) {
    return (
      <main className="student-home readiness-experience-wrap">
        <AssessmentTakerReadinessExperience
          assessmentData={currentAssessmentData}
          location={AssessmentLocation.REX}
          questions={assessmentControllerQuestions}
          refreshAssessmentQuestions={refreshAssessmentQuestions}
        />
      </main>
    );
  }
  return (
    <main className="student-home">
      <AssessmentTakerRoot
        assessmentData={currentAssessmentData}
        userRoleId={user.roleId}
        userId={user.id}
        questions={assessmentControllerQuestions}
        refreshAssessmentQuestions={refreshAssessmentQuestions}
      />
    </main>
  );
}

export default AssessmentController;
