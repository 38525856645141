import React from 'react';
import { DateTime } from 'luxon';
import { determineMultipleAttemptPolicy } from 'utils/assessmentFunctions';
import { formatPlural, formatPoints } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import renderBadge from './renderBadge';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import Instructions from './Instructions';
import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { PositionEnum } from 'types/common.types';
import './AssessmentTakerHeader.scss';

const AssessmentTakerHeader = ({
  assessmentData,
  assessmentStatus,
  totalPoints,
}: {
  assessmentData: AssessmentWithEnrollment
  assessmentStatus: StudentAssessmentStatus
  totalPoints: number
}) => {
  const {
    freeAttempts,
    gradingPolicy,
    lateDate,
    latePenalty,
    mergedDueDate,
    name,
    pointPenalty,
  } = assessmentData;
  const attemptPolicy = determineMultipleAttemptPolicy(freeAttempts, pointPenalty, gradingPolicy);
  const dueString = DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.WeekdayDateAtTime);
  const lateString = !!lateDate ? DateTime.fromISO(lateDate).toFormat(DateFormatEnum.WeekdayDateAtTime) : null;
  return (
    <div className="assessment-taker-header">
      <div className="assessment-taker-header__name">
        <h2 className="assessment-taker-root__assessment-name h2">{ name }</h2>
        <span className="assessment-taker-root__indicator">
          <BetterTooltip
            key={assessmentData.id}
            indicate
            wide
            position={PositionEnum.BottomRight}
            content={() => (
              <Instructions assessmentData={assessmentData} attemptPolicy={attemptPolicy} />
            )}
          />
        </span>
      </div>
      <div className="assessment-taker-header__subhead row">
        <div className="points-possible">{ formatPoints(totalPoints) } { formatPlural('Point', totalPoints) } Possible</div>
        <span role="separator"> | </span>
        <div>Due { dueString }</div>
        <div className="assessment-taker-root__assessment-status">
          {renderBadge(assessmentStatus, lateString, latePenalty)}
        </div>
      </div>
    </div>
  );
};

export default AssessmentTakerHeader;
