import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { QuestionApiOut } from 'types/backend/questions.types';

export interface AssessmentControllerQuestion extends QuestionApiOut {
  assessmentQuestionId?: number
  assessmentQuestionPoints?: number
  id: number
  order?: number
  previousId?: number
  createdAt: string
  updatedAt: string
  loData: Array<EnrichedCourseLearningObjective>
}

const getAssessmentControllerQuestions = ({
  assessmentId,
  assessmentQuestionMaps,
  courseLearningObjectives,
  questions,
}: {
  assessmentId: string
  assessmentQuestionMaps: Array<AssessmentQuestionApi>
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  questions: Array<QuestionApiOut>
}) => {
  const aqmsForAssessment = assessmentQuestionMaps.filter((aqm: AssessmentQuestionApi) => aqm.assessmentId === assessmentId);
  const questionsFromAqms = aqmsForAssessment.map((aqm: AssessmentQuestionApi) => questions.find((atq) => atq.id === aqm.questionId) as QuestionApiOut);

  const questionsWithAssessmentQuestionIds = questionsFromAqms.map((questionData: QuestionApiOut) => {
    const { id: assessmentQuestionId, points: assessmentQuestionPoints } = aqmsForAssessment.find(({ questionId }: { questionId: number }) => questionId === questionData?.id) || {};
    console.assert(assessmentQuestionId, `QuestionId: ${questionData?.id} not found in aqms`);
    const { learningObjectiveIds = [] } = questionData;
    const loData = courseLearningObjectives.filter((clo) => learningObjectiveIds.includes(clo.id));
    return {
      ...questionData,
      assessmentQuestionId,
      assessmentQuestionPoints,
      loData,
    };
  });
  return questionsWithAssessmentQuestionIds as Array<AssessmentControllerQuestion>;
};

export default getAssessmentControllerQuestions;
