import { DateTime } from 'luxon';
import { AssessmentApiBase, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { CreateAssessmentBody, CreateSummativeAssessmentBody } from 'types/common.types';
import { YesNo } from 'types/backend/shared.types';


const validateAssessmentUpdatesForWorker = (
  prevAssessment: AssessmentApiBase | SummativeAssessmentApi,
  newAssessment: CreateAssessmentBody | CreateSummativeAssessmentBody,
  startedAssessmentIds: Array<string> = []
): boolean => {

  // © Hope's conditions
  // the assessment is published AND
  // there are student-assessments AND
  // at least one of the following is true:
  // - the old dueDate is in the past and the new dueDate is in the future OR
  // - the old lateDate is in the past and the new lateDate is in the future OR
  // - the dueDate is in the past and the old latePolicy is no and the new latePolicy is yes
  if (prevAssessment.published === YesNo.No) {
    return false;
  }

  if (startedAssessmentIds.length === 0 || !startedAssessmentIds.includes(prevAssessment.id)) {
    return false;
  }

  const now = DateTime.now();

  const { dueDate, lateDate, latePolicy: prevLatePolicy } = prevAssessment;
  const prevDueDate = DateTime.fromISO(dueDate, { zone: 'utc' });
  const prevLateDate = lateDate && DateTime.fromISO(lateDate, { zone: 'utc' });

  const { dueDate: newDue, lateDate: newLate, latePolicy: newLatePolicy } = newAssessment;
  const newDueDate = DateTime.fromISO(newDue, { zone: 'utc' });
  const newLateDate = newLate && DateTime.fromISO(newLate, { zone: 'utc' });

  return [
    prevDueDate < now && newDueDate > now,
    prevLateDate && prevLateDate < now && newLateDate && newLateDate > now,
    prevDueDate < now && prevLatePolicy === YesNo.No && newLatePolicy === YesNo.Yes,
  ].some(Boolean);
};

export default validateAssessmentUpdatesForWorker;
