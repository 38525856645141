import React from 'react';
import PropTypes from 'prop-types';
import { formatPlural, formatPoints } from 'utils/commonFormattingFunctions';
import logExecutionTime from 'utils/logExecutionTime';
// NOTE: react-script-tag throws an error for using componentWillUpdate and componentWillReceiveProps, if in future we update react we will need to revisit
import ScriptTag from 'shared-components/ScriptTag/ScriptTag';

import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import AssessmentTakerActionBar from 'shared-components/AssessmentTakerActionBar/AssessmentTakerActionBar';
import apiNext from 'api-next';
import {
  determineFinishButtonState,
  distractorRationaleTemplate,
  renderFrozenVatMessage,
  LearnosityWrap,
} from './l8yContainerFunctions';
import { AssessmentLocation, LibraryTypeEnum, YesNo } from 'types/backend/shared.types';
import { StudentCoursePath } from 'types/student.types';
import { DirectionEnum, MultipleAttemptPolicyEnum } from 'types/common.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { GradingTypeTag, L8yQuestionType } from 'types/backend/l8y.types';
import { L8ySessionState } from 'types/backend/l8ySessions.types';
import { L8yContainerEvents, L8yEvents } from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import { RenderingTypeEnum, AssessmentModeEnum } from 'types/backend/assessmentInit.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTaker/AssessmentTaker.types';
import './LearnosityContainer.scss';

/*
  LearnosityItem Component
  Intention: Thinnest-possible layer to deal with eventing/rendering of L8y assessment rectangles. Should be used across various assessment types, with most style/logic
  within the parent component.
  Notes: This component is deliberately using the old-school component class format because of the requirements/limitations of Learnosity
  we want to limit re-renders and persist the ItemsAPI to `this` for events
  Also, it's not a .tsx component yet because trying to do TypeScript hurdles whilst figuring out L8y was a bit much, eventually this should, like all new components, be converted to TS
*/

export default class LearnosityContainer extends React.Component {
  static propTypes = {
    activityId: PropTypes.string.isRequired,
    assessmentMode: PropTypes.oneOf(Object.values(AssessmentModeEnum)),
    assessmentType: PropTypes.oneOf([...Object.values(AssessTypeEnum), null]),
    attemptLimit: PropTypes.number,
    attemptPolicy: PropTypes.oneOf(Object.values(MultipleAttemptPolicyEnum)),
    courseId: PropTypes.string,
    enableClarity: PropTypes.bool,
    handleEvents: PropTypes.func.isRequired,
    onL8yError: PropTypes.func,
    initAttemptsHash: PropTypes.object,
    initClarityHash: PropTypes.object,
    initCorrectHash: PropTypes.object,
    initEverCorrectHash: PropTypes.object,
    initLatePointsDeductedHash: PropTypes.object,
    initPointsHash: PropTypes.object,
    initRecapHash: PropTypes.object,
    initVatFrozenHash: PropTypes.object,
    initState: PropTypes.oneOf(Object.values(L8ySessionState)),
    inReviewMode: PropTypes.bool.isRequired,
    isAfterLate: PropTypes.bool,
    isInstructor: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    l8yBoxClassName: PropTypes.string,
    l8ySessionId: PropTypes.string,
    location: PropTypes.oneOf(Object.values(AssessmentLocation)).isRequired,
    name: PropTypes.string,
    questionData: PropTypes.array,
    renderingType: PropTypes.oneOf(Object.values(RenderingTypeEnum)),
    renderItemNav: PropTypes.func,
    targetL8yId: PropTypes.string,
    todoQuestionL8yIds: PropTypes.arrayOf(PropTypes.string),
    userId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeAssessmentQuestion: null,
      activeItemId: null,
      activeItemIndex: null,
      activeL8yRef: props.targetL8yId || null,
      attemptsHash: props.initAttemptsHash,
      clarityHash: props.initClarityHash,
      correctHash: props.initCorrectHash || {},
      everCorrectHash: props.initEverCorrectHash || {},
      itemHasAllORQsHash: {},
      latePointsDeductedHash: props.initLatePointsDeductedHash || {},
      recapHash: props.initRecapHash || {},
      vatFrozenHash: props.initVatFrozenHash || {},
      enableValidate: false,
      inputChanged: false,
      itemQuestions: null,
      l8yReady: false,
      pointsHash: props.initPointsHash,
      questionIds: [],
      currentStage: AssessmentTakerQuestionStage.INIT,
    };
    console.debug('constructor targetL8yId', props.targetL8yId);
    this.startTime = performance.now();
    this.activeQuestionMethods = [];
    this.lastValidation = 0;
    this.isVat = props.location === AssessmentLocation.AT;
    this.isPreview = props.location === AssessmentLocation.Preview;
    this.isStudyPath = [AssessmentLocation.SP, AssessmentLocation.SPPTAT].includes(props.location);
    // persist initial list of l8y items because changing them later doesn't affect l8y and causes other unintended side effects
    this.l8yItemList = props.items.map(({ l8yId }) => l8yId);
    this.hideValidationUI = props.assessmentType === AssessTypeEnum.PracticeTest && props.location === AssessmentLocation.AT;
    // get hash of total points available per question
    this.aqPointsHash = props.questionData.reduce((acc, q) => {
      const { assessmentQuestionPoints, l8yId } = q;
      return {
        ...acc,
        [l8yId]: assessmentQuestionPoints,
      };
    }, {});
    this.surveyItemsHash = props.questionData.reduce((acc, { l8yId, gradingType }) => {
      return {
        ...acc,
        [l8yId]: gradingType === GradingTypeTag.Survey,
      };
    }, {});
  }

  componentDidUpdate(prevProps) {
    if (!!this.itemsObject && prevProps.targetL8yId !== this.props.targetL8yId) {
      console.debug(`componentDidUpdate goto ${this.props.targetL8yId} from ${prevProps.targetL8yId}`);
      this.itemsObject.goto(this.props.targetL8yId);
    }
  }

  transition = (nextStage) => {
    const { currentStage } = this.state;
    console.debug(`:::: L8y stage transition ${currentStage} => ${nextStage}`);
    this.setState({ currentStage: nextStage });
  };

  handleItemLoad = () => {
    try {
      const { handleEvents } = this.props;
      const { itemQuestions, vatFrozenHash, activeL8yRef } = this.state;
      // TODO Cleanup: This doesn't return the same data as it used to, this is always an array of one questionId
      const questionIds = itemQuestions.map(q => q.response_id);
      this.setState({ questionIds });
      const questions = questionIds.reduce((acc, q) => {
        return {
          ...acc,
          [q]: this.itemsAPI.question(q),
        };
      }, {});

      // reset active question methods
      this.activeQuestionMethods = [];
      const vatFrozen = this.isVat && vatFrozenHash[activeL8yRef] === YesNo.Yes;
      if (vatFrozen) {
        this.setState({ enableValidate: false });
      }
      const isSurveyItem = this.surveyItemsHash[activeL8yRef];

      Object.values(questions).forEach((q, i) => {
        // Store methods for each question in an array for use elsewhere in the component
        this.activeQuestionMethods[i] = q;
        if (vatFrozen) {
          q.disable();
        }
        // fires when question inputs change
        q.on('changed', () => {
          this.handleInputChanged();
          handleEvents({ type: L8yContainerEvents.QUESTION_CHANGED });
          const l8yQuestionData = q.getQuestion();
          if ([
            L8yQuestionType.MultipleChoiceMultipleSelect,
            L8yQuestionType.EssayRichText,
            L8yQuestionType.ClozeDropDown,
            L8yQuestionType.LabelImageDropDown,
            L8yQuestionType.Hotspot,
            L8yQuestionType.Sequence,
            L8yQuestionType.OrderList,
            L8yQuestionType.Sorting,
            L8yQuestionType.MatchList,
            L8yQuestionType.LabelImageDragAndDrop,
          ].includes(l8yQuestionData.type)) {
            this.removeDistractor(l8yQuestionData.response_id);
          }
        });
        // fires on Check Answer
        q.on('validated', async () => {
          if (this.hideValidationUI || isSurveyItem) {
            q.resetValidationUI();
          } else {
            const l8yQuestionData = q.getQuestion();
            const { type } = l8yQuestionData;
            switch (type) {
              case L8yQuestionType.MultipleChoiceMultipleSelect:
                if (l8yQuestionData.multiple_responses) {
                  this.renderFeedbackMSQ(q, l8yQuestionData);
                } else {
                  this.renderFeedbackMCQ(q, l8yQuestionData);
                }
                break;
              case L8yQuestionType.ClozeDropDown:
                this.renderFeedbackClozeDDQ(q, l8yQuestionData);
                break;
              case L8yQuestionType.LabelImageDropDown:
                this.renderFeedbackLabelImageDDQ(q, l8yQuestionData);
                break;
              case L8yQuestionType.Hotspot:
                this.renderFeedbackHotspotQ(q, l8yQuestionData);
                break;
              case L8yQuestionType.Sequence:
                this.renderFeedbackSortListQ(q, l8yQuestionData);
                break;
              case L8yQuestionType.OrderList:
                this.renderFeedbackOrderListQ(q, l8yQuestionData);
                break;
              case L8yQuestionType.Sorting:
                this.renderFeedbackClassificationQ(q, l8yQuestionData);
                break;
              case L8yQuestionType.MatchList:
                this.renderFeedbackMatchListQ(q, l8yQuestionData);
                break;
              case L8yQuestionType.LabelImageDragAndDrop:
                this.renderFeedbackLabelImageDragAndDropQ(q, l8yQuestionData);
                break;
              default:
                console.debug(`No custom feedback display function available for question type ${type}`);
            }
          }
          await this.handleValidateAllQuestionsInItem(questions);
        });
      });
    } catch (err) {
      console.error('err', err, this.itemsAPI.questions());
    }
  };

  renderDistractor = (id, isCorrect, content) => {
    // src: https://github.com/Learnosity/learnosity-demos/blob/master/www/assessment/distractor-rationale.php#L316
    if (!document.querySelector(`[id='#${id}_distractor']`)) {
      const template = distractorRationaleTemplate(id, isCorrect, content);
      document.querySelector(`[id='${id}']`).insertAdjacentHTML('beforeend', template);
    }
  };

  removeDistractor = (id) => {
    if (!!document.querySelector(`[id='#${id}_distractor']`)) {
      document.querySelector(`[id='#${id}_distractor']`).remove();
    }
    const questionDiv = document.querySelector(`[id='${id}']`);
    if (questionDiv) {
      const orWrapper = questionDiv.querySelector('.lrn_response_input_wrapper');
      if (orWrapper) {
        orWrapper.className = 'lrn_response_input_wrapper';
      }
    }
  };

  showOpenResponseAnswers = (responseId, sampleAnswer, showFirstAnswer, qIndex) => {
    const { attemptPolicy } = this.props;
    const { activeL8yRef } = this.state;
    const isSurveyItem = this.surveyItemsHash[activeL8yRef];
    if (!isSurveyItem) {
      const questionDiv = document.querySelector(`[id='${responseId}']`);
      if (questionDiv) {
        const wrapper = questionDiv.querySelector('.lrn_response_input_wrapper');
        wrapper.className = 'lrn_response_input_wrapper lrn_correct lrn_complete';
      }
      if (!document.querySelector(`[id='#${responseId}_distractor']`)) {
        let firstAnswerContent = '';
        if (showFirstAnswer) {
          const { activeAssessmentQuestion } = this.state;
          const { latestVatStudentAssessmentQuestionAttempt } = activeAssessmentQuestion;
          let firstAnswer = null;
          if (!!latestVatStudentAssessmentQuestionAttempt) {
            const { attemptData } = latestVatStudentAssessmentQuestionAttempt;
            firstAnswer = attemptData[qIndex].value;
          }
          firstAnswerContent = `
            <div class="lrn_distractor_rationale_list lrn_distractor_rationale_correct lrn_distractor_rationale_first" aria-label="distractor rationale per response">
              <div class="lrn_distractor_rationale_list_title">Your First Answer – You gave this answer when first taking the Practice Test.</div>
              <div class="lrn_distractor_rationale">
                <div class="lrn_distractor_rationale_content">${firstAnswer || '<i>No answer submitted</i>'}</div>
              </div>
            </div>
          `;
        }
        let sampleAnswerSuffix = '';
        if (this.isPreview) {
          sampleAnswerSuffix = '<br>Note to Instructors – Students earn full points upon submission of open-response questions, regardless of grading policy. Instructor grading of open-response questions is not available.';
        } else if (!!attemptPolicy && attemptPolicy !== MultipleAttemptPolicyEnum.NotForPoints) {
          sampleAnswerSuffix = '<br>You have earned full points for attempting this question.';
        }
        const template = `
          <div id="#${responseId}_distractor" class="lrn_distractor_rationale_wrapper" role="region" aria-label="distractor rationale">
            ${firstAnswerContent}
            <div class="lrn_distractor_rationale_list lrn_distractor_rationale_correct lrn_distractor_rationale_complete" aria-label="distractor rationale per response complete">
              <div class="lrn_distractor_rationale_list_title">Sample Answer – Assess your understanding by comparing the sample answer to your own.${sampleAnswerSuffix}</div>
              <div class="lrn_distractor_rationale">
                <div class="lrn_distractor_rationale_content">${sampleAnswer}</div>
              </div>
            </div>
          </div>
        `;
        document.querySelector(`[id='${responseId}']`).insertAdjacentHTML('beforeend', template);
      }
    }
  };

  renderFeedbackMCQ = (questionMethods, questionData) => {
    const distractorRationaleResponseLevel = questionMethods.mapValidationMetadata('distractor_rationale_response_level');
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (distractorRationaleResponseLevel !== false) {
      const { correct, incorrect } = distractorRationaleResponseLevel;
      if (questionMethods.isValid()) {
        const [ { metadata } ] = correct;
        isCorrect = YesNo.Yes;
        outputHTML = metadata;
      } else {
        const [ { metadata } ] = incorrect;
        outputHTML = metadata;
      }
    }
    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackMSQ = (questionMethods, questionData) => {
    // src: https://github.com/Learnosity/learnosity-demos/blob/master/www/assessment/distractor-rationale.php#L270

    // hide default L8y formatting
    questionMethods.resetValidationUI();

    const distractorRationaleResponseLevel = questionMethods.mapValidationMetadata('distractor_rationale_response_level');
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: show the general feedback
      isCorrect = YesNo.Yes;
      outputHTML = questionMethods.getMetadata().distractor_rationale;
    } else if (distractorRationaleResponseLevel !== false) {
      const { correct, incorrect, unattempted } = distractorRationaleResponseLevel;
      const someCorrect = !!correct.length;
      const someIncorrect = !!incorrect.length;
      const someUnattempted = !!unattempted.length;
      if ((someCorrect && !someIncorrect && someUnattempted)
        || (!someCorrect && !someIncorrect && someUnattempted)) {
        // if not fully correct and no incorrect answers selected, or nothing selected
        outputHTML = 'At least one more option should be selected.';
      } else if (someIncorrect) {
        // if some incorrect answers selected: show specific feedback for those answers
        for (const data of incorrect) {
          const distractor = data.metadata;
          if (!!distractor) {
            outputHTML = `${outputHTML}<li>${distractor}</li>`;
          }
        }
        if (!!outputHTML) {
          outputHTML = `<ul>${outputHTML}</ul>`;
        }
      }
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackClozeDDQ = (questionMethods, questionData) => {
    const distractorRationaleResponseLevel = questionMethods.mapValidationMetadata('distractor_rationale_response_level');
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: show the general feedback
      isCorrect = YesNo.Yes;
      outputHTML = questionMethods.getMetadata().distractor_rationale;
    } else {
      const response = questionMethods.getResponse().value;
      const { validation: { valid_response: { value: correctAnswer } } } = questionData;
      const anyIncorrectlyBlank = correctAnswer.some((c, i) => !!c && !response[i]);
      const anyIncorrect = correctAnswer.some((c, i) => !!response[i] && response[i] !== c);
      if (distractorRationaleResponseLevel !== false) {
        // if there's feedback written for incorrect responses: show that
        const { incorrect } = distractorRationaleResponseLevel;
        for (const inc of incorrect) {
          const { value, metadata } = inc;
          if (!!value && !!metadata) {
            outputHTML = `${outputHTML}<li>${metadata}</li>`;
          }
        }
        if (outputHTML) {
          outputHTML = `<ul>${outputHTML}</ul>`;
        }
      }
      // if any incorrectly blank and no incorrect responses: show the generic message
      if (!outputHTML && anyIncorrectlyBlank && !anyIncorrect) {
        outputHTML = 'You have not filled in all the blanks.';
      }
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackLabelImageDDQ = (questionMethods, questionData) => {
    const distractorRationaleResponseLevel = questionMethods.mapValidationMetadata('distractor_rationale_response_level');
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: show the general feedback
      isCorrect = YesNo.Yes;
      outputHTML = questionMethods.getMetadata().distractor_rationale;
    } else {
      const response = questionMethods.getResponse().value;
      const { validation: { valid_response: { value: correctAnswer } } } = questionData;
      if (distractorRationaleResponseLevel !== false) {
        // if there's feedback written for incorrect responses: show that
        const { incorrect } = distractorRationaleResponseLevel;
        for (const inc of incorrect) {
          const { value, metadata } = inc;
          if (!!value && !!metadata) {
            outputHTML = `${outputHTML}<li>${metadata}</li>`;
          }
        }
        if (outputHTML) {
          outputHTML = `<ul>${outputHTML}</ul>`;
        }
      }
      const anyIncorrectlyBlank = correctAnswer.some((c, i) => !!c && !response[i]);
      const anyIncorrect = correctAnswer.some((c, i) => !!response[i] && response[i] !== c);
      // if any incorrectly blank and no incorrect responses: show the generic message
      if (!outputHTML && anyIncorrectlyBlank && !anyIncorrect) {
        outputHTML = 'You have not filled in all the blanks.';
      }
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackHotspotQ = (questionMethods, questionData) => {
    // distractor rationale field for hotspot contains general *incorrect* feedback
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: just show correct banner
      isCorrect = YesNo.Yes;
    } else {
      const distractorRationale = questionMethods.getMetadata().distractor_rationale;
      const response = questionMethods.getResponse().value;
      const { validation: { valid_response: { value: correctAnswer } } } = questionData;
      const anyIncorrect = response.some(r => !correctAnswer.includes(r));
      if (!anyIncorrect) {
        // if no incorrect responses: show generic message
        outputHTML = 'At least one more area should be selected.';
      } else if (distractorRationale !== false) {
        // if general incorrect feedback is written: show that
        outputHTML = distractorRationale;
      }
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackSortListQ = (questionMethods, questionData) => {
    // distractor rationale field for sortlist contains general *incorrect* feedback
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: just show correct banner
      isCorrect = YesNo.Yes;
    } else {
      const distractorRationale = questionMethods.getMetadata().distractor_rationale;
      const response = questionMethods.getResponse().value;
      const { validation: { valid_response: { value: correctAnswer } } } = questionData;
      // response values are numeric indexes, so we need to account for 0
      const anyIncorrect = correctAnswer.some((c, i) => (!!response[i] || response[i] === 0) && response[i] !== c);
      if (!anyIncorrect) {
        // if no incorrect responses: show generic message
        outputHTML = 'A complete answer includes all entries in the Source list.';
      } else if (distractorRationale !== false) {
        // if general incorrect feedback is written: show that
        outputHTML = distractorRationale;
      }
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackOrderListQ = (questionMethods, questionData) => {
    // distractor rationale field for orderlist contains general *incorrect* feedback
    const distractorRationale = questionMethods.getMetadata().distractor_rationale;
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: just show correct banner
      isCorrect = YesNo.Yes;
    } else if (distractorRationale !== false) {
      // if general incorrect feedback is written: show that
      outputHTML = distractorRationale;
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackClassificationQ = (questionMethods, questionData) => {
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: show the general feedback
      isCorrect = YesNo.Yes;
      outputHTML = questionMethods.getMetadata().distractor_rationale;
    } else {
      // determine which items are incorrectly placed so that we can show the feedback for them:
      // we can't trust L8y's mapValidationMetadata's distractorRationaleResponseLevel for this,
      // because it doesn't include items placed in correct AND incorrect containers as incorrect
      const response = questionMethods.getResponse().value;
      const { validation: { valid_response: { value: correctAnswer } }, metadata: { distractor_rationale_response_level } } = questionData;
      const itemsToShowFeedbackFor = [];
      let anyIncorrectlyUnplaced = false;
      for (let i = 0; i < response.length; i++) {
        const responseBinContents = response[i];
        const correctAnswerBinContents = correctAnswer[i];
        if (!!responseBinContents) {
          for (const responseItem of responseBinContents) {
            if (!correctAnswerBinContents.includes(responseItem)) {
              itemsToShowFeedbackFor.push(responseItem);
            }
          }
        }
        if (!!correctAnswerBinContents.length && (!responseBinContents || correctAnswerBinContents.some(c => !responseBinContents.includes(c)))) {
          anyIncorrectlyUnplaced = true;
        }
      }
      const itemsToShowFeedbackForUnique = [...new Set(itemsToShowFeedbackFor)].sort();
      if (itemsToShowFeedbackForUnique.length && !!distractor_rationale_response_level) {
        // if there's feedback written for any of the incorrect items: show that
        for (const item of itemsToShowFeedbackForUnique) {
          if (!!distractor_rationale_response_level[item]) {
            outputHTML = `${outputHTML}<li>${distractor_rationale_response_level[item]}</li>`;
          }
        }
      }
      if (outputHTML) {
        outputHTML = `<ul>${outputHTML}</ul>`;
      }

      // if all responses that are placed are placed correctly but at least 1 response is incorrectly unplaced: show generic message
      const anyIncorrect = !!itemsToShowFeedbackForUnique.length;
      if (!outputHTML && anyIncorrectlyUnplaced && !anyIncorrect) {
        outputHTML = 'There is at least one more item that belongs in a bin.';
      }
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackMatchListQ = (questionMethods, questionData) => {
    const distractorRationaleResponseLevel = questionMethods.mapValidationMetadata('distractor_rationale_response_level');
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: show the general feedback
      isCorrect = YesNo.Yes;
      outputHTML = questionMethods.getMetadata().distractor_rationale;
    } else {
      const response = questionMethods.getResponse().value;
      const { validation: { valid_response: { value: correctAnswer } } } = questionData;
      if (distractorRationaleResponseLevel !== false) {
        // if there's feedback written for incorrect responses: show that
        const { incorrect } = distractorRationaleResponseLevel;
        for (const inc of incorrect) {
          const { value, metadata } = inc;
          if (!!value && !!metadata) {
            outputHTML = `${outputHTML}<li>${metadata}</li>`;
          }
        }
        if (outputHTML) {
          outputHTML = `<ul>${outputHTML}</ul>`;
        }
      }
      const anyIncorrectlyBlank = correctAnswer.some((c, i) => !!c && !response[i]);
      const anyIncorrect = correctAnswer.some((c, i) => !!response[i] && response[i] !== c);
      // if any incorrectly blank and no incorrect responses: show the generic message
      if (!outputHTML && anyIncorrectlyBlank && !anyIncorrect) {
        outputHTML = 'You have not filled in all the boxes.';
      }
    }

    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  renderFeedbackLabelImageDragAndDropQ = (questionMethods, questionData) => {
    const distractorRationaleResponseLevel = questionMethods.mapValidationMetadata('distractor_rationale_response_level');
    let outputHTML = '';
    let isCorrect = YesNo.No;
    if (questionMethods.isValid()) {
      // if fully correct: show the general feedback
      isCorrect = YesNo.Yes;
      outputHTML = questionMethods.getMetadata().distractor_rationale;
    } else {
      const response = questionMethods.getResponse().value;
      const { validation: { valid_response: { value: correctAnswer } } } = questionData;
      if (distractorRationaleResponseLevel !== false) {
        // if there's feedback written for incorrect responses: show that
        const { incorrect } = distractorRationaleResponseLevel;
        for (const inc of incorrect) {
          const { value, metadata } = inc;
          if (!!value.length && !!metadata) {
            outputHTML = `${outputHTML}<li>${metadata}</li>`;
          }
        }
        if (outputHTML) {
          outputHTML = `<ul>${outputHTML}</ul>`;
        }
      }
      const anyIncorrectlyBlank = correctAnswer.some((c, i) => !!c.length && !response[i].length);
      const anyIncorrect = correctAnswer.some((c, i) => !!response[i].length && JSON.stringify(response[i].sort()) !== JSON.stringify(c.sort()));
      // if any incorrectly blank and no incorrect responses: show the generic message
      if (!outputHTML && anyIncorrectlyBlank && !anyIncorrect) {
        outputHTML = 'You have not filled in all the containers.';
      }
    }
    const qid = questionData.response_id;
    this.renderDistractor(qid, isCorrect, outputHTML);
  };

  validateOpenResponse = (questionMethods, l8yQuestionData, qIndex) => {
    const { metadata: { sample_answer }, response_id: qid } = l8yQuestionData;
    const { location } = this.props;
    if (location === AssessmentLocation.SPPTAT) {
      this.showOpenResponseAnswers(qid, sample_answer, true, qIndex);
    } else {
      if (!this.hideValidationUI) {
        if (questionMethods.isAttempted() || this.isPreview) {
          // if attempted, show the key below the OR box
          this.showOpenResponseAnswers(qid, sample_answer, false);
        }
      }
    }
    questionMethods.trigger('validated');
  };

  validateQuestionlessItem = async () => {
    const { isInstructor } = this.props;
    const now = Date.now();
    if ((now - this.lastValidation) > 1000) {
      const itemMaxScore = 1;
      const itemScore = 1;
      const attemptDataArray = [];
      const isCorrect = YesNo.Yes;
      // Don't do all the extra item validation stuff in Preview mode
      if (!this.isPreview) {
        await this.handleItemValidated({ itemScore, itemMaxScore, attemptDataArray, isCorrect });
        // save the session
        if (!this.isStudyPath && !isInstructor) {
          await this.itemsAPI.save();
        }
      }
    } else {
      console.warn('validate debounced', now, this.lastValidation);
    }
  };

  handleValidateAllQuestionsInItem = async (questions) => {
    const { isInstructor } = this.props;
    const { activeAssessmentQuestion } = this.state;
    const now = Date.now();
    if ((now - this.lastValidation) > 1000) {
      let itemMaxScore = 0;
      let itemScore = 0;
      const attemptDataArray = [];
      const isCorrectArray = [];
      if (activeAssessmentQuestion.gradingType === GradingTypeTag.Assessment) {
        for (const q in questions) {
          const qScore = questions[q].getScore();
          const { max_score } = qScore;
          let { score } = qScore;
          const response = questions[q].getResponse();
          if (questions[q].getQuestion().type === L8yQuestionType.EssayRichText) {
            if (questions[q].isAttempted()) {
              isCorrectArray.push(YesNo.Yes);
              if (!score) {
                // give them full points even if they deleted their answer before submitting
                score = max_score;
              }
            } else {
              isCorrectArray.push(YesNo.No);
            }
          } else {
            isCorrectArray.push(questions[q].isValid() ? YesNo.Yes : YesNo.No);
          }
          attemptDataArray.push(response);
          itemMaxScore += max_score;
          itemScore += score;
        }
      } else {
        // survey item: correct and full credit for attempting
        for (const q in questions) {
          const max_score = 1;
          const response = questions[q].getResponse();
          isCorrectArray.push(YesNo.Yes);
          attemptDataArray.push(response);
          itemMaxScore += max_score;
          itemScore += max_score;
        }
      }
      const isCorrect = isCorrectArray.some(c => [YesNo.No, null].includes(c)) ? YesNo.No : YesNo.Yes;
      // Don't do all the extra item validation stuff in Preview mode
      if (!this.isPreview) {
        await this.handleItemValidated({ itemScore, itemMaxScore, attemptDataArray, isCorrect });
        // save the session
        if (!this.isStudyPath && !isInstructor) {
          await this.itemsAPI.save();
        }
      }
    } else {
      console.warn('validate debounced', now, this.lastValidation);
    }
  };

  handleInputChanged = () => {
    const { attemptsHash, clarityHash, activeL8yRef } = this.state;
    const { attemptLimit } = this.props;
    const currentItemAttempts = attemptsHash[activeL8yRef] || 0;
    const attemptsRemaining = attemptLimit - currentItemAttempts;
    this.setState({ inputChanged: true });

    const itemsAPIGotItems = this.itemsAPI.getItems();
    const itemQuestions = itemsAPIGotItems[activeL8yRef].questions;
    const responseIds = itemQuestions.map(q => q.response_id);
    const allQuestionPartsAnswered = responseIds.every(id => this.itemsAPI.question(id).isAttempted());

    // Always allow show validate if attemptLimit is null
    if (attemptsRemaining > 0 || attemptLimit === null) {
      // enable validate if all parts of question have been answered and muddy clear selected or survey item
      if (allQuestionPartsAnswered && (this.surveyItemsHash[activeL8yRef] || !!clarityHash[activeL8yRef])) {
        this.setState({ enableValidate: true });
      }
    }
    this.transition(AssessmentTakerQuestionStage.ANSWER_CHANGED);
  };

  handleItemValidated = async ({ itemScore, itemMaxScore, attemptDataArray, isCorrect }) => {
    this.lastValidation = Date.now();
    const {
      assessmentType,
      attemptLimit,
      handleEvents,
      isAfterLate,
      questionData,
    } = this.props;
    const {
      activeL8yRef,
      attemptsHash,
      clarityHash,
      correctHash,
      everCorrectHash,
      latePointsDeductedHash,
      pointsHash,
      recapHash,
      vatFrozenHash,
    } = this.state;

    // if clarity is not set (when not mandated by the VAT), pass null to clarity
    const activeItemClarity = clarityHash[activeL8yRef] || null;
    const activeItemAttempts = attemptsHash[activeL8yRef] || 0;
    const activeItemLatePointsDeducted = latePointsDeductedHash[activeL8yRef] || 0;
    const activeItemPoints = pointsHash[activeL8yRef] || 0;
    const activeItemEverCorrect = everCorrectHash[activeL8yRef] || YesNo.No;

    const hwPTAndOutOfAttempts = [AssessTypeEnum.Homework, AssessTypeEnum.PracticeTest].includes(assessmentType) && (activeItemAttempts + 1) >= attemptLimit;
    const correctness = isCorrect === YesNo.Yes;
    const isVatFrozen = hwPTAndOutOfAttempts || correctness;
    if (this.isVat && isVatFrozen) {
      for (const active of this.activeQuestionMethods) {
        active.disable();
      }
    }
    const { assessmentQuestionId, assessmentQuestionPoints } = questionData.find((q) => q.l8yId === activeL8yRef);
    const attempt = await handleEvents({
      type: L8yContainerEvents.VALIDATED,
      data: {
        assessmentQuestionId,
        assessmentQuestionPoints,
        score: itemScore,
        rawMaxScore: itemMaxScore,
        attemptData: attemptDataArray,
        isCorrect,
        activeL8yRef,
        clarity: activeItemClarity,
        previousAttemptNum: activeItemAttempts,
        previousPointsEarned: activeItemPoints,
        previousLatePointsDeducted: activeItemLatePointsDeducted,
      },
    });
    const { gradedAdjustedPointsEarned, freePlay, vatFrozen, latePointsDeducted } = attempt;
    const updatedAttemptsHash = {
      ...attemptsHash,
      [activeL8yRef]: activeItemAttempts + 1,
    };

    const updatedVatFrozenHash = {
      ...vatFrozenHash,
      [activeL8yRef]: vatFrozen,
    };
    const updatedCorrectHash = {
      ...correctHash,
      [activeL8yRef]: isCorrect,
    };
    const updatedEverCorrectHash = {
      ...everCorrectHash,
      [activeL8yRef]: activeItemEverCorrect === YesNo.No && correctness ? YesNo.Yes : activeItemEverCorrect,
    };
    const updatedLatePointsDeductedHash = {
      ...latePointsDeductedHash,
      [activeL8yRef]: latePointsDeducted,
    };
    const updatedPointsHash = {
      ...pointsHash,
      [activeL8yRef]: gradedAdjustedPointsEarned,
    };
    const currentQuestionCanRecap = freePlay === YesNo.No;
    const updatedRecapHash = {
      ...recapHash,
      [activeL8yRef]: currentQuestionCanRecap,
    };
    this.setState({
      attemptsHash: updatedAttemptsHash,
      correctHash: updatedCorrectHash,
      enableValidate: false,
      everCorrectHash: updatedEverCorrectHash,
      latePointsDeductedHash: updatedLatePointsDeductedHash,
      pointsHash: updatedPointsHash,
      // don't update recap icons if after late
      recapHash: !isAfterLate ? updatedRecapHash : {},
      vatFrozenHash: updatedVatFrozenHash,
    });
    const nextStage = isCorrect === YesNo.Yes ? AssessmentTakerQuestionStage.VALIDATED_CORRECT : AssessmentTakerQuestionStage.VALIDATED_INCORRECT;
    this.transition(nextStage);
  };

  handleItemChanged = async (itemIndex) => {
    const { handleEvents, questionData, assessmentType, location } = this.props;
    const { clarityHash, l8yReady } = this.state;
    const activeL8yRef = this.l8yItemList[itemIndex];
    console.debug('handleItemChanged', activeL8yRef, itemIndex, this.l8yItemList);
    const activeItemClarity = clarityHash[activeL8yRef];
    const itemsAPIGotItems = this.itemsAPI.getItems();
    const itemQuestions = itemsAPIGotItems[activeL8yRef].questions;
    const activeAssessmentQuestion = questionData.find((q) => q.l8yId === activeL8yRef);
    this.setState({
      itemQuestions,
      activeAssessmentQuestion,
    });
    const questionIds = itemQuestions.map(q => q.response_id);
    // Had to re-implement this because the way of retrieving activeItemId wasn't working, see above comment ~L115
    // TODO: Revisit the whole question array issue, there's probably another way to get activeItemId
    const itemsAPIQuestions = this.itemsAPI.questions();
    const questionIdsOG = Object.keys(itemsAPIQuestions);
    const activeItemId = questionIdsOG[itemIndex];

    // don't send ITEM_CHANGED before l8y is ready, this might break some things
    if (l8yReady) {
      await handleEvents({
        type: L8yContainerEvents.ITEM_CHANGED,
        data: {
          itemIndex,
          activeL8yRef,
        },
      });
    }

    await handleEvents({ type: L8yContainerEvents.QUESTIONS_LOADED, data: { questionIds } });

    if (location === AssessmentLocation.SPPTAT) {
      itemQuestions.forEach((cur, qIndex) => {
        if (cur.type === L8yQuestionType.EssayRichText) {
          const { response_id, metadata: { sample_answer } } = cur;
          this.showOpenResponseAnswers(response_id, sample_answer, true, qIndex);
        }
      });
    }

    const isSurveyItem = this.surveyItemsHash[activeL8yRef];
    let enableValidate = questionIds.every(id => this.itemsAPI.question(id).isAttempted());
    if (([AssessTypeEnum.Homework, AssessTypeEnum.Preclass, AssessTypeEnum.Readiness, AssessTypeEnum.PracticeTest].includes(assessmentType) || !questionIds.length)
      && activeItemClarity === undefined && !isSurveyItem) {
      // don't enable validate if clarity not selected unless it's a survey item
      enableValidate = false;
    }

    this.setState({
      activeItemId,
      activeItemIndex: itemIndex,
      activeL8yRef,
      enableValidate,
      inputChanged: false,
      questionIds,
    });
    this.transition(AssessmentTakerQuestionStage.INIT);
  };

  handleFinished = async () => {
    const { handleEvents } = this.props;
    const { clarityHash, attemptsHash, correctHash, everCorrectHash, latePointsDeductedHash, pointsHash, vatFrozenHash } = this.state;
    await handleEvents({ type: L8yContainerEvents.HANDLE_FINISHED, data: { clarityHash, attemptsHash, correctHash, everCorrectHash, latePointsDeductedHash, pointsHash, vatFrozenHash } });
  };

  handleReady = () => {
    this.itemsObject = this.itemsAPI.items();
    this.itemsFromL8y = this.itemsAPI.getItems();
    const { items, targetL8yId } = this.props;
    const newItemHasAllORQsHash = items.reduce((acc, { l8yId }) => {
      const itemFromL8y = this.itemsFromL8y[l8yId];
      if (!itemFromL8y) {
        console.error(`l8yId ${l8yId} not found in itemsFromL8y`, this.itemsFromL8y);
      }
      const allORQs = !itemFromL8y.questions.some(({ type }) => type !== L8yQuestionType.EssayRichText);
      return {
        ...acc,
        [l8yId]: allORQs,
      };
    }, {});
    this.setState({
      l8yReady: true,
      itemHasAllORQsHash: newItemHasAllORQsHash,
    });

    if (!!targetL8yId) {
      this.itemsObject.goto(targetL8yId);
    }
  };

  handleLoad = async () => {
    const {
      activityId,
      assessmentMode,
      assessmentType,
      handleEvents,
      initState,
      isInstructor,
      items,
      l8ySessionId,
      location,
      name,
      renderingType,
      userId,
    } = this.props;
    const data = await apiNext.createSignedLearnosityRequest({
      activityId: isInstructor ? 'instructor-view' : activityId,
      assessmentMode,
      assessmentType,
      initState,
      items,
      l8ySessionId,
      location,
      name,
      renderingType,
      userId,
    });
    console.debug('getSignedLearnosityRequest data', data);
    this.l8yItems = await window.LearnosityItems;
    this.itemsAPI = await this.l8yItems.init(data, 'testregion', {
      errorListener: this.props.onL8yError,
      readyListener: this.handleReady,
      customUnload: () => {
        console.debug('beforeunload event has been triggered');
        return false;
      },
    });
    this.itemsAPI.on(L8yEvents.ITEM_LOAD, this.handleItemLoad);
    this.itemsAPI.on(L8yEvents.ITEM_CHANGED, this.handleItemChanged);
    this.itemsAPI.on(L8yEvents.TEST_FINISHED_SUBMIT, this.handleFinished);

    this.itemsAPI.on(L8yEvents.ITEM_GOTO, (e) => handleEvents({ type: L8yContainerEvents.ITEM_GOTO, data: e }));
    this.itemsAPI.on(L8yEvents.ITEM_UNLOAD, (e) => handleEvents({ type: L8yContainerEvents.ITEM_UNLOAD, data: e }));
    this.itemsAPI.on(L8yEvents.ITEM_SETATTEMPTEDRESPONSE, (e) => console.debug('item:setAttemptedResponse', e));
  };

  triggerValidate = async (itemIndex) => {
    if (itemIndex > -1) {
      if (!this.activeQuestionMethods.length) {
        await this.validateQuestionlessItem();
      } else {
        const { activeAssessmentQuestion } = this.state;
        if (activeAssessmentQuestion.gradingType === GradingTypeTag.Assessment) {
          this.activeQuestionMethods.forEach((active, qIndex) => {
            const ques = active.getQuestion();
            if (ques.type === L8yQuestionType.EssayRichText) {
              this.validateOpenResponse(active, ques, qIndex);
            } else {
              const validateOpts = {
                showCorrectAnswers: this.isPreview,
              };
              if ((ques.type === L8yQuestionType.MultipleChoiceMultipleSelect && !ques.multiple_responses)
                || [L8yQuestionType.ClozeDropDown, L8yQuestionType.LabelImageDragAndDrop].includes(ques.type)
              ) {
                // don't let L8y show the feedback
                validateOpts.showDistractorRationale = 'never';
              }
              active.validate(validateOpts);
            }
          });
        } else {
          // survey item
          this.activeQuestionMethods.forEach((active) => {
            active.trigger('validated');
          });
        }
      }
    }
  };

  handleClarity = (clarity, l8yRef) => {
    const { handleEvents } = this.props;
    const { clarityHash, questionIds } = this.state;
    const newClarityHash = {
      ...clarityHash,
      [l8yRef]: clarity,
    };
    // update parent clarityHash on clarity toggle
    handleEvents({ type: L8yContainerEvents.HANDLE_CLARITY, data: { clarityHash: newClarityHash } });
    // When clarity is set only enable validate if all parts of the question have been attempted
    const questions = questionIds.reduce((acc, q) => {
      return {
        ...acc,
        [q]: this.itemsAPI.question(q),
      };
    }, {});

    this.setState({
      enableValidate: questionIds.every(id => questions[id].isAttempted()),
      clarityHash: newClarityHash,
    });
    this.transition(AssessmentTakerQuestionStage.CLARITY_SELECTED);
  };

  handleActionBarNav = (navDirection) => {
    if (navDirection === DirectionEnum.Next) {
      this.itemsObject.next();
    } else if (navDirection === DirectionEnum.Prev) {
      this.itemsObject.previous();
    }
  };

  handleItemNav = (l8yRef) => {
    this.setState({ activeL8yRef: l8yRef });
  };
  render() {
    const {
      activeAssessmentQuestion,
      activeItemId,
      activeItemIndex,
      activeL8yRef,
      attemptsHash,
      clarityHash,
      correctHash,
      currentStage,
      enableValidate,
      everCorrectHash,
      itemHasAllORQsHash,
      l8yReady,
      pointsHash,
      questionIds,
      recapHash,
      vatFrozenHash,
    } = this.state;
    const {
      assessmentType,
      attemptLimit,
      attemptPolicy,
      courseId,
      enableClarity,
      inReviewMode,
      isAfterLate,
      items,
      l8yBoxClassName = 'col-xs-12',
      location,
      renderItemNav,
      todoQuestionL8yIds,
    } = this.props;

    const showRexBanner = assessmentType === AssessTypeEnum.Readiness && location === AssessmentLocation.REX && attemptPolicy !== MultipleAttemptPolicyEnum.NotForPoints && isAfterLate;
    const showItemNav = typeof renderItemNav === 'function';
    const showRecap = assessmentType !== AssessTypeEnum.Readiness && recapHash[activeL8yRef] === true;
    // Get hash of l8yIds with clarity and isCorrect data for displaying nav menu icons
    const questionStatusHash = items.reduce((acc, { l8yId }) => {
      const isCorrect = correctHash[l8yId];
      const allORQs = itemHasAllORQsHash[l8yId];
      const clarity = clarityHash[l8yId];
      const canRecap = recapHash[l8yId];
      const isSurveyItem = this.surveyItemsHash[l8yId];
      return {
        ...acc,
        [l8yId]: {
          isCorrect,
          allORQs,
          clarity,
          canRecap,
          isSurveyItem,
        },
      };
    }, {});

    const showPointsOverlay = !this.isPreview && l8yReady && !(assessmentType === AssessTypeEnum.PracticeTest && !inReviewMode) && location !== AssessmentLocation.SPPTAT;
    const currentPoints = pointsHash && pointsHash[activeL8yRef] ? formatPoints(pointsHash[activeL8yRef]).replace(/\.00$/, '') : 0;
    const currentPointsAvailable = activeAssessmentQuestion && activeAssessmentQuestion.assessmentQuestionPoints ? activeAssessmentQuestion.assessmentQuestionPoints : 0;
    const vatFrozen = this.isVat && vatFrozenHash[activeL8yRef] === YesNo.Yes;
    const allQuestionsFrozenOrAttempted = !this.l8yItemList.some(item => vatFrozenHash[item] === YesNo.No && attemptsHash[item] === undefined);
    const attribution = !this.isPreview && activeAssessmentQuestion?.type === LibraryTypeEnum.User ? 'This question was created or adapted by your instructor.' : false;

    const { showFinish, enableFinish } = determineFinishButtonState(this.isStudyPath, {
      activeL8yRef,
      questionStatusHash,
      todoQuestionL8yIds,
      allQuestionsFrozenOrAttempted,
    });

    // TODO CA-1155: Link to correct SP for current question
    const studyPathLink = `/student/course/${courseId}/${StudentCoursePath.StudyPath}`;

    const questionItems = questionIds ? questionIds.length : 0;

    const currentQuestionIsCorrect = !!activeAssessmentQuestion ? correctHash[activeAssessmentQuestion.l8yId] : undefined;
    const currentQuestionEverCorrect = !!activeAssessmentQuestion ? everCorrectHash[activeAssessmentQuestion.l8yId] : YesNo.No;

    const currentItemContainsOnlyOR = !!activeAssessmentQuestion ? questionStatusHash[activeAssessmentQuestion.l8yId].allORQs : undefined;
    const currentItemIsSurveyItem = !!activeAssessmentQuestion ? questionStatusHash[activeAssessmentQuestion.l8yId].isSurveyItem : undefined;
    const currentItemIsAttempted = currentItemContainsOnlyOR || currentItemIsSurveyItem;

    if (l8yReady && this.startTime > 0) {
      logExecutionTime(this.startTime, 'LearnosityContainer render, l8yReady=true');
      this.startTime = 0; //reset the startTime to avoid logging multiple times
    }

    return (
      <LearnosityWrap
        id="learnosity-wrap"
        questionStatusHash={questionStatusHash}
        assessmentType={assessmentType}
        inReviewMode={inReviewMode}
        questionItems={questionItems > 1 ? `This item has ${questionItems} questions.` : null}
        pointsString={showPointsOverlay ? `${currentPoints}/${currentPointsAvailable} ${formatPlural('point', currentPointsAvailable)} earned` : null}
        attribution={attribution}
      >
        <ScriptTag src="https://items.learnosity.com/?v2023.2.LTS" async onLoad={() => this.handleLoad()} />
        <div className="learnosity-item__wrap" data-ready={l8yReady} data-assesstype={assessmentType} data-location={location}>
          {(vatFrozen || showRexBanner) && (
            <div className="vat-study-path-hint-banner">
              {renderFrozenVatMessage({ assessType: assessmentType, attemptPolicy, currentQuestionIsCorrect, currentQuestionEverCorrect, isAfterLate, studyPathLink, currentItemIsAttempted })}
            </div>
          )}
          <div className="row reverse learnosity-row">
            {showItemNav && l8yReady && renderItemNav(clarityHash, activeL8yRef, this.handleItemNav, questionStatusHash)}
            <div className={l8yBoxClassName}>
              <div className="learnosity-box">
                {/* This is the div where learnosity is injected */}
                <div className="learnosity-item" data-reference="testregion" id="testregion">
                  {!l8yReady && <LoadingSpinner />}
                </div>
              </div>
            </div>
          </div>
          {l8yReady && (
            <AssessmentTakerActionBar
              activeL8yRef={activeL8yRef}
              assessmentType={assessmentType}
              attemptLimit={attemptLimit}
              attemptsHash={attemptsHash}
              clarityHash={clarityHash}
              currentStage={currentStage}
              enableClarity={enableClarity}
              enableFinish={enableFinish}
              enableValidate={enableValidate}
              handleClarity={(clarity, l8yRef) => this.handleClarity(clarity, l8yRef)}
              handleFinish={() => this.handleFinished()}
              handleNav={(direction) => this.handleActionBarNav(direction)}
              handleValidate={() => this.triggerValidate(activeItemIndex)}
              itemHasQuestions={!!questionIds.length}
              l8yRefArray={this.l8yItemList}
              location={location}
              isStudyPath={this.isStudyPath}
              showRecap={showRecap}
              showFinish={showFinish}
              surveyItemsHash={this.surveyItemsHash}
              vatFrozen={vatFrozen}
            />
          )}
          {/* This hidden div is used so Cypress knows how to find the active question div */}
          <span className="activeItemId" style={{ visibility: 'hidden' }}>{activeItemId}</span>
        </div>
      </LearnosityWrap>
    );
  }
}
