import { StudyPathEnrichedQuestion } from '../student/controllers/Course/StudyPathController/StudyPathController.types';

function removeQuestionDuplicates(questions: Array<StudyPathEnrichedQuestion>) {
  return questions.reduce((acc: Array<StudyPathEnrichedQuestion>, cur: StudyPathEnrichedQuestion) => {
    const duplicateIndex = acc.findIndex((q) => q.id === cur.id);
    if (duplicateIndex === -1) {
      return [
        ...acc,
        cur,
      ];
    }
    const existingQuestion = acc[duplicateIndex];
    const loString = existingQuestion.loNumberStrings.join(' | ');

    acc[duplicateIndex] = {
      ...existingQuestion,
      loNumber: loString,
    };
    return acc;
  }, []);
}

export function dedupeAndSort(questions: Array<StudyPathEnrichedQuestion>) {
  const deduped = removeQuestionDuplicates(questions);
  return deduped.sort((a, b) => a.questionNumber - b.questionNumber);
}

export default removeQuestionDuplicates;
