import { CheckpointColumn, LibraryTypeEnum } from 'types/backend/shared.types';
import { TopicApi } from 'types/backend/topics.types';
import {
  StudentStudyPathApi,
  StudentTopicCardForStudyPath,
  StudentTopicCardLearningObjective,
  StudentTopicCardLearningObjectiveAssessmentQuestion,
} from 'types/backend/studentStudyPaths.types';
import { StudentAssessmentQuestionAttemptApi } from 'types/backend/studentAssessmentQuestionAttempts.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { GradingTypeTag } from 'types/backend/l8y.types';


export interface EnrichedStudentTopicCard extends StudentTopicCardForStudyPath {
  topic: TopicApi
  learningObjectives: Array<EnrichedStudentTopicCardLearningObjective>
}

export interface EnrichedStudentStudyPath extends StudentStudyPathApi {
  studentTopicCardCheckpoints: EnrichedStudentTopicCardCheckpoints
  summaryInfo: StudyPathSummaryInfo
}

export interface EnrichedStudentTopicCardCheckpoints {
  [CheckpointColumn.Hidden]: Array<EnrichedStudentTopicCard>
  [CheckpointColumn.Review]: Array<EnrichedStudentTopicCard>
  [CheckpointColumn.Prep]: Array<EnrichedStudentTopicCard>
  [CheckpointColumn.Test]: Array<EnrichedStudentTopicCard>
}

export interface EnrichedStudentTopicCardLearningObjective extends StudentTopicCardLearningObjective {
  title: string
  courseWideSort: number
  stringId: string
  loNumber: string
}
export interface EnrichedStudentTopicCardLearningObjectiveWithTopicData extends EnrichedStudentTopicCardLearningObjective {
  topicData: {
    name: string
  }
}

export interface EnrichedLearningObjective extends LearningObjectiveApi {
  title: string
  courseWideSort: number
  loNumber: string
  assessmentQuestions: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>
}

export interface StudyPathSummaryInfo {
  checkpointZeroHasTopics: boolean
  checkpointOneHasTopics: boolean
  checkpointTwoHasTopics: boolean
  checkpointThreeHasTopics: boolean
  allAssessmentsCompleted: boolean
  availableLOs: number
  totalLOs: number
  questionsForRecapture: number
  reviewedTopics: number
  testMeTopics: number
  totalTopics: number
  totalPrepQuestions: number
  correctPrepQuestions: number
  muddyCount: number
}

export interface HandleCardActionData {
  studentTopicCardId: number
  topicCardId: number
  checkpoint: CheckpointColumn
}

export interface StudyPathEnrichedQuestion extends StudentTopicCardLearningObjectiveAssessmentQuestion {
  assessmentNumber: number
  studentAssessment?: StudentAssessmentApi
  isStudyPathEligibleAssessment: boolean
  questionNumber: number
  loNumber: string
  loNumberStrings: Array<string>
}

export enum EnrichedStudentTopicCardCheckpointStateEnum {
  ActiveWithQuestions = 'active-with-questions',
  ActiveWithoutQuestions = 'active-without-questions',
  Complete = 'complete',
  NotStarted = 'not-started',
}

export enum StudyPathLaunch {
  PracticeTestReview = 'ptreview',
}

export interface QuestionDatumForSPATL8y {
  id: number
  l8yId: string
  assessmentQuestionId: number
  assessmentQuestionPoints: number
  studentAssessmentId: number
  latestVatStudentAssessmentQuestionAttempt?: StudentAssessmentQuestionAttemptApi | null
  type: LibraryTypeEnum
  gradingType: GradingTypeTag
}

export enum StudentPTState {
  PTReadyUnpublished = 'pt-ready-unpublished', // the student could take the PT if it was published
  PTPastDueUnpublished = 'pt-past-due-unpublished',
  PTCompletedOrPastDue = 'pt-completed-or-past-due',
  PTInProgress = 'pt-in-progress',
  PTAvailable = 'pt-available',
  CardsNotMoved = 'cards-not-moved',
  AssignmentsNotCompleted = 'assignments-not-completed'
}

export enum StudentAssessmentStartedStatus {
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  Completed = 'completed'
}
