import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import calculatePointsForRecap from './shared/calculatePointsForRecap';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import OutroContent from './shared/OutroContent';
import AssessmentContainer from './shared/AssessmentContainer';
import IntroOutro from './shared/IntroOutro';
import Instructions from './shared/Instructions';

import { AssessmentTakerPropTypes, AssessmentTakerProps } from './shared/AssessmentTakerProps';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { Store } from 'types/store.types';
import { Steps } from './AssessmentTaker.types';
import './Homework.scss';


function AssessmentTakerHomework(props: AssessmentTakerProps) {
  const {
    assessmentData,
    attemptPolicy,
    clarityHash,
    correctQuestionCount,
    currentStep,
    dueString,
    earnedPoints,
    enrollmentData,
    handleStartSession,
    l8ySessionId,
    onExit,
    questions,
    studentAssessmentId,
    totalLatePointsDeducted,
    totalPoints,
    totalQuestions,
    handleReviewAssessment,
  } = props;
  const { mergedOpenDate, mergedDueDate } = assessmentData;
  const openString = DateTime.fromISO(mergedOpenDate).toFormat(DateFormatEnum.WeekdayDateAtTime);
  const isInstructor = useSelector((store: Store) => !!store.state.instructorStudentViewCourseId);
  const [recapPoints, setRecapPoints] = useState(0);

  useEffect(() => {
    if (currentStep === Steps.Outro) {
      (async () => {
        const points = await calculatePointsForRecap(mergedDueDate, studentAssessmentId);
        setRecapPoints(points);
      })();
    }
  }, [currentStep, studentAssessmentId, mergedDueDate]);

  switch (currentStep) {
    case Steps.Intro: {
      return (
        <IntroOutro>
          <>
            <Instructions assessmentData={assessmentData} attemptPolicy={attemptPolicy} />
            <div className="assessment__action">
              {DateTime.fromISO(mergedOpenDate) < DateTime.local() || isInstructor
                ? <BetterButton className="gray-button" text="Start" onClick={handleStartSession} />
                : `This assessment will open on ${openString}.`
              }
            </div>
          </>
        </IntroOutro>
      );
    }
    case Steps.Assessment: {
      if (studentAssessmentId === -1 && !isInstructor) {
        console.error('Cannot render Assessment without valid studentAssessmentId', studentAssessmentId);
        return null;
      }
      return (
        <AssessmentContainer
          {...props}
          assessmentType={AssessTypeEnum.Homework}
          l8ySessionId={l8ySessionId}
          studentAssessmentId={studentAssessmentId}
          handleExit={onExit}
          questions={questions}
          enableClarity
          inReviewMode={false}
        />
      );
    }
    case Steps.Outro: {
      return (
        <OutroContent
          assessmentControllerQuestions={questions}
          assessType={AssessTypeEnum.Homework}
          clarityHash={clarityHash}
          correctQuestionCount={correctQuestionCount}
          courseId={enrollmentData.courseId}
          dueString={dueString}
          earnedPoints={earnedPoints}
          handleReviewAssessment={handleReviewAssessment}
          isInstructor={isInstructor}
          recapPoints={recapPoints}
          totalLatePointsDeducted={totalLatePointsDeducted}
          totalPoints={totalPoints}
          totalQuestions={totalQuestions}
        />
      );
    }
    default: return null;
  }
}

AssessmentTakerHomework.propTypes = AssessmentTakerPropTypes;

export default AssessmentTakerHomework;
