import apiNext from 'api-next';
import { isInThePast } from 'utils/dateFormattingFunctions';

const calculatePointsForClarity = async function(assessmentDueDate: string, studentAssessmentId: number) {
  if (isInThePast(assessmentDueDate)) {
    return 0;
  }
  const studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions([studentAssessmentId], true);
  return studentAssessmentQuestions.reduce((acc, saq) => {
    acc += saq.pointsAvailableToRecap || 0;
    return acc;
  }, 0);
};

export default calculatePointsForClarity;
