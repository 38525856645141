/* eslint-disable jsx-a11y/no-autofocus */
// JUSTIFICATION: autoFocus is required for making ConfirmationPrompt play nice with keyboards
// this represents one of the valid uses of autoFocus
// it may be possible in future to handle this with forwardRef/createRef https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forward_and_create_ref/
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './BetterButton.scss';

const BetterButton = ({
  autoFocus = false,
  onClick,
  linkTo,
  text,
  icon,
  showText,
  className,
  primary,
  secondary,
  selected,
  disabled = false,
  type = 'button',
  ...props
}: {
  autoFocus?: boolean
  linkTo?: string
  text: string
  className?: string
  icon?: () => ReactNode
  showText?: boolean
  primary?: boolean
  secondary?: boolean
  selected?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const history = useHistory();
  const classNameArray = [
    primary && 'primary',
    secondary && 'secondary',
    selected && 'selected',
    !!icon && 'has-icon',
  ].filter(Boolean);
  const classNameString = `button ${classNameArray.join(' ')} ${className}`;
  // use history.push if linkTo is provided
  if (linkTo) {
    return (
      <button
        autoFocus={autoFocus}
        type="button"
        className={classNameString}
        aria-label={text}
        disabled={disabled === true}
        onClick={() => history.push(linkTo)}
        {...props}
      >
        { icon && icon() }
        { showText && text }
      </button>
    );
  } else {
    // this if block is needed to make typescript happy
    if (!onClick) {
      return null;
    }
    // otherwise use default onclick event
    return (
      <button
        autoFocus={autoFocus}
        className={classNameString}
        aria-label={text}
        disabled={disabled === true}
        onClick={onClick}
        type={type}
        {...props}
      >
        { icon && <div className={`icon-wrap ${!showText ? 'no-text' : ''}`}>{icon()}</div> }
        { showText && text }
      </button>
    );
  }
};

BetterButton.propTypes = {
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.func,
  showText: PropTypes.bool,
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

BetterButton.defaultProps = {
  onClick: () => {},
  showText: true,
  className: '',
  primary: false,
  selected: false,
  disabled: false,
  type: 'button',
};

export default BetterButton;
