/***
 * retrieveEnrichedActiveStudyPath
 * Hydrate study path object with data
*/
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';

import retrieveEnrichedCourseTopics from './retrieveEnrichedCourseTopics';
import retrieveActiveCourseLearningObjectives from './retrieveActiveCourseLearningObjectives';
import retrieveEnrichedStudentAssessments from 'store/selectors/retrieveEnrichedStudentAssessments';
import enrichStudentStudyPath from 'utils/enrichStudentStudyPath';
import { StudentStudyPathApi } from 'types/backend/studentStudyPaths.types';
import { Store } from 'types/store.types';

export default createSelector(
  (store: Store) => store.active.studentStudyPath,
  (store: Store) => store.active.studyPath,
  (store: Store) => store.active.classSessions,
  retrieveEnrichedStudentAssessments,
  retrieveEnrichedCourseTopics,
  retrieveActiveCourseLearningObjectives,
  (
    studentStudyPath,
    studyPath,
    classSessions,
    enrichedAssessmentsForStudent,
    courseTopics,
    courseLearningObjectives
  ) => {
    if (Object.entries(studentStudyPath).length === 0) {
      console.debug('studentStudyPath is empty', studentStudyPath);
      return null;
    } else {
      // this selector will break if studentTopicCardCheckpoints isn't present so I added this condition
      // if the study path object exists but hasn't been hydrated with the checkpoints
      if (!studentStudyPath.studentTopicCardCheckpoints) {
        console.warn('retrieveEnrichedActiveStudyPath called without studentTopicCardCheckpoints', studentStudyPath);
        return null;
      }
    }
    studentStudyPath = cloneDeep(studentStudyPath);
    return enrichStudentStudyPath(studentStudyPath as Required<StudentStudyPathApi>, studyPath, courseTopics, classSessions, courseLearningObjectives, enrichedAssessmentsForStudent);
  });
