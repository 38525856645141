import React, { useCallback, useEffect, useMemo } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';

import apiNext from 'api-next';
import { getMostRecentActiveSummative } from 'utils/getMostRecentActiveSummative';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useEffectOnce from 'hooks/useEffectOnce';
import { useAppDispatch, useAppSelector } from 'store';
import activeSlice from 'store/slices/active';
import createStudentStudyPath from 'store/actions/createStudentStudyPath';
import retrieveEnrichedActiveStudyPath from 'store/selectors/retrieveEnrichedActiveStudyPath';
import retrieveEnrichedStudentAssessments, { EnrichedStudentSummativeAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import StudyPathContainer from './components/StudyPathContainer/StudyPathContainer';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import { HandleCardActionData } from './StudyPathController.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { RoleEnum } from 'types/backend/roles.types';
import { StudentTopicCardAction, StudentTopicCardSource } from 'types/backend/studentTopicCards.types';

export default function StudyPathController() {
  useDocumentTitle('Study Path');
  const instructorStudentViewCourse = useAppSelector((store) => store.state.instructorStudentViewCourseId);
  const dispatch = useAppDispatch();
  const enrichedAssessments = useAppSelector(retrieveEnrichedStudentAssessments);
  const enrollment = useAppSelector((store) => store.active.enrollment);
  const summativeAssessmentSupplements = useAppSelector((store) => store.active.summativeAssessmentSupplements);
  const studentStudyPath = useAppSelector((store) => store.active.studentStudyPath);
  // this is where the currently loaded enriched student study path state flows from
  const selectedEnrichedStudentStudyPath = useAppSelector(retrieveEnrichedActiveStudyPath);
  // 1. get the chronologically most recent summative
  const mostRecentSummative = useMemo(() => {
    const currentlyInSummativeWindow = getMostRecentActiveSummative(enrichedAssessments, summativeAssessmentSupplements);
    if (!currentlyInSummativeWindow) {
      // no future Practice test / no current summative. Get the last available one
      const onlySummatives = enrichedAssessments.filter(({ assessType }) => assessType === AssessTypeEnum.Summative) as Array<EnrichedStudentSummativeAssessment>;
      const lastSummative = [...onlySummatives].pop();
      console.debug('Only past summatives exist, get last one', lastSummative);
      if (!lastSummative?.id) {
        console.error('enrichedAssessments does not contain a valid final summative', enrichedAssessments);
        return null;
      }
      return lastSummative;
    }
    return currentlyInSummativeWindow;
  }, [enrichedAssessments, summativeAssessmentSupplements]);
  // 2. get summativeAssessmentIdFromQuery, this wins over most recent
  const [summativeAssessmentIdFromQuery, setSelectedSummativeIdQuery] = useQueryParam('summativeAssessmentId', StringParam);
  const [launchInto] = useQueryParam('launchInto', StringParam);
  // 3. use summativeAssessmentIdFromQuery over mostRecentSummative?.id, this should always be defined, unless there are no study paths at all
  const selectedSummativeId = summativeAssessmentIdFromQuery || mostRecentSummative?.id;
  console.assert(selectedSummativeId, 'No selectedSummativeId found');

  const { id: enrollmentId, roleId } = enrollment;
  const isStudent = roleId === RoleEnum.Student;

  // fetch study path from the server
  const loadStudyPath = useCallback(async (summativeId) => {
    if (!!instructorStudentViewCourse || !enrollmentId) {
      console.debug(`Student Goggles active for course ${instructorStudentViewCourse}, do not load Study Path`);
      return null;
    }
    console.debug('loadStudyPath for summativeId:', summativeId);
    // 6. get SP based on summativeId
    return apiNext.getStudyPathBySummativeAssessmentId(summativeId).then(([studyPathDataFromSummative]) => {
      const { id: studyPathId } = studyPathDataFromSummative;
      if (!studyPathId) {
        // this shouldn't happen but plan for it anyway for typesafety
        console.error(`Oops: studyPathId not found for summativeAssessmentId ${summativeId}`);
        return null;
      }
      dispatch(activeSlice.actions.setStudyPath(studyPathDataFromSummative));
      // 7. get StudentSP based on summativeId SP data
      return apiNext.getStudentStudyPathByStudyPathIdAndEnrollment(studyPathId, enrollmentId).then(([studentStudyPathData]) => {
        if (!!studentStudyPathData) {
          console.debug('Existing SSP data loaded', studentStudyPathData);
          // 8a. if SSP exists, update studentStudyPath redux state
          return dispatch(activeSlice.actions.setStudentStudyPath(studentStudyPathData));
        }
        console.debug('SSP not found, createStudentStudyPath', studyPathId, enrollmentId);
        // 8b. if SSP has not been created yet, trigger create action, which also sets studentStudyPath redux
        // which then updates selectedEnrichedStudentStudyPath redux, which brings us back to doe
        return dispatch(createStudentStudyPath(studyPathId, enrollmentId));
      });
    });
  }, [dispatch, enrollmentId, instructorStudentViewCourse]);

  useEffect(() => {
    // 4. should do load SP fetch because no studentStudyPath data has loaded yet
    const noSspLoadedShouldLoadFromQuery = !studentStudyPath?.studyPathId && !!selectedSummativeId;
    noSspLoadedShouldLoadFromQuery && console.debug('loadStudyPath because no ssp and has query', selectedSummativeId);
    // 5. studentStudyPath data is loaded but summativeAssessmentId query is different from what is loaded into state
    const sspDoesNotMatchQuery = !!studentStudyPath && !!selectedSummativeId && studentStudyPath.summativeAssessmentId !== selectedSummativeId;
    sspDoesNotMatchQuery && console.debug('loadStudyPath because ssp exists but doesn\'t match query', studentStudyPath.summativeAssessmentId, selectedSummativeId);
    if (noSspLoadedShouldLoadFromQuery || sspDoesNotMatchQuery) {
      loadStudyPath(selectedSummativeId);
    }
  }, [dispatch, selectedSummativeId, enrollmentId, studentStudyPath, loadStudyPath]);

  // if launch, always get fresh data
  useEffectOnce(() => {
    if (!!launchInto) {
      console.debug('launchInto is present, get fresh SP data', selectedSummativeId);
      loadStudyPath(selectedSummativeId);
    }
  });
  // when summativeId changes in the dropdown we need to update the student study path
  // updating the selectedSummativeIdQuery to trigger the above useEffect and do a fresh pull with the updated summative data
  const handleSummativeIdChange = async (summativeId: string) => {
    if (summativeId !== selectedSummativeId) {
      setSelectedSummativeIdQuery(summativeId);
    }
  };

  const handleCardSkip = async (data: HandleCardActionData) => {
    const { studentTopicCardId } = data;
    const cardActionResult = await apiNext.editStudentTopicCard({
      id: studentTopicCardId,
      source: StudentTopicCardSource.User,
      action: StudentTopicCardAction.Skipped,
    });
    loadStudyPath(selectedSummativeId);
    console.debug('handleCardSkip cardActionResult', cardActionResult);
    return cardActionResult;
  };

  const onModalClose = async () => {
    console.debug(`StudyPathController onModalClose, reload ${selectedSummativeId}`);
    loadStudyPath(selectedSummativeId);
  };

  if (!isStudent) {
    return (
      <>
        <div className="study-path__summary-bar row">
          <div className="study-path__summary-bar-instructor-view">
            <h2>The Study Path doesn't load for instructors previewing the student experience. To try it for yourself, head to My Courses and open a Demo Course.</h2>
          </div>
        </div>
        <div className="study-path__summary-info-graphic">
          <img src="/assets/study-path-info-graphic.png" alt="Study Path Info Graphic" />
        </div>
      </>
    );
  }

  if (!!summativeAssessmentSupplements.length && (!selectedEnrichedStudentStudyPath || selectedEnrichedStudentStudyPath.summativeAssessmentId !== selectedSummativeId)) {
    console.debug(`has summativeAssessmentSupplements but selectedEnrichedStudentStudyPath does not exist or does not match selectedSummativeId ${selectedSummativeId}`, selectedEnrichedStudentStudyPath);
    return (
      <LoadingSpinner loadingMessage="Study Path Loading" />
    );
  }

  return (
    <StudyPathContainer
      enrichedStudyPath={selectedEnrichedStudentStudyPath}
      handleCardSkip={handleCardSkip}
      onModalClose={onModalClose}
      activeSummative={mostRecentSummative}
      selectedSummative={enrichedAssessments.find(a => a.id === selectedSummativeId) as EnrichedStudentSummativeAssessment}
      handleSummativeIdChange={handleSummativeIdChange}
    />
  );
}
