import PropTypes from 'prop-types';
import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { AssessTypeEnum, GradingPolicyEnum } from 'types/backend/assessments.types';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import {
  AttemptsHash,
  ClarityHash,
  CorrectHash,
  MultipleAttemptPolicyEnum,
  PointsHash,
  RecapHash,
  VatFrozenHash,
} from 'types/common.types';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import { VatHashes } from 'utils/getVatHashesFromSaqa';

export interface AssessmentContainerExitPayload extends VatHashes {
  correctQuestionsArray: Array<StudentAssessmentQuestionApiWithSaqas>
}

export interface AssessmentContainerProps {
  assessmentData: AssessmentWithEnrollment
  assessmentType: AssessTypeEnum
  attemptPolicy: MultipleAttemptPolicyEnum
  attemptsHash: AttemptsHash
  clarityHash: ClarityHash
  correctHash: CorrectHash
  courseId: string
  enableClarity?: boolean
  everCorrectHash: CorrectHash
  handleExit: (exitPayload: AssessmentContainerExitPayload) => void
  inReviewMode: boolean
  l8ySessionId: string
  latePointsDeductedHash: PointsHash
  pointsHash: PointsHash
  questions: Array<AssessmentControllerQuestion>
  recapHash: RecapHash
  studentAssessmentId: number
  userId: string
  vatFrozenHash: VatFrozenHash
}

export const AssessmentContainerPropTypes = {
  assessmentData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    enrollmentAssessmentDueDate: PropTypes.string,
    mergedDueDate: PropTypes.string.isRequired,
    attemptsInAt: PropTypes.number,
    gradingPolicy: PropTypes.oneOf(Object.values(GradingPolicyEnum) as Array<GradingPolicyEnum>),
  }).isRequired,
  assessmentType: PropTypes.oneOf(Object.values(AssessTypeEnum) as Array<AssessTypeEnum>),
  attemptPolicy: PropTypes.oneOf(Object.values(MultipleAttemptPolicyEnum) as Array<MultipleAttemptPolicyEnum>),
  attemptsHash: PropTypes.object.isRequired,
  clarityHash: PropTypes.object.isRequired,
  correctHash: PropTypes.object.isRequired,
  courseId: PropTypes.string.isRequired,
  enableClarity: PropTypes.bool,
  everCorrectHash: PropTypes.object.isRequired,
  handleExit: PropTypes.func.isRequired,
  inReviewMode: PropTypes.bool.isRequired,
  l8ySessionId: PropTypes.string.isRequired,
  latePointsDeductedHash: PropTypes.object.isRequired,
  pointsHash: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  recapHash: PropTypes.object.isRequired,
  studentAssessmentId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  vatFrozenHash: PropTypes.object.isRequired,
};
