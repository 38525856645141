import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';

function DateTimePicker({
  className,
  disabled,
  invalid = false,
  maxDate,
  minDate,
  name,
  onChange,
  value,
}: {
  className: string
  disabled?: boolean
  invalid?: boolean
  maxDate: Date
  minDate: Date
  name: string
  onChange: (updatedDate: Date) => void
  value: Date | null
}) {
  const selected = !!value ? new Date(value) : value;
  const cssClassName = `${className}${invalid ? ' invalid' : ''}`;
  return (
    <DatePicker
      className={cssClassName}
      dateFormat={DateFormatEnum.DatePickerDisplayFormat}
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      name={name}
      onChange={(dateVal) => !!dateVal && onChange(dateVal)}
      selected={selected}
      showTimeSelect
    />
  );
}

DateTimePicker.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default DateTimePicker;
