/***
 * l8yContainerFunctions
 * This function takes an assessment's status and determines whether the finish button should show or not and whether or not it should be disabled when shown
 * The documentation for this logic is here: https://docs.google.com/document/d/13abJF5ypPEjRDpgND4rABGavStEM7ttQxaaws8o-Cy8/edit?usp=sharing
 * tl;dr
 *   - in the SPAT, only enable the finish button when all questions are answered correct
 *   - in the VAT, enable the finish button when all questions are answered, regardless of correctness
 */
import React from 'react';
import styled, { css } from 'styled-components';
import CodonUrls from '../../urls';
import { ClarityEnum, YesNo } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { MultipleAttemptPolicyEnum } from 'types/common.types';
import { QuestionStatus, QuestionStatusHash } from './LearnosityContainer.types';

export const determineFinishButtonState = (isStudyPath: boolean, {
  activeL8yRef,
  questionStatusHash,
  todoQuestionL8yIds,
  allQuestionsFrozenOrAttempted,
}: {
  activeL8yRef: string
  questionStatusHash: QuestionStatusHash
  todoQuestionL8yIds?: Array<string>
  allQuestionsFrozenOrAttempted?: boolean
}) => {
  const activeQuestionIsCorrect = questionStatusHash[activeL8yRef] && questionStatusHash[activeL8yRef].isCorrect === YesNo.Yes;
  const allNonSelectedQuestions = Object.keys(questionStatusHash).filter((l8yId) => l8yId !== activeL8yRef);
  const questionStatusArray = Object.values(questionStatusHash);

  // SPAT finish button logic
  if (isStudyPath && todoQuestionL8yIds) {
    // in SPAT, only show finish if all answered AND correct
    const isLastTodo = [...todoQuestionL8yIds].pop() === activeL8yRef;
    const allAnsweredQuestions = questionStatusArray.filter(({ isCorrect }) => !!isCorrect);
    const allQuestionsHaveBeenAnswered = todoQuestionL8yIds.map((l8yId) => questionStatusHash[l8yId]).every(({ isCorrect }) => !!isCorrect);
    // handle instances where a user skips a question then goes back and gets the question correct
    const allOtherQuestionsAreCorrectButNotCurrentQuestion = !activeQuestionIsCorrect && allNonSelectedQuestions.every((l8yId) => questionStatusHash[l8yId].isCorrect === YesNo.Yes);
    const allQuestionsHaveBeenAnsweredCorrectly = allQuestionsHaveBeenAnswered && allAnsweredQuestions.every(({ isCorrect }) => isCorrect === YesNo.Yes);
    // if all questions are correct show finish regardless of which question the user is on currently
    if (allQuestionsHaveBeenAnsweredCorrectly) {
      return {
        enableFinish: true,
        showFinish: true,
      };
    } else if (isLastTodo || allOtherQuestionsAreCorrectButNotCurrentQuestion) {
      // still show disabled finish btn if on last q (or last unanswered q) and all qs have correct answers
      return {
        enableFinish: false,
        showFinish: true,
      };
    }
    return {
      enableFinish: false,
      showFinish: false,
    };
  }

  // VAT finish button logic
  const allQuestionsHaveBeenAnswered = questionStatusArray.every(({ isCorrect }) => !!isCorrect);
  const allL8yIds = Object.keys(questionStatusHash);
  const isLastQuestion = [...allL8yIds].pop() === activeL8yRef;
  const allOtherQuestionsHaveBeenAnswered = allNonSelectedQuestions.map((l8yId) => questionStatusHash[l8yId]).every(({ isCorrect }) => !!isCorrect);
  if (allQuestionsHaveBeenAnswered || allQuestionsFrozenOrAttempted) {
    return {
      enableFinish: true,
      showFinish: true,
    };
  } else if (isLastQuestion || allOtherQuestionsHaveBeenAnswered) {
    return {
      enableFinish: false,
      showFinish: true,
    };
  }
  return {
    enableFinish: false,
    showFinish: false,
  };
};

export const renderFrozenVatMessage = ({
  assessType,
  attemptPolicy,
  currentQuestionIsCorrect,
  currentQuestionEverCorrect,
  isAfterLate,
  studyPathLink,
  currentItemIsAttempted,
}: {
  assessType: AssessTypeEnum
  attemptPolicy: MultipleAttemptPolicyEnum
  currentQuestionIsCorrect: YesNo
  currentQuestionEverCorrect: YesNo
  isAfterLate: boolean
  studyPathLink: string
  currentItemIsAttempted: boolean
}) => {
  // TODO: Use Link instead of <a> tags
  // TODO: Link directly to correct study path
  console.debug(`renderFrozenVatMessage: ${JSON.stringify({ assessType, attemptPolicy, currentQuestionIsCorrect, currentQuestionEverCorrect, isAfterLate, studyPathLink, currentItemIsAttempted })}`);
  const studyPathMessage = (
    <>
      Want to retry this question? You can do that in the <a href={studyPathLink}>Study Path</a>!
    </>
  );

  if (isAfterLate && assessType !== AssessTypeEnum.Readiness) {
    return studyPathMessage;
  }

  switch (assessType) {
    case AssessTypeEnum.PracticeTest: {
      return (
        <>
          You have attempted this question. After completing the Practice Test, head to the <a href={studyPathLink}>Study Path</a> and click "Review Practice Test Questions" to see how you did.
        </>
      );
    }
    case AssessTypeEnum.Homework:
    case AssessTypeEnum.Preclass: {
      const reviewInStudyPathMessage = (
        <>
          After completing the assignment, head to the <a href={studyPathLink}>Study Path</a> to review this question and others like it.
        </>
      );
      if (!!currentItemIsAttempted) {
        return (
          <>
            You have attempted this question. {reviewInStudyPathMessage}
          </>
        );
      }
      if (currentQuestionIsCorrect === YesNo.Yes || currentQuestionEverCorrect === YesNo.Yes) {
        return (
          <>
            You answered this question correctly. {reviewInStudyPathMessage}
          </>
        );
      } else {
        const incorrectHomeworkForCorrectness = assessType === AssessTypeEnum.Homework && [
          MultipleAttemptPolicyEnum.CorrectnessTypeOne,
          MultipleAttemptPolicyEnum.CorrectnessTypeTwo,
          MultipleAttemptPolicyEnum.CorrectnessTypeThree,
        ].includes(attemptPolicy);

        const incorrectForCompletionOrNoPoints = [
          MultipleAttemptPolicyEnum.CorrectnessTypeFour,
          MultipleAttemptPolicyEnum.ForCompletion,
          MultipleAttemptPolicyEnum.NotForPoints,
        ].includes(attemptPolicy);

        if (incorrectHomeworkForCorrectness) {
          return (
            <>
              You have missed this question twice. After completing the assignment, head to the <a href={studyPathLink}>Study Path</a> to recapture points on missed questions.
              &nbsp;
              <a href={CodonUrls.HowToRecaptureKB}>Learn More.</a>
            </>
          );
        } else if (incorrectForCompletionOrNoPoints) {
          return (
            <>
              {assessType === AssessTypeEnum.Homework ? 'You have missed this question twice.' : ''} {reviewInStudyPathMessage}
            </>
          );
        }
      }
      return studyPathMessage;
    }
    case AssessTypeEnum.Readiness: {
      return (
        <>
          The assignment is no longer for points. Work is for practice only.
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export const distractorRationaleTemplate = (id: number, isCorrect: YesNo, content: string) => {
  const valid = isCorrect === YesNo.No ? 'Incorrect' : 'Correct';
  const validForCss = valid.toLowerCase();
  return `
    <div id="#${id}_distractor" class="lrn_distractor_rationale_wrapper" role="region" aria-label="distractor rationale">
      <div class="lrn_distractor_rationale_list lrn_distractor_rationale_${validForCss}" aria-label="distractor rationale per response ${validForCss}">
        <div class="lrn_distractor_rationale_list_title">${valid}</div>
        ${!content ? '' : `
          <div class="lrn_distractor_rationale">
            <div class="lrn_distractor_rationale_content">${content}</div>
          </div>
        `}
      </div>
    </div>
  `;
};

// <LearnosityWrap>
const showCorrectnessIcon = (isCorrect: QuestionStatus['isCorrect'], allORQs: boolean) => {
  if (!!isCorrect) {
    if (allORQs) {
      return 'url("/check-square.svg")';
    }
    if (isCorrect === YesNo.Yes) {
      return 'url("/circle-check.svg")';
    } else if (isCorrect === YesNo.No) {
      return 'url("/circle-x.svg")';
    }
  }
  return 'none';
};
const showCompletedIcon = (isCorrect: QuestionStatus['isCorrect']) => {
  if (!!isCorrect) {
    return 'url("/check-square.svg")';
  } else {
    return 'none';
  }
};
const showClarityIcon = (clarity: QuestionStatus['clarity']) => {
  if (clarity === ClarityEnum.Muddy) {
    return 'url("/muddy.svg")';
  } else if (clarity === ClarityEnum.Clear) {
    return 'url("/clear.svg")';
  } else {
    return 'none';
  }
};
const showRecapIcon = (recap: boolean) => {
  if (recap === true) {
    return 'url("/recapture.svg")';
  }
  return 'none';
};

const inlineIconCSS = css`
position: absolute;
height: 16px;
width: 16px;
top: 24px;
background-size: 16px 16px;
`;

export const LearnosityWrap = styled.div<{
  assessmentType: AssessTypeEnum
  attribution: string | false
  inReviewMode: boolean
  pointsString: string | null
  questionItems: number
  questionStatusHash: QuestionStatusHash
}>`
  .debug__learnosity {
    position: fixed;
    bottom: 0;
    right: 0;
    visibility: hidden;
  }
  .lrn-assess-content.content.lrn div.row {
    &::before {
      visibility: ${p => p.questionItems === null ? 'hidden' : 'visible'};
      content: '${p => p.questionItems}';
    }
  }
  .lrn-assess-content.content.lrn {
    &::before {
      visibility: ${p => p.pointsString === null ? 'hidden' : 'visible'};
      content: '${p => p.pointsString}';
    }
    &::after {
      visibility: ${p => p.attribution ? 'visible' : 'hidden'};
      content: "${p => p.attribution ? p.attribution : ''}";
    }
  }
  .lrn-assess-ul.slides-vertical-pagination li {
    ${p => p.assessmentType !== AssessTypeEnum.PracticeTest && Object.values(p.questionStatusHash).map(({ isCorrect, allORQs, clarity, canRecap, isSurveyItem }, i) => css`
      &:nth-child(${i + 1}) {
        span.pagination-item-name {
          &::before {
            content: ' ';
            background-image: ${showCorrectnessIcon(isCorrect, allORQs || isSurveyItem)};
            ${inlineIconCSS};
            left: 12px;
          }
        }
        span.pagination-item-number {
          &::before {
            content: ' ';
            background-image: ${showClarityIcon(clarity)};
            ${inlineIconCSS};
            right: 32px;
          }
          &::after {
            content: ' ';
            background-image: ${p.assessmentType !== AssessTypeEnum.PracticeTest ? showRecapIcon(canRecap) : 'none'};
            ${inlineIconCSS};
            right: 58px;
          }
        }
      }
    `)},
    ${p => p.assessmentType === AssessTypeEnum.PracticeTest && Object.values(p.questionStatusHash).map(({ clarity, isCorrect, allORQs, isSurveyItem }, i) => css`
      &:nth-child(${i + 1}) {
        span.pagination-item-name {
          &::before {
            content: ' ';
            background-image: ${p.inReviewMode ? showCorrectnessIcon(isCorrect, allORQs || isSurveyItem) : showCompletedIcon(isCorrect)};
            ${inlineIconCSS};
            left: 12px;
          }
        }
        span.pagination-item-number {
          &::before {
            content: ' ';
            background-image: ${showClarityIcon(clarity)};
            ${inlineIconCSS};
            right: 32px;
          }
        }
      }
    `)}
  }
`;
// </LearnosityWrap>
