import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { FaChevronLeft } from 'react-icons/fa';

import apiNext, { SimplifiedErrorResponse } from 'api-next';
import createStudentAssessment from '../shared/createStudentAssessment';
import sharedStrings, { assessmentTakerWarningStrings } from 'sharedStrings';
import getVatHashesFromSaqa, { VatHashes } from 'utils/getVatHashesFromSaqa';
import { breakdownCourseLosByTopic } from 'utils/courseLearningObjectiveFunctions';
import { saveLogMessage } from 'utils/saveLogMessage';
import logExecutionTime from 'utils/logExecutionTime';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import {
  determineAssessmentWindow,
  determineMultipleAttemptPolicy,
  handleFinishInstructor,
  validateInstructorVatAttempt,
} from 'utils/assessmentFunctions';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveEnrichedStudentAssessments from 'store/selectors/retrieveEnrichedStudentAssessments';

import ReadinessExperienceNavItem from './ReadinessExperienceNavItem';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import Instructions from '../shared/Instructions';
import IntroOutro from '../shared/IntroOutro';
import OutroContent from '../shared/OutroContent';
import AssessmentTakerHeader from '../shared/AssessmentTakerHeader';
import LearnosityController from 'shared-components/LearnosityContainer/LearnosityController';
import { assessmentDataProp } from '../shared/AssessmentTakerProps';
import { AssessmentModeEnum, RenderingTypeEnum } from 'types/backend/assessmentInit.types';
import { L8yContainerEvents, QuestionStatusHash } from 'shared-components/LearnosityContainer/LearnosityContainer.types';

import {
  ClarityHash,
  ConfirmationTypeEnum,
  CorrectHash,
  MappedAssessmentQuestion,
  PointsHash,
} from 'types/common.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTaker/AssessmentTaker.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { SpatHashes } from '../../StudyPathController/sharedStudyPathFunctions';
import { Store } from 'types/store.types';
import { AssessmentLocation, GenericObject, YesNo } from 'types/backend/shared.types';
import { PageStates, ScoreData, Steps } from '../AssessmentTaker.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { RoleEnum } from 'types/backend/roles.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { FirstAttemptedEnum, StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import './ReadinessExperience.scss';

function ReadinessExperience({
  assessmentData,
  handleDone,
  spatL8ySessionId,
  location,
  refreshAssessmentQuestions,
  questions,
  initSpatHashes,
  targetL8yId,
  initStudentAssessmentId,
}: {
  assessmentData: AssessmentWithEnrollment
  handleDone?: () => void
  initSpatHashes?: SpatHashes
  initStudentAssessmentId?: number
  location: AssessmentLocation
  questions: Array<AssessmentControllerQuestion>
  refreshAssessmentQuestions?: (assessmentId: string) => Promise<Array<AssessmentControllerQuestion>>
  spatL8ySessionId?: string
  targetL8yId?: string
}) {
  const isSPAT = location !== AssessmentLocation.REX;
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const user = useSelector((store: Store) => store.user);
  const isInstructor = useSelector((store: Store) => !!store.state.instructorStudentViewCourseId);
  const assessmentQuestionMaps: Array<MappedAssessmentQuestion> = useSelector(retrieveActiveAssessmentQuestionMaps);
  const enrichedStudentAssessments = useSelector(retrieveEnrichedStudentAssessments);
  const studentAssessments = useSelector((store: Store) => store.active.studentAssessments);
  const { id: existingStudentAssessmentId, l8ySessionId: existingL8ySessionId } = studentAssessments.find((sa) => sa.assessmentId === assessmentData.id) || {};

  const questionL8yIdArray = questions.map(({ l8yId }) => l8yId);
  const [currentStep, setCurrentStep] = useState(Steps.Intro);
  const [currentStage, setCurrentStage] = useState(AssessmentTakerQuestionStage.INIT);
  const [correctQuestionCount, updateCorrectQuestionCount] = useState(0);
  const [earnedPoints, updateEarnedPoints] = useState(0);
  const [totalLatePointsDeducted, setTotalLatePointsDeducted] = useState(-1);
  const initL8ySessionId = spatL8ySessionId || existingL8ySessionId || '';
  const [l8ySessionId, setL8ySessionId] = useState(initL8ySessionId);
  const [scoreHash, updateScoreHash] = useState<ScoreData | {}>({});
  const [loHeaderExpanded, setLoHeaderExpanded] = useState(!isSPAT);

  const allClosFromQuestions = questions.map(({ loData }) => loData).flat();
  const initTargetL8yId = targetL8yId || questionL8yIdArray[0];
  // show all LOs on initial load, group this state together since it should always change at the same time
  const [{ activeL8yId, visibleLoIds }, setActiveItemData] = useState({
    visibleLoIds: [] as Array<number>,
    activeL8yId: initTargetL8yId,
  });

  const commonHashes = initSpatHashes || {
    clarityHash: {},
    correctHash: {},
    latePointsDeductedHash: {},
    pointsHash: {},
    recapHash: {},
  };

  const [vatHashes, updateVatHashes] = useReducer((prev: VatHashes, next: Partial<VatHashes>) => {
    return { ...prev, ...next };
  }, {
    ...commonHashes,
    attemptsHash: {},
    vatFrozenHash: {},
  } as VatHashes);
  const [studentAssessmentId, setStudentAssessmentId] = useState(initStudentAssessmentId || existingStudentAssessmentId || -1);
  const {
    dueDate,
    enrollmentAssessmentDueDate,
    id: assessmentId,
    mergedOpenDate,
    lateDate,
  } = assessmentData;

  const { gradingPolicy, freeAttempts, pointPenalty } = assessmentData;
  const attemptPolicy = determineMultipleAttemptPolicy(freeAttempts, pointPenalty, gradingPolicy);

  let assessmentStatus = undefined;
  const currentWindow = determineAssessmentWindow(dueDate, lateDate, enrollmentAssessmentDueDate);
  const isAfterLate = currentWindow === FirstAttemptedEnum.AfterLate;
  const currentAssessment = enrichedStudentAssessments.find(assign => assign.id === assessmentData.id);
  if (currentAssessment) {
    assessmentStatus = currentAssessment.assessmentStatus;
  }

  if (assessmentStatus === undefined) {
    if (currentWindow === FirstAttemptedEnum.BeforeDue) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeDue;
    } else if (currentWindow === FirstAttemptedEnum.BeforeLate) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeLate;
    } else { // after late
      assessmentStatus = StudentAssessmentStatus.NotStartedAfterLate;
    }
  }

  const assessmentQuestionMapsForAssessment = assessmentQuestionMaps.filter((aqm: AssessmentQuestionApi) => aqm.assessmentId === assessmentId);
  const totalQuestions = questions.length;
  const filteredAssessmentQuestionMap = assessmentQuestionMapsForAssessment.filter(({ _derived: { questionData: { l8yId } } }) => questionL8yIdArray.includes(l8yId));
  const totalPoints = filteredAssessmentQuestionMap.map(({ points }) => points).reduce((a, b) => a + b, 0);
  const enrollmentData = useSelector((store: Store) => store.active.enrollment);
  const handleStep = (step: Steps, force?: boolean) => {
    setCurrentStep(force ? step : PageStates[step].next);
  };

  const handleReview = () => {
    if (isInstructor) {
      window.location.reload();
    }
    handleStep(Steps.Intro);
  };

  const handleStartSession = async () => {
    if (!!refreshAssessmentQuestions) {
      const startTime = performance.now();
      // get the very latest assessmentQuestions in case there has been a last minute change
      const refreshedQuestions = await refreshAssessmentQuestions(assessmentData.id);
      if (!refreshedQuestions.length) {
        triggerConfirmationPrompt({
          title: 'Error Starting Assignment',
          message: 'There are no questions in this assignment. Please check back later.',
          onConfirm: () => {},
        });
        return;
      }
      // Get role from enrollment
      if (enrollmentData.roleId === RoleEnum.Student) {
        const studentAssessmentData = await createStudentAssessment({ assessmentData, enrollmentData }) as Required<StudentAssessmentApi>;
        setStudentAssessmentId(studentAssessmentData.id);
        setL8ySessionId(studentAssessmentData.l8ySessionId);
        const studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions([studentAssessmentData.id]) as Array<StudentAssessmentQuestionApiWithSaqas>;
        // get initial state of attempt-related data based on latestStudentAssessmentQuestionAttempt
        const saqaHashes = getVatHashesFromSaqa(refreshedQuestions, studentAssessmentQuestions);
        updateVatHashes(saqaHashes);
      } else if (isInstructor) {
        setL8ySessionId(assessmentData.id);
        const saqaHashes = getVatHashesFromSaqa(refreshedQuestions, [], true);
        updateVatHashes(saqaHashes);
      }
      logExecutionTime(startTime, 'ReadinessExperience handleStartSession');
    }
    handleStep(Steps.Intro);
  };

  const onAssessmentDone = async ({ correctQuestionsArray, updatedVatHashes }: { correctQuestionsArray: Array<StudentAssessmentQuestionApiWithSaqas>; updatedVatHashes: Partial<VatHashes> }) => {
    let newTotalPointsEarned = -1;
    let newCorrectQuestionCount = -1;
    if (isInstructor) {
      const { pointsHash, correctHash } = updatedVatHashes;
      ({ totalPointsEarned: newTotalPointsEarned, correctQuestionCount: newCorrectQuestionCount } = handleFinishInstructor(pointsHash as PointsHash, correctHash as CorrectHash));
    } else {
      const updatedStudentAssessments = await apiNext.getStudentAssessment(assessmentData.id, enrollmentData.id);
      const saData = updatedStudentAssessments.find((sa) => sa.assessmentId === assessmentData.id) as StudentAssessmentApi;
      ({ totalPointsEarned: newTotalPointsEarned } = saData);
      newCorrectQuestionCount = correctQuestionsArray.length;
    }
    if (newTotalPointsEarned !== -1) {
      updateEarnedPoints(newTotalPointsEarned);
    }
    const { latePointsDeductedHash: latePointsDeducted } = updatedVatHashes as Required<VatHashes>;
    const newTotalLatePointsDeducted = Object.values(latePointsDeducted).reduce((acc, cur) => {
      acc = acc + cur;
      return acc;
    }, 0);
    if (newTotalLatePointsDeducted !== -1) {
      setTotalLatePointsDeducted(newTotalLatePointsDeducted);
    }
    updateVatHashes(updatedVatHashes);
    updateCorrectQuestionCount(newCorrectQuestionCount);
    if (isSPAT && !!handleDone) {
      handleDone();
    } else {
      handleStep(Steps.Outro, true);
    }
  };


  const handleL8yContainerEvents = async ({ type, data }: { type: string; data: GenericObject }) => {
    console.debug(`LearnosityContainer event:: ${type}`, data);
    switch (type) {
      case L8yContainerEvents.ITEM_CHANGED: {
        const { activeL8yRef } = data;
        if (!!activeL8yRef) {
          const targetQuestion = questions.find((q) => q.l8yId === activeL8yRef) as AssessmentControllerQuestion;
          const { learningObjectiveIds = [] } = targetQuestion;
          if (!!learningObjectiveIds.length) {
            setActiveItemData({ activeL8yId: activeL8yRef, visibleLoIds: learningObjectiveIds });
          }
        }
        setCurrentStage(AssessmentTakerQuestionStage.INIT);
        break;
      }
      case L8yContainerEvents.QUESTION_CHANGED: {
        setCurrentStage(AssessmentTakerQuestionStage.ANSWER_CHANGED);
        break;
      }
      case L8yContainerEvents.HANDLE_CLARITY: {
        updateVatHashes({ clarityHash: data.clarityHash as ClarityHash });
        break;
      }
      case L8yContainerEvents.HANDLE_FINISHED: {
        const exitHashes = data as Partial<VatHashes>;
        let correctQuestionsArray: Array<StudentAssessmentQuestionApiWithSaqas> = [];
        if (!isInstructor) {
          const studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions([studentAssessmentId]) as Array<StudentAssessmentQuestionApiWithSaqas>;
          correctQuestionsArray = studentAssessmentQuestions.filter(s => s.gradedStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes);
        }
        onAssessmentDone({
          correctQuestionsArray,
          updatedVatHashes: exitHashes,
        });
        break;
      }
      case L8yContainerEvents.VALIDATED: {
        const { activeL8yRef, score, isCorrect, assessmentQuestionId, clarity, attemptData, rawMaxScore } = data;
        updateScoreHash({
          ...scoreHash,
          [activeL8yRef]: {
            score,
            isCorrect,
            clarity,
          },
        });
        const newStudentAttempt = {
          studentAssessmentId,
          assessmentQuestionId,
          location,
          isCorrect,
          rawPointsEarned: score,
          attemptData,
          clarity,
          rawMaxScore,
        };
        // handle update attempt
        console.debug('VALIDATED data, assessmentData, newStudentAttempt', data, assessmentData, newStudentAttempt);
        if (isInstructor) {
          return validateInstructorVatAttempt(assessmentData, data, studentAssessmentId);
        }
        const studentAttemptSubmitted = await apiNext.createStudentAttempt(newStudentAttempt);
        const { error } = studentAttemptSubmitted as SimplifiedErrorResponse;
        if (!!error) {
          console.warn('Error creating student attempt in the VAT', error);
          if (error.errorCode === CodonErrorCode.CreateSAQAAQNotFound) {
            saveLogMessage(`A student encountered an error in the VAT where a question was removed after the VAT was loaded,
              user: ${user.id} in course: ${enrollmentData.courseId}, AQId: ${assessmentQuestionId}, SAId: ${studentAssessmentId}`);
            triggerConfirmationPrompt({
              title: assessmentTakerWarningStrings.IN_PROGRESS_QUESTION_REMOVAL_WARNING_TITLE,
              message: assessmentTakerWarningStrings.IN_PROGRESS_QUESTION_REMOVAL_WARNING_MESSAGE,
              confirmationType: ConfirmationTypeEnum.Warn,
              onConfirm: () => {return;},
            });
            return {};
          } else {
            throw new Error(`Unexpected error in studentAttemptSubmitted ${JSON.stringify(error)}`);
          }
        } else {
          return studentAttemptSubmitted;
        }
      }
      case L8yContainerEvents.QUESTIONS_LOADED: {
        const { questionIds } = data;
        const { learningObjectiveIds } = questions.find(({ l8yId }) => l8yId === initTargetL8yId) as AssessmentControllerQuestion;
        // only set activeItemData on initial load to load the correct topic/LO in header for initial item
        if (!!learningObjectiveIds?.length && !visibleLoIds.length) {
          setActiveItemData((prev) => ({ ...prev, visibleLoIds: learningObjectiveIds }));
        }
        console.debug(L8yContainerEvents.QUESTIONS_LOADED, questionIds);
        break;
      }
    }
  };

  const renderRex = (step: Steps) => {
    const loDisplay = breakdownCourseLosByTopic(allClosFromQuestions);
    switch (step) {
      case Steps.Intro: {
        const openString = DateTime.fromISO(mergedOpenDate).toFormat(DateFormatEnum.WeekdayDateAtTime);
        return (
          <IntroOutro>
            <Instructions assessmentData={assessmentData} attemptPolicy={attemptPolicy} />
            <div className="assessment__action">
              {DateTime.fromISO(mergedOpenDate) < DateTime.local() || isInstructor
                ? <BetterButton className="gray-button" text="Start" onClick={handleStartSession} />
                : `This assessment will open on ${openString}.`
              }
            </div>
          </IntroOutro>
        );
      }
      case Steps.Assessment: {
        if (studentAssessmentId === -1 && !isInstructor) {
          console.error('Cannot render Assessment without valid studentAssessmentId', studentAssessmentId);
          return null;
        }
        const { attemptsInAt, name: l8yName } = assessmentData;
        const items = questions.map(({ l8yId, type }) => ({ l8yId, type }));

        const handleNavClick = (question: AssessmentControllerQuestion, handleItemNav: (l8yId: string) => void) => {
          const { l8yId, learningObjectiveIds = [] } = question;
          setActiveItemData({ activeL8yId: l8yId, visibleLoIds: learningObjectiveIds });
          handleItemNav(l8yId);
        };
        return (
          <div className="assessment-wrap" data-assessmentstage={currentStage}>
            <div className="assessment-taker__header" data-collapsed={!loHeaderExpanded} data-showheader={!!visibleLoIds.length}>
              <button className="toggle-lo-button" onClick={() => setLoHeaderExpanded(!loHeaderExpanded)}>
                {loHeaderExpanded ? <>Hide <abbr title="Learning Objective">LO</abbr>s</> : <>Show <abbr title="Learning Objective">LO</abbr>s</>}
              </button>
              {loDisplay.map(({ topicClos, topic, topicId }) => loHeaderExpanded ? (
                <div className="topic-with-los" key={`${topicId}_${topic.name}`} data-showtopic={topicClos.some((tclo) => visibleLoIds.includes(tclo.id))}>
                  <div className="assessment-taker__header__topic-title">
                    <h1>{topic.name}</h1>
                  </div>
                  <div>
                    {topicClos.map(({ id: loId, title, _derived: { loNumber } }) => (
                      <div className="assessment-taker__header__lo-item" data-showlo={!visibleLoIds.length || visibleLoIds.includes(loId)} key={`${topicId}_${loId}`}>
                        <strong className="assessment-taker__header__lo-number">
                          {loNumber.replace('LO', 'Learning Objective')}
                        </strong>:&nbsp;
                        <span>{title}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <button className="topic-with-los" onClick={() => setLoHeaderExpanded(true)} key={`${topicId}_${topic.name}`} data-showtopic={topicClos.some((tclo) => visibleLoIds.includes(tclo.id))}>
                  <div className="collapsed-topic-title">{topic.name}</div>
                  <div className="collapsed-topic-los">
                    {topicClos.map(({ id: loId, _derived: { loNumber } }) => (
                      <span className="assessment-taker__header__lo-item collapsed-lo-number" data-showlo={!visibleLoIds.length || visibleLoIds.includes(loId)} key={`${loId}_${loNumber}`}>
                        {loNumber}
                      </span>
                    ))}
                  </div>
                </button>
              ))}
            </div>
            <LearnosityController
              activityId={studentAssessmentId.toString()}
              assessmentMode={AssessmentModeEnum.SubmitPractice}
              assessmentType={AssessTypeEnum.Readiness}
              attemptLimit={attemptsInAt}
              attemptPolicy={attemptPolicy}
              courseId={enrollmentData.courseId}
              enableClarity
              handleEvents={handleL8yContainerEvents}
              initAttemptsHash={vatHashes.attemptsHash}
              initClarityHash={vatHashes.clarityHash}
              initCorrectHash={vatHashes.correctHash}
              initLatePointsDeductedHash={vatHashes.latePointsDeductedHash}
              initPointsHash={vatHashes.pointsHash}
              initRecapHash={vatHashes.recapHash}
              initVatFrozenHash={vatHashes.vatFrozenHash}
              inReviewMode={false}
              isInstructor={isInstructor}
              isAfterLate={isAfterLate}
              items={items}
              l8ySessionId={l8ySessionId}
              l8yBoxClassName="col-xs-12 col-sm-9"
              location={location}
              name={l8yName}
              questionData={questions}
              renderingType={RenderingTypeEnum.Assess}
              renderItemNav={(clarityHash: ClarityHash, activeL8yRef: string, handleItemNav: (l8yId: string) => void, questionStatusHash: QuestionStatusHash) => (
                <div className="col-xs-12 col-sm-3 readiness-nav-menu__wrap">
                  <nav aria-label="Assessment Table of Contents" className="readiness-nav-menu">
                    <div className="rex-nav-item__header"></div>
                    {!isSPAT && (
                      <button className="rex-nav-item__about-link" onClick={() => setCurrentStep(Steps.Intro)}>
                        <FaChevronLeft />
                        About this Assignment
                      </button>
                    )}
                    <ul className="rex-nav-list">
                      {questions.map((question) => (
                        <ReadinessExperienceNavItem
                          assessmentQuestion={question}
                          handleClick={() => handleNavClick(question, handleItemNav)}
                          isActive={activeL8yRef === question.l8yId}
                          isSPAT={isSPAT}
                          questionStatus={questionStatusHash[question.l8yId]}
                          key={question.id}
                        />
                      ))}
                    </ul>
                    <div className="rex-nav-item__footer"></div>
                  </nav>
                </div>
              )}
              targetL8yId={activeL8yId}
              userId={user.id}
            />
          </div>
        );
      }
      case Steps.Outro: {
        const { clarityHash } = vatHashes;
        return (
          <OutroContent
            assessmentControllerQuestions={questions}
            assessType={AssessTypeEnum.Readiness}
            clarityHash={clarityHash}
            correctQuestionCount={correctQuestionCount}
            courseId={enrollmentData.courseId}
            earnedPoints={earnedPoints}
            handleReviewAssessment={handleReview}
            isInstructor={isInstructor}
            totalLatePointsDeducted={totalLatePointsDeducted}
            totalPoints={totalPoints}
            totalQuestions={totalQuestions}
          />
        );
      }
      default: return null;
    }
  };

  if (isSPAT) {
    return (
      <div className="study-path__readiness-experience">
        {renderRex(Steps.Assessment)}
      </div>
    );
  }

  return (
    <div className="assessment-taker-root assessment-taker-readiness-experience">
      <AssessmentTakerHeader
        assessmentData={assessmentData}
        assessmentStatus={assessmentStatus}
        totalPoints={totalPoints}
      />
      {isInstructor && (
        <div className="assessment-taker-root__instructor-banner">
          {sharedStrings.INSTRUCTOR_PREVIEW_ASSESSMENT}
          <button onClick={() => window.location.reload()}>
            {sharedStrings.INSTRUCTOR_PREVIEW_RESTART_ASSESSMENT}
          </button>
        </div>
      )}
      <div className="assessment-taker-root__wrap">
        { renderRex(currentStep) }
      </div>
    </div>
  );
}

ReadinessExperience.propTypes = {
  assessmentData: assessmentDataProp,
  handleDone: PropTypes.func,
  location: PropTypes.oneOf(Object.values(AssessmentLocation)).isRequired,
  questions: PropTypes.array.isRequired,
  refreshAssessmentQuestions: PropTypes.func,
  targetL8yId: PropTypes.string,
  initStudentAssessmentId: PropTypes.number,
};

export default ReadinessExperience;

