import apiNext from 'api-next';
import { AppDispatch, Store } from 'types/store.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import activeSlice from 'store/slices/active';

export default function updateUserQuestionLosForUserQuestion(
  reference: string,
  learningObjectiveIds: Array<number>,
  qLoIdsToRemove?: Array<number>
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { userQuestions }, user } = getStore();
    const userQuestion = userQuestions.find(({ l8yId }) => l8yId === reference);
    if (!userQuestion) {
      throw new Error(`${reference} not found in userQuestions`);
    }
    // Add learning objectives to user questions
    if (!!learningObjectiveIds.length) {
      const qlosToCreate = learningObjectiveIds.map(loId => ({
        questionId: userQuestion.id,
        learningObjectiveId: loId,
        type: LibraryTypeEnum.User,
        userId: user.id,
      }));
      const result = await apiNext.createUserQuestionLos(qlosToCreate);
      console.debug('createUserQuestionLos result', result);
    }
    if (!!qLoIdsToRemove) {
      // removal has to be dealt with atomically
      const removeResult = await Promise.all(qLoIdsToRemove.map((qLoId) => apiNext.deleteUserQuestionLo(qLoId)));
      console.debug('deleteUserQuestionLo result', removeResult);
    }
    return dispatch(activeSlice.actions.editActiveUserQuestion({
      ...userQuestion,
      learningObjectiveIds,
    }));
  })();
}
