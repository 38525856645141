import { GradingTypeTag } from 'types/backend/l8y.types';
import { QuestionUseEnum } from 'types/backend/shared.types';
import { LeftRightEnum } from 'types/common.types';

export enum QuestionBuilderStep {
  ChooseLO = 'choose-lo',
  Authoring = 'authoring',
  Reviewing = 'reviewing',
  Close = 'close',
}

export enum ButtonTypeEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Loading = 'loading',
}

export enum ButtonAlias {
  Cancel = 'cancel',
  Done = 'done',
  GoBack = 'back',
  GoNext = 'next',
  GoNextSurvey = 'next-survey',
  OnlySave = 'only-save',
  SaveAndDone = 'save-and-done',
  SaveAndGoBack = 'save-and-back',
  SaveAndGoNext = 'save-and-next',
}

export interface QuestionBuilderFooterButton {
  alias: ButtonAlias
  align?: LeftRightEnum
  buttonType?: ButtonTypeEnum
  disabled?: boolean
  isLoading?: boolean
  loadingText?: string
  label: string
  onClick: () => void
  show?: boolean
}

export interface QuestionBuilderFooterObject {
  [QuestionBuilderStep.ChooseLO]: Array<QuestionBuilderFooterButton>
  [QuestionBuilderStep.Authoring]: Array<QuestionBuilderFooterButton>
  [QuestionBuilderStep.Reviewing]: Array<QuestionBuilderFooterButton>
  [QuestionBuilderStep.Close]: Array<QuestionBuilderFooterButton>
}

export interface InitQuestionBuilder {
  editingAssessmentId: string | null
  blooms: number | null
  l8yId: string | false
  questionId: number | false
  questionUse: QuestionUseEnum
  selectedLoIds: Array<number>
  learningObjectiveIds: Array<number>
  shortTitle: string | null
  gradingType: GradingTypeTag | null
}

export interface QuestionUsageData {
  courseName: string
  courseId: string
  assessmentName: string
  assessmentId: string
}
