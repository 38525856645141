import { GradingTypeTag } from 'types/backend/l8y.types';
import { ClarityEnum, YesNo } from 'types/backend/shared.types';
import { StudentAssessmentQuestionAttemptApi } from 'types/backend/studentAssessmentQuestionAttempts.types';

/*
  These events are passed from L8yContainer to whatever component it lives in.
  They don't necessarily exist in L8y.
*/
export enum L8yContainerEvents {
  QUESTION_CHANGED = 'QUESTION_CHANGED',
  VALIDATED = 'VALIDATED',
  ITEM_CHANGED = 'ITEM_CHANGED',
  QUESTIONS_LOADED = 'QUESTIONS_LOADED',
  ITEM_GOTO = 'ITEM_GOTO',
  ITEM_UNLOAD = 'ITEM_UNLOAD',
  HANDLE_FINISHED = 'HANDLE_FINISHED',
  HANDLE_CLARITY = 'HANDLE_CLARITY',
  CONTAINER_LOADED = 'CONTAINER_LOADED',
}

/*
  These are L8y-specific events.
  https://reference.learnosity.com/items-api/events
*/
export enum L8yEvents {
  ITEM_LOAD = 'item:load',
  ITEM_CHANGED = 'item:changed',
  ITEM_GOTO = 'item:goto',
  ITEM_UNLOAD = 'item:unload',
  ITEM_SETATTEMPTEDRESPONSE = 'item:setAttemptedResponse',
  ITEMS_FETCH_ERROR = 'items:fetch:error',
  ITEMS_LAZYLOAD_ERROR = 'items:lazyload:error',
  TEST_FINISHED_SUBMIT = 'test:finished:submit',
}

export interface QuestionStatus {
  allORQs: boolean
  canRecap: boolean
  clarity?: ClarityEnum
  isCorrect?: YesNo
  isSurveyItem: boolean
}
export interface QuestionStatusHash {
  [key: string]: QuestionStatus
}

export interface L8yErrorData {
  code: number
  msg: string
  errorUI?: string
  detail?: string
}

export interface L8yEventData extends StudentAssessmentQuestionAttemptApi {
  activeL8yRef: string
  score: number
  questionIds: Array<string>
}

export interface L8yQuestionData {
  id: number
  questionId: number
  l8yId: string
  gradingType: GradingTypeTag
}
