import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import AssessmentContainer from './shared/AssessmentContainer';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import Instructions from './shared/Instructions';
import IntroOutro from './shared/IntroOutro';
import OutroContent from './shared/OutroContent';

import { AssessmentTakerPropTypes, AssessmentTakerProps } from './shared/AssessmentTakerProps';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { Store } from 'types/store.types';
import { Steps } from './AssessmentTaker.types';
import './Homework.scss';


function AssessmentTakerPreclass(props: AssessmentTakerProps) {
  const {
    assessmentData,
    attemptPolicy,
    clarityHash,
    correctQuestionCount,
    currentStep,
    earnedPoints,
    enrollmentData,
    handleStartSession,
    l8ySessionId,
    onExit,
    questions,
    studentAssessmentId,
    totalLatePointsDeducted,
    totalPoints,
    totalQuestions,
    handleReviewAssessment,
  } = props;
  const { mergedOpenDate } = assessmentData;
  const openString = DateTime.fromISO(mergedOpenDate).toFormat(DateFormatEnum.WeekdayDateAtTime);
  const isInstructor = useSelector((store: Store) => !!store.state.instructorStudentViewCourseId);

  switch (currentStep) {
    case Steps.Intro: {
      return (
        <IntroOutro>
          <Instructions assessmentData={assessmentData} attemptPolicy={attemptPolicy} />
          <div className="assessment__action">
            {DateTime.fromISO(mergedOpenDate) < DateTime.local() || isInstructor
              ? <BetterButton className="gray-button" text="Start" onClick={handleStartSession} />
              : `This assessment will open on ${openString}.`
            }
          </div>
        </IntroOutro>
      );
    }
    case Steps.Assessment: {
      if (studentAssessmentId === -1 && !isInstructor) {
        console.error('Cannot render Assessment without valid studentAssessmentId', studentAssessmentId);
        return null;
      }
      return (
        <AssessmentContainer
          {...props}
          assessmentType={AssessTypeEnum.Preclass}
          l8ySessionId={l8ySessionId}
          studentAssessmentId={studentAssessmentId}
          handleExit={onExit}
          questions={questions}
          enableClarity
          inReviewMode={false}
        />
      );
    }
    case Steps.Outro: {
      return (
        <OutroContent
          assessmentControllerQuestions={questions}
          assessType={AssessTypeEnum.Preclass}
          clarityHash={clarityHash}
          correctQuestionCount={correctQuestionCount}
          courseId={enrollmentData.courseId}
          earnedPoints={earnedPoints}
          handleReviewAssessment={handleReviewAssessment}
          isInstructor={isInstructor}
          totalLatePointsDeducted={totalLatePointsDeducted}
          totalPoints={totalPoints}
          totalQuestions={totalQuestions}
        />
      );
    }
    default: return null;
  }
}

AssessmentTakerPreclass.propTypes = AssessmentTakerPropTypes;

export default AssessmentTakerPreclass;
