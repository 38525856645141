import React from 'react';

import sharedStrings from 'sharedStrings';
import { externalLink } from 'utils/commonFormattingFunctions';
import CodonUrls from 'urls';

const assessmentBuilderTooltips = {
  assessType: (
    <p>
      <em>Which Assessment Type should I choose?</em><br/><br/>
      Choose <strong>Items</strong> if you want to put together a question-based assessment like a reading quiz,
      problem set, or exam. Most of these items can be automatically graded.<br /><br/>
      Choose <strong>Other Assessment</strong> if you want to assign any other type of assessment.
      Examples include lab reports, exam wrappers, group projects, paper summaries, etc.
    </p>
  ),
  assessmentCategory: (
    <p>
      <em>What is Assessment Category?</em>
      <br/><br/>
      By selecting the Assessment Category, the corresponding portion of the alignment indicator will fill when you add aligned items or tasks.
      <br/><br/>
      <li>Choose <strong>Pre-Class Assessment</strong> for any formative assessment that is completed to prepare in advance of class.</li>
      <li>Choose <strong>Post-Class Assessment</strong> for any formative assessment that is completed after class for additional practice (e.g., homeworks).</li>
      <li>Choose <strong>Summative Assessment</strong> for larger assessments that are meant to evaluate student mastery of the learning objectives.</li>
      <br />
      <em>Curious why assessment is broken down this way? Read more about high-structure course design <a href={window.location.href}>here</a>.</em>
    </p>
  ),
  classDaysCovered: (
    <p>
      <em>Understanding Class Days Covered</em>
      <br/><br/>
      Selecting <strong>Class Days Covered</strong> allows you to indicate which class days the assessment is intended to cover. This capability makes is easy to keep your assessment in sync with your course material.
      <br/><br/>
      As you select class periods, the learning objectives linked to those class periods will fill in. Use this list to make sure the class days are the ones you want. The <strong>linked LOs</strong> will be used to help filter items on the next screen, making it even easier to select items aligned to your teaching.
      <br/><br/>
      Class Days Covered helps with planning and efficiency, but you can always add items to an assessment from any LO from any class, you won't be limited. Class Days covered can also be edited at any time.
    </p>
  ),
  gradeSync: (
    <p>
      Enabling Sync to LMS will make this assessment appear in your LMS Assignments List, Gradebook, and Calendar.
      <br/><br/>
      Visit the Student Performance page to push grades to your LMS gradebook. Grade sync does NOT happen automatically.
    </p>
  ),
  publishing: (
    <p>
      Assessments are completely hidden from students until they are published.
    </p>
  ),
  publishingPracticeTestPublished: (
    <p>
      You can unpublish the Practice Test until students have started it.
    </p>
  ),
  publishingPracticeTestStudyPathPublished: (
    <p>
      Because the Study Path is published, students can see that there will be a Practice Test in the future, but that it is currently unpublished.
    </p>
  ),
  publishingPracticeTestStudyPathUnpublished: (
    <p>
      The Practice Test cannot be published until the Study Path and Prep Questions are published.
    </p>
  ),
  publishingStudyPath: (
    <p>
      The Study Path is completely hidden from students until it is published.
    </p>
  ),
  spbCheckpoint1: (
    <div className="spb-tooltip-content row">
      <div className="tooltip-text col-md-6 col-lg-6 col-xl-6">
        In <b>Checkpoint 1</b>, students are prompted to retry items they missed on selected assessments. If they do so before that assessment's due date, they can recapture points.
        <br/><br/>
        They also see which topics and LOs items are associated with.<br/>
        {externalLink(CodonUrls.StudyPathKB, 'Learn More.')}
      </div>
      <div className="tooltip-image col-md-6 col-lg-6 col-xl-6">
        <img src="/assets/sp-column-ishouldreview.png" alt="Preview of the I Should Review column in the Study Path" />
      </div>
    </div>
  ),
  spbCheckpoint2: (
    <div className="spb-tooltip-content row">
      <div className="tooltip-text col-md-6 col-lg-6 col-xl-6">
        We recommend adding 1-2 Prep Items per LO to give students an opportunity to reinforce their understanding in <b>Checkpoint 2</b>.
        <br/><br/>
        {externalLink(CodonUrls.StudyPathKB, 'Learn More.')}
      </div>
      <div className="tooltip-image col-md-6 col-lg-6 col-xl-6">
        <img src="/assets/sp-column-prepme.png" alt="Preview of the Prep Me column in the Study Path" />
      </div>
    </div>
  ),
  spbCheckpoint3: (
    <div className="spb-tooltip-content row">
      <div className="tooltip-text col-md-6 col-lg-6 col-xl-6">
        We recommend adding items that mirror those that will be on the summative assessment. Students can take the Practice Test after they move all of the Topic Cards into <b>Checkpoint 3</b> (or 3 days before the Study Path due date).
        <br/><br/>
        {externalLink(CodonUrls.StudyPathKB, 'Learn More.')}
      </div>
      <div className="tooltip-image col-md-6 col-lg-6 col-xl-6">
        <img src="/assets/sp-column-practicetest.png" alt="Preview of the Practice Test column in the Study Path" />
      </div>
    </div>
  ),
  startedByStudents: (
    <div>
      <div className="question-list-sidebar__tooltip-header">
        {sharedStrings.HAS_BEEN_STARTED_TOOLTIP_HEADER}
      </div>
      <div className="question-list-sidebar__tooltip-body">
        <li>You cannot add, remove, or reorder items.</li>
        <li>You cannot change points assigned to items.</li>
      </div>
    </div>
  ),
};

export default assessmentBuilderTooltips;
