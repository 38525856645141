/**
 * StudyPathAssessmentTakerNavItem
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FaRegStar } from 'react-icons/fa';

import { getShortAssessType } from 'utils/commonFormattingFunctions';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import Icon, { IconEnum } from 'shared-components/Icon';
import { QuestionDataProp } from './StudyPathAssessmentTakerNavMenu';
import { StudyPathAssessmentTooltip } from '../StudyPathTooltipContent/StudyPathAssessmentTooltip';
import { StudyPathEnrichedQuestion } from '../../StudyPathController.types';
import { ClarityEnum } from 'types/backend/shared.types';
import { ClarityHash } from 'types/common.types';

const StudyPathAssessmentTakerNavItem = ({
  handleQuestionNav,
  isActive,
  clarityHash,
  questionData,
}: {
  clarityHash: ClarityHash
  handleQuestionNav: (l8yId: string, loNumber: string) => void
  isActive: boolean
  questionData: StudyPathEnrichedQuestion
}) => {
  const { l8yId } = questionData.question;
  const { assessment, assessmentNumber, studentAssessmentQuestion, loNumber, loNumberStrings } = questionData;
  const { pointsAvailableToRecap, latestStudentAssessmentQuestionAttempt } = studentAssessmentQuestion;

  // get saved clarity status from LSAQA
  const savedClarity = !!latestStudentAssessmentQuestionAttempt ? latestStudentAssessmentQuestionAttempt.clarity : null;
  // get current clarity (set with user input) from clarityHash state
  const questionClarity = clarityHash[questionData.question.l8yId];
  // try to get latest clarity from clarityHash, if null, use saved clarity
  const currentClarity = questionClarity || savedClarity;
  const canRecap = !!pointsAvailableToRecap && pointsAvailableToRecap > 0;
  const questionTitleString = `${getShortAssessType(assessment.assessType)} ${assessmentNumber} - Q${questionData.questionNumber}`;
  return (
    <li className="spat__nav-item">
      <button
        className={`nav-item ${isActive ? 'active' : ''}`}
        data-l8yid={l8yId}
        onClick={() => handleQuestionNav(l8yId, loNumber)}
      >
        <div className="nav-item__status">
          <div>
            { currentClarity === ClarityEnum.Muddy && <Icon size={16} which={IconEnum.Muddy} /> }
          </div>
          <div>
            { canRecap && <FaRegStar size={16} /> }
          </div>
        </div>
        <BetterTooltip
          content={() => (
            <StudyPathAssessmentTooltip
              title={assessment.title}
              assessType={assessment.assessType}
              assessNumber={assessmentNumber}
              questionNum={questionData.questionNumber}
            />
          )}
          inModal
        >
          <div className="nav-item__content">
            <div className="question-LO">{loNumberStrings.join(' | ')}</div>
            <div className="question-title">
              {questionTitleString}
            </div>
          </div>
        </BetterTooltip>
      </button>
    </li>
  );
};

StudyPathAssessmentTakerNavItem.propTypes = {
  clarityHash: PropTypes.objectOf(PropTypes.string).isRequired,
  handleQuestionNav: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  questionData: QuestionDataProp.isRequired,
};

export default StudyPathAssessmentTakerNavItem;
