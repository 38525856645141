import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import intersection from 'lodash-es/intersection';
import union from 'lodash-es/union';
import difference from 'lodash-es/difference';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';

import { bloomsArray, questionUseArray } from 'codonConstants';
import { AssessmentBuilderContext } from '../../AssessmentBuilderController';
import getSortedUniqueLOsForClassSessions from 'utils/getSortedUniqueLOsForClassSessions';
import retrieveActiveCourseLearningObjectives, { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import retrieveFilterableQuestionTypes from 'store/selectors/retrieveFilterableQuestionTypes';
import retrieveNestedSubjectsUnitsAndTopics, { UnitWithTopics } from 'store/selectors/retrieveNestedSubjectsUnitsAndTopics';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import CheckboxItem from 'shared-components/CheckboxItem/CheckboxItem';

import { FilterState } from '../../filterQuestions';
import { QuestionTypeAbbreviationEnum } from '../../AssessmentBuilderController.types';
import { EnrichedEditingAssessment } from '../../enrichAssessmentForEditing';
import { Store } from 'types/store.types';
import { PositionEnum } from 'types/common.types';
import { LibraryTypeEnum, QuestionUseEnum } from 'types/backend/shared.types';
import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';
import { GradingTypeTag } from 'types/backend/l8y.types';
import './QuestionFilter.scss';


function QuestionFilterUnit({ selectedTopicIds, setSelectedTopicIds, toggleTopic, unit }: {
  selectedTopicIds: Array<number>
  setSelectedTopicIds: (selectedTopicArray: Array<number>) => void
  toggleTopic: (lvl: number, unitId: number) => void
  unit: UnitWithTopics
}) {
  const [expanded, setUnitExpanded] = useState(false);
  const { topics: unitTopics = [] } = unit;
  const unitTopicIds = unitTopics.map(({ id }) => id);
  const allTopicsSelected = unitTopics.every(({ id: topicId }) => selectedTopicIds.includes(topicId));
  const someTopicsSelected = unitTopics.some(({ id: topicId }) => selectedTopicIds.includes(topicId));
  const handleUnitToggle = () => {
    // if clicking unit checkbox while any selected, deselect all
    if (someTopicsSelected) {
      setSelectedTopicIds(selectedTopicIds.filter((topicId) => !unitTopicIds.includes(topicId)));
    } else {
      // if clicking unit checkbox while no topics selected, select all topics within unit
      setSelectedTopicIds([...selectedTopicIds, ...unitTopicIds]);
    }
  };
  return (
    <div className="assessment-filter__sub-topic" data-no-children={!unit.unitHasTopicsWithLos}>
      <div className="assessment-filter__sub-topic-expand" data-expanded={expanded} key={`sub-topic_${unit.id}-${unit.name}`}>
        <div className="sub-topic__chevron-container" data-no-children={!unit.unitHasTopicsWithLos}>
          <IoIosArrowForward
            className={`sub-topic__chevron ${expanded ? 'isActive' : ''}`}
            data-no-children={!unit.unitHasTopicsWithLos}
            onClick={() => setUnitExpanded(!expanded)}
          />
        </div>
        <button className="sub-topic__checkbox" onClick={handleUnitToggle}>
          {someTopicsSelected
            ? <FaRegCheckSquare style={{ opacity: allTopicsSelected ? 1 : 0.5 }} />
            : <FaRegSquare />
          }
        </button>
        <button className="sub-topic__name" onClick={() => setUnitExpanded(!expanded)}>
          {unit.name}
        </button>
      </div>
      {expanded && (
        <ul className="sub-topic__list">
          {unitTopics.map((topicItem) => (
            <li key={topicItem.name}>
              <CheckboxItem
                selected={selectedTopicIds.includes(topicItem.id) && topicItem.hasLos}
                onClick={() => toggleTopic(3, topicItem.id)}
                label={topicItem.name}
                data-topicid={topicItem.id}
                disabled={!topicItem.hasLos}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

interface QuestionFilterProps {
  currentAssessment: EnrichedEditingAssessment | AssessmentApiBase
  setActiveFilters: React.Dispatch<React.SetStateAction<FilterState>>
  onRemoveFilters: (showAllQuestionUse?: boolean) => void
}

export default function QuestionFilter({
  currentAssessment,
  setActiveFilters,
  onRemoveFilters,
}: QuestionFilterProps) {
  const { assessType } = currentAssessment;
  const { activeFilters } = React.useContext(AssessmentBuilderContext);
  const isSPB = [AssessTypeEnum.Summative, AssessTypeEnum.Prep, AssessTypeEnum.PracticeTest].includes(assessType);
  const classSessions = useSelector(retrieveBetterClassSessions);
  const courseLearningObjectives = useSelector(retrieveActiveCourseLearningObjectives);
  const filterableQuestionTypes = useSelector(retrieveFilterableQuestionTypes);
  const course = useSelector((store: Store) => store.active.course);
  const subjects = useSelector((store: Store) => store.passive.subjects);
  const topics = useSelector((store: Store) => store.active.topics);
  const primarySubjectId = course.subjectId;
  const { name: primarySubjectName } = subjects.find(s => s.id === primarySubjectId) || {};
  const allSubjectsUnitsAndTopics = useSelector(retrieveNestedSubjectsUnitsAndTopics);

  const primarySubjectUnitsWithTopics = allSubjectsUnitsAndTopics.find(({ id }) => id === primarySubjectId)?.units || [];
  const additionalSubjectsUnitsAndTopics = allSubjectsUnitsAndTopics.filter(({ id }) => id !== primarySubjectId);

  const [bloomActivity, setBloomActivity] = useState(false);
  const [questionUseActivity, setQuestionUseActivity] = useState(false);
  const [authorActivity, setAuthorActivity] = useState(false);
  const [loActivity, setLoActivity] = useState(true);
  const [addCloActivity, setAddCloActivity] = useState(false);
  const [questionTypeActivity, setQuestionTypeActivity] = useState(false);
  const [gradingTypeActivity, setGradingTypeActivity] = useState(false);

  //TODO: getSortedUnique should be replace with a selector call once we know how to do this
  const classSessionClos = getSortedUniqueLOsForClassSessions(currentAssessment.classSessionIds, classSessions);
  const authors: Array<[string, LibraryTypeEnum]> = [['Codon', LibraryTypeEnum.Template], ['Custom', LibraryTypeEnum.User]];
  const gradingTypes: Array<[string, GradingTypeTag]> = [['Assessment', GradingTypeTag.Assessment], ['Survey', GradingTypeTag.Survey]];

  function isLoSelected(clo: EnrichedCourseLearningObjective) {
    return !!activeFilters.los.find((lo) => clo.id === lo);
  }

  function toggleTopic(level: number, topicId: number) {
    let topicsToSelect = [];
    if (level === 2) { // unit (formerly topicTwo)
      topicsToSelect = topics.filter((t3) => t3.unitId === topicId).map((t3) => t3.id);
    } else if (level === 3) { // topic (formerly topicThree)
      topicsToSelect.push(topicId);
    }
    const existing = intersection(activeFilters.topics, topicsToSelect);
    let newTopics = union(activeFilters.topics, topicsToSelect);
    newTopics = difference(newTopics, existing);
    setActiveFilters({ ...activeFilters, topics: newTopics });
  }

  function toggleBlooms(level: number) {
    const { blooms } = activeFilters;
    const newBloomsArray = blooms.includes(level)
      ? blooms.filter((v) => v !== level)
      : [...blooms, level];
    setActiveFilters({ ...activeFilters, blooms: newBloomsArray });
  }

  function toggleQuestionType(type: QuestionTypeAbbreviationEnum) {
    const { questionTypes: questionTypesArray } = activeFilters;
    const newQuestionTypes = questionTypesArray.includes(type)
      ? questionTypesArray.filter((v) => v !== type)
      : [...questionTypesArray, type];
    setActiveFilters({ ...activeFilters, questionTypes: newQuestionTypes });
  }

  function toggleQuestionUse(use: QuestionUseEnum) {
    const { questionUse } = activeFilters;
    const updatedQuestionUseArray = questionUse.includes(use)
      ? questionUse.filter((v) => v !== use)
      : [...questionUse, use];
    setActiveFilters({ ...activeFilters, questionUse: updatedQuestionUseArray });
  }

  function toggleLo(clo: number) {
    const { los } = activeFilters;
    const updatedLosArray = los.includes(clo)
      ? los.filter((v) => v !== clo)
      : [...los, clo];
    const losFromClos = updatedLosArray.map((loid) => courseLearningObjectives.find((lo) => lo.id === loid) as EnrichedCourseLearningObjective);
    const sortedCloIds = losFromClos.sort((a, b) => a._derived.courseWideSort - b._derived.courseWideSort).map(({ id }) => id);
    setActiveFilters({ ...activeFilters, los: sortedCloIds });
  }

  function toggleAuthor(val: LibraryTypeEnum) {
    const { author } = activeFilters;
    const updatedAuthorArray = author.includes(val)
      ? author.filter((v) => v !== val)
      : [...author, val];
    setActiveFilters({ ...activeFilters, author: updatedAuthorArray });
  }

  function toggleGradingType(val: GradingTypeTag) {
    const { gradingType } = activeFilters;
    const updatedGradingTypeArray = gradingType.includes(val)
      ? gradingType.filter((v) => v !== val)
      : [...gradingType, val];
    setActiveFilters({ ...activeFilters, gradingType: updatedGradingTypeArray });
  }

  function toggleAssignment() {
    setActiveFilters({ ...activeFilters, assignment: !activeFilters.assignment });
  }

  return (
    <div className="assessment-filter">
      <div className="assessment-filter__header-bar" />
      <div className="assessment-filter__wrapper">
        <div className="row">
          <div className="assessment-filter__drawer-column col-sm-4">
            <div className="assessment-filter__block lo-filter suggested-lo-filter">
              <div className="filter-title assessment-filter__drawer-column-title">
                <div>Filter by LOs</div>
              </div>
              <button className="filter-title" onClick={() => setLoActivity(!loActivity)}>
                <IoIosArrowForward className={loActivity ? 'isActive' : ''} />
                Suggested LOs
                <BetterTooltip
                  content={() => isSPB
                    ? 'Based on LOs from Class Days Covered and items in Selected Assessments'
                    : 'Based on LOs from Class Days Covered'
                  }
                  indicate
                  position={PositionEnum.Right}
                  wide
                />
              </button>
              {!!loActivity && (
                <ul className="assessment-filter__los">
                  {classSessionClos.map((clo) => (
                    <li
                      data-lonumber={clo._derived.loNumber}
                      key={`clo-item_${clo.title}-${clo._derived.loNumber}`}
                    >
                      <CheckboxItem
                        className="suggested-lo-item"
                        selected={isLoSelected(clo)}
                        onClick={() => toggleLo(clo.id)}
                        label={(<><b>{clo._derived.loNumber}</b> {clo.title}</>)}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="assessment-filter__block lo-filter course-lo-filter">
              <button className="filter-title" onClick={() => setAddCloActivity(!addCloActivity)}>
                <IoIosArrowForward className={addCloActivity ? 'isActive' : ''} />
                All LOs in My Course
              </button>
              {!!addCloActivity && (
                <ul className="assessment-filter__los">
                  {courseLearningObjectives.map((clo) => (
                    <li
                      key={`additional-clo-item_${clo.title}-${clo._derived.loNumber}`}
                      data-lonumber={clo._derived.loNumber}
                    >
                      <CheckboxItem
                        className="additional-lo-item"
                        selected={isLoSelected(clo)}
                        onClick={() => toggleLo(clo.id)}
                        label={(<><b>{clo._derived.loNumber}</b> {clo.title}</>)}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="assessment-filter__drawer-column col-sm-4">
            <div className="assessment-filter__block">
              <div className="filter-title assessment-filter__drawer-column-title">
                Browse {primarySubjectName} Library
              </div>
              {primarySubjectUnitsWithTopics.map((unitItem) => (
                <QuestionFilterUnit
                  key={unitItem.id}
                  selectedTopicIds={activeFilters.topics}
                  setSelectedTopicIds={(updatedTopics) => setActiveFilters({ ...activeFilters, topics: updatedTopics })}
                  toggleTopic={toggleTopic}
                  unit={unitItem}
                />
              ))}
              <div className="filter-title assessment-filter__drawer-divider"/>
              {!!course.additionalSubjectIds.length && (
                <div>
                  <div className="filter-title">
                    Browse Additional Subjects
                  </div>
                  {additionalSubjectsUnitsAndTopics.map((subjectItem) => (
                    <div key={subjectItem.id}>
                      <div className="filter-title assessment-filter__drawer-column-title">
                        {subjectItem.name}
                      </div>
                      {subjectItem.units.map((unitItem) => (
                        <QuestionFilterUnit
                          key={unitItem.id}
                          selectedTopicIds={activeFilters.topics}
                          setSelectedTopicIds={(updatedTopics) => setActiveFilters({ ...activeFilters, topics: updatedTopics })}
                          toggleTopic={toggleTopic}
                          unit={unitItem}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="assessment-filter__drawer-column col-sm-4">
            <div className="assessment-filter__block">
              <div className="filter-title assessment-filter__drawer-column-title">
                Filter by Item Metadata
              </div>
              <div className="assessment-filter__block">
                <button className="filter-title question-use" onClick={() => setQuestionUseActivity(!questionUseActivity)}>
                  <IoIosArrowForward className={questionUseActivity ? 'isActive' : ''} />
                  Item Use
                </button>
                {questionUseActivity && (
                  <ul className="assessment-filter__items question-use-list">
                    {questionUseArray.map(([label, value]) => (
                      <li key={`question-use_${label}`} data-questionuse={value}>
                        <CheckboxItem
                          selected={activeFilters.questionUse.includes(value)}
                          onClick={() => toggleQuestionUse(value)}
                          name={value}
                          label={label}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="assessment-filter__block">
                <button id="authorFilter" className="filter-title" onClick={() => setAuthorActivity(!authorActivity)}>
                  <IoIosArrowForward className={authorActivity ? 'isActive' : ''} />
                  Author
                </button>
                {!!authorActivity && (
                  <ul className="assessment-filter__items plain">
                    {authors.map(([author, libraryType]) => (
                      <li key={author}>
                        <CheckboxItem
                          className="toggle-author"
                          selected={activeFilters.author.includes(libraryType)}
                          onClick={() => toggleAuthor(libraryType)}
                          label={author}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="assessment-filter__block">
                <button className="filter-title" onClick={() => setBloomActivity(!bloomActivity)}>
                  <IoIosArrowForward className={bloomActivity ? 'isActive' : ''} />
                  Bloom's Level
                </button>
                <ol className="assessment-filter__items">
                  {bloomActivity && bloomsArray.map(({ name, value }) => (
                    <li key={name}>
                      <CheckboxItem
                        selected={activeFilters.blooms.includes(value)}
                        className="toggle-blooms"
                        onClick={() => toggleBlooms(value)}
                        label={(<>{value}. {name}</>)}
                      />
                    </li>
                  ))}
                </ol>
              </div>
              <div className="assessment-filter__block">
                <button className="filter-title" onClick={() => setGradingTypeActivity(!gradingTypeActivity)}>
                  <IoIosArrowForward className={gradingTypeActivity ? 'isActive' : ''} />
                  Grading Type
                </button>
                {!!gradingTypeActivity && (
                  <ul className="assessment-filter__items plain">
                    {gradingTypes.map(([gradingTypeName, gradingType]) => (
                      <li key={gradingTypeName}>
                        <CheckboxItem
                          className="toggle-grading"
                          selected={activeFilters.gradingType.includes(gradingType)}
                          onClick={() => toggleGradingType(gradingType)}
                          label={gradingTypeName}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="assessment-filter__block">
                <button className="filter-title" onClick={() => setQuestionTypeActivity(!questionTypeActivity)}>
                  <IoIosArrowForward className={questionTypeActivity ? 'isActive' : ''} />
                  Item Type
                </button>
                <ul className="assessment-filter__items">
                  {questionTypeActivity && filterableQuestionTypes.map(({ userText, abbreviation }) => (
                    <li key={abbreviation}>
                      <CheckboxItem
                        data-questiontype={abbreviation}
                        selected={activeFilters.questionTypes.includes(abbreviation)}
                        onClick={() => toggleQuestionType(abbreviation)}
                        label={`${userText} (${abbreviation})`}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="assessment-filter__toggles">
                <CheckboxItem
                  className="toggle-assignment"
                  selected={activeFilters.assignment}
                  onClick={() => toggleAssignment()}
                  label="Show previously assigned items"
                />
              </div>
              <button className="assessment-filter__header-reset" onClick={() => onRemoveFilters(true)}>
                Show All Items
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

QuestionFilter.propTypes = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
  }),
  setActiveFilters: PropTypes.func.isRequired,
  onRemoveFilters: PropTypes.func.isRequired,
};
