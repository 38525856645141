import React, { createContext, useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import PropTypes from 'prop-types';

import getUniqueLosSortedByLoNumber from 'utils/getUniqueLosSortedByLoNumber';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import AssessmentBuilderActionBar, { ItemTypeEnum } from '../AssessmentBuilderActionBar/AssessmentBuilderActionBar';

import QuestionList from '../QuestionList/QuestionList';
import QuestionFilter from '../QuestionFilter/QuestionFilter';
import QuestionListSidebar from '../QuestionListSidebar/QuestionListSidebar';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { QuestionAction, TabEnum, TabNavigate } from '../../AssessmentBuilderController.types';
import { EnrichedEditingAssessment } from '../../enrichAssessmentForEditing';
import { FilterState } from '../../filterQuestions';
import { QuestionPreviewLaunchWithMetadata } from 'utils/getAssessmentQuestionsMetadata';
import { LoadingState } from 'types/common.types';
import './AssessmentQuestionSelector.scss';

// this context exists so that QuestionListSidebarSelectedQuestionsListItem can know about the loading status without drilling through 3 layers
export const AssessmentQuestionSelectorContext = createContext({ loadingState: LoadingState.Init });

function AssessmentQuestionSelector({
  currentAssessment,
  currentTab,
  getStartedQuestionIds,
  navigate,
  onChangeQuestionSort,
  onFinalize,
  onRemoveFilters,
  questionAction,
  questionList,
  selectedLos,
  setActiveFilters,
  setQuestionPreview,
}: {
  currentAssessment: EnrichedEditingAssessment | AssessmentApiBase
  currentTab: TabEnum
  getStartedQuestionIds: (assessmentId: string) => Promise<Array<number>>
  navigate: TabNavigate
  onChangeQuestionSort: (assessmentQuestionId: number, previousId: number) => void
  onFinalize: () => void
  onRemoveFilters: (showAllQuestionUse?: boolean) => void
  questionAction: QuestionAction
  questionList: Array<ActiveCombinedQuestion>
  selectedLos: Array<EnrichedCourseLearningObjective>
  setActiveFilters: React.Dispatch<React.SetStateAction<FilterState>>
  setQuestionPreview: (preview: QuestionPreviewLaunchWithMetadata | null) => void
}) {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [startedQuestionIds, setStartedQuestionIds] = useState<Array<number>>([]);
  const [loadingState, setLoadingState] = useState(LoadingState.Init);
  const sortedCourseLos: Array<EnrichedCourseLearningObjective> = getUniqueLosSortedByLoNumber(selectedLos);

  // This useEffect loads the assessmentQuestionIds that have been started for a particular assessment to enable fine grained editing of the assessments.
  useEffect(() => {
    const getStartedQIds = async () => {
      setLoadingState(LoadingState.Loading);
      const startedQIds = await getStartedQuestionIds(currentAssessment.id);
      setStartedQuestionIds(startedQIds);
      setLoadingState(LoadingState.Loaded);
    };
    if (loadingState === LoadingState.Init) {
      getStartedQIds();
    }
  }, [currentAssessment.id, currentTab, getStartedQuestionIds, loadingState]);


  return (
    <AssessmentQuestionSelectorContext.Provider value={{ loadingState }}>
      <div className="assessment-question-selector__wrapper">
        <div className="assessment-question-selector__header-wrap">
          <button
            className="assessment-question-selector__expand-filter"
            onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)}
            data-expanded={isFilterDrawerOpen}
          >
            <FaFilter />
            {!isFilterDrawerOpen
              ? 'SHOW FILTERS'
              : 'HIDE FILTERS'
            }
          </button>
          <div className="assessment-question-selector__header-filtering-by">
            <div className="assessment-question-selector__header-filtering-by-criteria">
              {!!sortedCourseLos.length && (
                <>
                  Currently Filtering by:
                  <span className="filtering-by__lo-list">
                    {sortedCourseLos.map(({ _derived: { loNumber }, id, stringId, title }) => (
                      <BetterTooltip key={loNumber} content={() => <p>{title}</p>}>
                        <span data-lonumber={loNumber} data-stringid={stringId} className="assessment-question-selector__lo-filter">
                          {loNumber}
                        </span>
                      </BetterTooltip>
                    ))}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {isFilterDrawerOpen && (
          <div className="assessment-question-selector__header-filter-drawer">
            <QuestionFilter
              currentAssessment={currentAssessment}
              setActiveFilters={setActiveFilters}
              onRemoveFilters={onRemoveFilters}
            />
          </div>
        )}
        <main className="assessment-question-selector__list">
          <QuestionList
            currentAssessment={currentAssessment}
            navigate={navigate}
            questionAction={questionAction}
            questionList={questionList}
            setQuestionPreview={setQuestionPreview}
            startedQuestionIds={startedQuestionIds}
          />
          <QuestionListSidebar
            currentTab={currentTab}
            currentAssessment={currentAssessment}
            onChangeQuestionSort={onChangeQuestionSort}
            questionAction={questionAction}
            navigate={navigate}
            key={currentAssessment.id}
            setQuestionPreview={setQuestionPreview}
            startedQuestionIds={startedQuestionIds}
          />
        </main>
        <AssessmentBuilderActionBar
          rightSideButtons={[
            {
              className: 'assessment-builder__back',
              itemType: ItemTypeEnum.SecondaryBtn,
              itemId: 'assessmentsQuestionSelectorBack',
              itemText: 'BACK',
              onClick: () => {
                switch (currentTab) {
                  case TabEnum.SelectQuestions:
                    return navigate(TabEnum.Editing);
                  case TabEnum.SelectPrepQuestions:
                    return navigate(TabEnum.AssessmentReview);
                  case TabEnum.SelectPracticeQuestions:
                    return navigate(TabEnum.SelectPrepQuestions);
                }
              },
            },
            {
              className: 'assessment-builder__continue',
              itemType: ItemTypeEnum.PrimaryBtn,
              itemId: 'saveAndContinue',
              itemText: 'CONTINUE',
              onClick: () => navigate(TabEnum.SelectPracticeQuestions),
              show: currentTab === TabEnum.SelectPrepQuestions,
            },
            {
              className: 'assessment-builder__finish',
              itemType: ItemTypeEnum.PrimaryBtn,
              itemId: 'returnToOverview',
              itemText: 'RETURN TO COURSE OVERVIEW',
              onClick: () => onFinalize(),
              show: [TabEnum.SelectQuestions, TabEnum.SelectPracticeQuestions].includes(currentTab),
            },
          ]}
        />
      </div>
    </AssessmentQuestionSelectorContext.Provider>
  );
}

AssessmentQuestionSelector.propTypes = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
  }).isRequired,
  questionList: PropTypes.array.isRequired,
  navigate: PropTypes.func.isRequired,
  setQuestionPreview: PropTypes.func.isRequired,
  selectedLos: PropTypes.array.isRequired,
  onFinalize: PropTypes.func,
  onChangeQuestionSort: PropTypes.func,
  questionAction: PropTypes.func.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  onRemoveFilters: PropTypes.func.isRequired,
  getStartedQuestionIds: PropTypes.func.isRequired,
};

export default AssessmentQuestionSelector;
