/**
 * LearnosityController
 *
 * This component is an attempt to begin bringing some TypeScript sanity to LearnosityContainer.
 * All it does is give us a typed wrapper around LearnosityContainer, which is too big to TypeScriptify all in one go.
 * In future I may escalate some of the state from L8yContainer up to this component.
 **/
import React from 'react';
import LearnosityContainer from './LearnosityContainer';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import { AssessmentModeEnum, RenderingTypeEnum } from 'types/backend/assessmentInit.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { L8ySessionState } from 'types/backend/l8ySessions.types';
import { AssessmentLocation, LibraryTypeEnum } from 'types/backend/shared.types';
import { QuestionDatumForSPATL8y } from 'student/controllers/Course/StudyPathController/StudyPathController.types';
import {
  AttemptsHash,
  ClarityHash,
  CorrectHash,
  MultipleAttemptPolicyEnum,
  PointsHash,
  RecapHash,
  VatFrozenHash,
} from 'types/common.types';
import {
  L8yContainerEvents,
  L8yErrorData,
  L8yEventData,
  L8yQuestionData,
  QuestionStatusHash,
} from './LearnosityContainer.types';

function LearnosityController({
  activityId,
  assessmentMode,
  assessmentType,
  attemptLimit,
  attemptPolicy,
  courseId,
  enableClarity,
  handleEvents,
  onL8yError,
  initAttemptsHash,
  initClarityHash,
  initCorrectHash,
  initEverCorrectHash,
  initLatePointsDeductedHash,
  initPointsHash,
  initRecapHash,
  initVatFrozenHash,
  initState,
  inReviewMode,
  isAfterLate,
  isInstructor,
  items,
  l8yBoxClassName,
  l8ySessionId,
  location,
  name,
  questionData,
  renderingType,
  renderItemNav,
  targetL8yId,
  todoQuestionL8yIds,
  userId,
}: {
  activityId: string
  assessmentMode: AssessmentModeEnum
  assessmentType: AssessTypeEnum | null
  attemptLimit?: number | null
  attemptPolicy?: MultipleAttemptPolicyEnum
  courseId?: string
  enableClarity?: boolean
  handleEvents: ({ type, data }: { type: L8yContainerEvents; data: L8yEventData }) => void
  onL8yError?: (error: L8yErrorData) => void
  initAttemptsHash?: AttemptsHash
  initClarityHash?: ClarityHash
  initCorrectHash?: CorrectHash
  initEverCorrectHash?: CorrectHash
  initLatePointsDeductedHash?: PointsHash
  initPointsHash?: PointsHash
  initRecapHash?: RecapHash
  initVatFrozenHash?: VatFrozenHash
  initState?: L8ySessionState
  inReviewMode: boolean
  isAfterLate?: boolean
  isInstructor?: boolean
  items: Array<{ l8yId: string; type: LibraryTypeEnum }>
  l8yBoxClassName?: string
  l8ySessionId?: string
  location: AssessmentLocation
  name: string
  questionData: Array<L8yQuestionData> | Array<AssessmentControllerQuestion> | Array<QuestionDatumForSPATL8y>
  renderingType: RenderingTypeEnum
  renderItemNav?: (
    clarityHash: ClarityHash,
    activeL8yRef: string,
    handleItemNav: (l8yId: string) => void,
    questionStatusHash: QuestionStatusHash
  ) => React.ReactNode
  targetL8yId?: string
  todoQuestionL8yIds?: Array<string>
  userId: string
}) {
  return (
    <LearnosityContainer
      activityId={activityId}
      assessmentMode={assessmentMode}
      assessmentType={assessmentType}
      attemptLimit={attemptLimit}
      attemptPolicy={attemptPolicy}
      courseId={courseId}
      enableClarity={enableClarity}
      handleEvents={handleEvents}
      onL8yError={onL8yError}
      initAttemptsHash={initAttemptsHash}
      initClarityHash={initClarityHash}
      initCorrectHash={initCorrectHash}
      initEverCorrectHash={initEverCorrectHash}
      initLatePointsDeductedHash={initLatePointsDeductedHash}
      initPointsHash={initPointsHash}
      initRecapHash={initRecapHash}
      initVatFrozenHash={initVatFrozenHash}
      initState={initState}
      inReviewMode={inReviewMode}
      isAfterLate={isAfterLate}
      isInstructor={isInstructor}
      items={items}
      l8yBoxClassName={l8yBoxClassName}
      l8ySessionId={l8ySessionId}
      location={location}
      name={name}
      questionData={questionData}
      renderingType={renderingType}
      renderItemNav={renderItemNav}
      targetL8yId={targetL8yId}
      todoQuestionL8yIds={todoQuestionL8yIds}
      userId={userId}
    />
  );
}

export default LearnosityController;
