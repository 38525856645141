import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { externalLink } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import CodonUrls from 'urls';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { MultipleAttemptPolicyEnum } from 'types/common.types';
import { attemptPolicyProp } from './AssessmentTakerProps';
import './Instructions.scss';

// shared defaults
const correctnessTypeOneGradingPolicy = (
  <>
    You earn 100% credit for each question answered correctly, regardless of the number of attempts you make.
  </>
);
const correctnessTwoGradingPolicyDefault = (
  <>
    You earn 100% points for answering a question correctly on the first or second attempt, 90% points on the third attempt, 80% on the fourth attempt, etc.
  </>
);
const correctnessThreeGradingPolicyDefault = (
  <>
    You earn 100% points for answering a question correctly on the first attempt, 90% points on the second attempt, 80% on the third attempt, etc.
  </>
);
const correctnessFourGradingPolicyDefault = (
  <>
    You earn 100% points for answering a question correctly on the first attempt. No points are earned on subsequent attempts.
  </>
);
const completionGradingPolicyDefault = (
  <>
    You earn 100% points for answering each question, even if incorrect.
  </>
);
const notForPointsDefault = (
  <>
    This assignment is not for points.
  </>
);
const moreInformationDefault = (
  <>
    <strong>Visit our {externalLink(CodonUrls.StudentSupportKB, 'Knowledge Base')} for more information</strong> about Codon assignment policies, including {externalLink(CodonUrls.GradingPolicyKB, 'how open-response and multipart questions are graded')}, how {externalLink(CodonUrls.HowToRecaptureKB, 'point recapture')} works, and how to {externalLink(CodonUrls.RevisitPastQuestionsKB, 'review this assignment after the due date')}.
  </>
);

const notFound = {
  instructorGradingPolicy: '',
  moreInformation: '',
};

interface InstructionsObject {
  instructorGradingPolicy: string | React.ReactNode
  moreInformation: string | React.ReactNode
}

interface IntroTextObject {
  [AssessTypeEnum.Preclass]: {
    [key in MultipleAttemptPolicyEnum]: InstructionsObject
  }
  [AssessTypeEnum.Readiness]: {
    [key in MultipleAttemptPolicyEnum]: InstructionsObject
  }
  [AssessTypeEnum.Homework]: {
    [key in MultipleAttemptPolicyEnum]: InstructionsObject
  }
  [AssessTypeEnum.Prep]: {
    [key in MultipleAttemptPolicyEnum]: InstructionsObject
  }
  [AssessTypeEnum.PracticeTest]: {
    [key in MultipleAttemptPolicyEnum]?: InstructionsObject
    // PT only has a subset of the MAP options available
  }
}

const instructionsData: IntroTextObject = {
  [AssessTypeEnum.Preclass]: {
    [MultipleAttemptPolicyEnum.CorrectnessTypeOne]: {
      instructorGradingPolicy: correctnessTypeOneGradingPolicy,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeTwo]: {
      instructorGradingPolicy: correctnessTwoGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeThree]: {
      instructorGradingPolicy: correctnessThreeGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeFour]: {
      instructorGradingPolicy: correctnessFourGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.ForCompletion]: {
      instructorGradingPolicy: completionGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotForPoints]: {
      instructorGradingPolicy: notForPointsDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotFound]: notFound,
  },
  // Readiness instructions is currently identical to preclass, this will change when Alexa is able to give feedback
  [AssessTypeEnum.Readiness]: {
    [MultipleAttemptPolicyEnum.CorrectnessTypeOne]: {
      instructorGradingPolicy: correctnessTypeOneGradingPolicy,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeTwo]: {
      instructorGradingPolicy: correctnessTwoGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeThree]: {
      instructorGradingPolicy: correctnessThreeGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeFour]: {
      instructorGradingPolicy: correctnessFourGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.ForCompletion]: {
      instructorGradingPolicy: completionGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotForPoints]: {
      instructorGradingPolicy: notForPointsDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotFound]: notFound,
  },
  [AssessTypeEnum.Homework]: {
    [MultipleAttemptPolicyEnum.CorrectnessTypeOne]: {
      instructorGradingPolicy: correctnessTypeOneGradingPolicy,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeTwo]: {
      instructorGradingPolicy: correctnessTwoGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeThree]: {
      instructorGradingPolicy: correctnessThreeGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeFour]: {
      instructorGradingPolicy: correctnessFourGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.ForCompletion]: {
      instructorGradingPolicy: completionGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotForPoints]: {
      instructorGradingPolicy: notForPointsDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotFound]: notFound,
  },
  [AssessTypeEnum.PracticeTest]: {
    [MultipleAttemptPolicyEnum.CorrectnessTypeFour]: {
      instructorGradingPolicy: correctnessFourGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.ForCompletion]: {
      instructorGradingPolicy: completionGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotForPoints]: {
      instructorGradingPolicy: notForPointsDefault,
      moreInformation: moreInformationDefault,
    },
  },
  [AssessTypeEnum.Prep]: {
    [MultipleAttemptPolicyEnum.CorrectnessTypeOne]: {
      instructorGradingPolicy: correctnessTypeOneGradingPolicy,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeTwo]: {
      instructorGradingPolicy: correctnessTwoGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeThree]: {
      instructorGradingPolicy: correctnessThreeGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.CorrectnessTypeFour]: {
      instructorGradingPolicy: correctnessFourGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.ForCompletion]: {
      instructorGradingPolicy: completionGradingPolicyDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotForPoints]: {
      instructorGradingPolicy: notForPointsDefault,
      moreInformation: moreInformationDefault,
    },
    [MultipleAttemptPolicyEnum.NotFound]: notFound,
  },
};

function Instructions({ assessmentData, attemptPolicy }: {
  assessmentData: {
    assessType: AssessTypeEnum
    dueDate: string
    instructions: string
    lateDate: string | null
    latePenalty: number | null
  }
  attemptPolicy: MultipleAttemptPolicyEnum
}) {
  const { assessType, dueDate, instructions, lateDate, latePenalty } = assessmentData;
  const colSpots = instructions ? 6 : 12;
  const renderReminderText = (typeOfAssessment: AssessTypeEnum, multiAttemptPolicy: MultipleAttemptPolicyEnum) => {
    if (typeOfAssessment === AssessTypeEnum.Summative) {
      return null;
    }
    const instructionsObject = instructionsData[typeOfAssessment][multiAttemptPolicy];
    if (!instructionsObject) {
      return null;
    }
    const { instructorGradingPolicy, moreInformation } = instructionsObject;
    const latePolicy = lateDate && latePenalty !== null && dueDate !== lateDate ? `You can complete this assignment for ${100 - latePenalty}% of points until ${DateTime.fromISO(lateDate).toFormat(DateFormatEnum.WeekdayDateAtTime)}.` : '';
    return (
      <div className="instructions__content-text">
        <ul>
          <li><strong>Your instructor's grading policy:</strong> {instructorGradingPolicy}</li>
          {!!latePolicy && <li><strong>Late policy:</strong> {latePolicy}</li>}
          <li>{moreInformation}</li>
        </ul>
      </div>
    );
  };
  // TODO: CA-454 capture and display instructor name
  const instructorName = 'Your Instructor';
  return (
    <div className="instructions__content row">
      {instructions && (
        <div className={`col-xs-${colSpots}`}>
          <div className="instructions__content-title">From { instructorName }</div>
          <div className="instructions__content-text">
            { instructions }
          </div>
        </div>
      )}
      <div className={`col-xs-${colSpots}`}>
        <div className="instructions_infobox-title">About this Assignment</div>
        { renderReminderText(assessType, attemptPolicy) }
      </div>
    </div>
  );
}

Instructions.propTypes = {
  assessmentData: PropTypes.shape({
    assessType: PropTypes.oneOf(Object.values(AssessTypeEnum) as Array<AssessTypeEnum>).isRequired,
    dueDate: PropTypes.string.isRequired,
    instructions: PropTypes.string,
    lateDate: PropTypes.string,
    latePenalty: PropTypes.number,
  }).isRequired,
  attemptPolicy: attemptPolicyProp.isRequired,
};

export default Instructions;
