import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { TopicApi } from 'types/backend/topics.types';

interface TopicWithLos {
  topicId: number
  topic: TopicApi
  topicClos: Array<EnrichedCourseLearningObjective>
}

export const breakdownCourseLosByTopic = (courseLos: Array<EnrichedCourseLearningObjective>) => {
  return courseLos.reduce((acc, clo) => {
    const { topic } = clo;
    const foundItem = acc.find(({ topicId }) => topicId === topic.id);
    if (!foundItem) {
      return [
        ...acc,
        {
          topic,
          topicId: topic.id,
          topicClos: [clo],
        },
      ];
    }
    if (!foundItem.topicClos.find((topicClo) => topicClo.id === clo.id)) {
      foundItem.topicClos.push(clo);
    }
    return acc;
  }, [] as Array<TopicWithLos>);
};
