import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import apiNext from 'api-next';
import { useAppSelector, useAppDispatch } from 'store';
import LoginBox from './LoginBox';
import userSlice from 'store/slices/user';
import './MfaTokenVerification.scss';

export default function MfaTokenVerification() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);

  const [errorMsg, setErrorMsg] = useState('');
  const [{ otp, numInputs, separator }, setConfig] = useState({
    otp: '',
    numInputs: 6,
    separator: '',
    minLength: 0,
    maxLength: 40,
    inputType: 'text' as const,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const authUser = await apiNext.mfaLogin(otp, user.id);
      if (authUser) {
        dispatch(userSlice.actions.setUser(authUser));
      } else {
        setErrorMsg('There was a problem verifying your code.');
      }
    } catch (err: any) {
      setErrorMsg(err?.response?.data?.message || err.message);
    }
  };

  const handleBackToLogin = () => {
    dispatch(userSlice.actions.clear());
    history.push('/login');
  };

  const handleOTPChange = (newOtp: string) => {
    setConfig((prevConfig) => ({ ...prevConfig, otp: newOtp }));
  };

  return (
    <LoginBox title="2FA Authentication">
      <form onSubmit={handleSubmit}>
        <p>Enter 6-digit code from your email</p>
        <div className="mfa-token-container">
          <div className="mfa-token-verification">
            <OtpInput
              inputStyle="code-input"
              numInputs={numInputs}
              onChange={handleOTPChange}
              renderSeparator={separator}
              value={otp}
              inputType='text'
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
            />
          </div>
          <div className="btn-action-row">
            <input type="submit" value="Verify" className="verify-btn" disabled={otp.length < numInputs}/>
          </div>
          <div role="alert">
            {errorMsg !== '' && (
              <div className="verify-error">
                {errorMsg}
                { errorMsg === 'Code expired' && (
                  <div className="login__email_block">
                    <a href="/login" onClick={handleBackToLogin}>Back to Login</a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </LoginBox>
  );
}
