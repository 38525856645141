import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { AppDispatch, Store } from 'types/store.types';

// TODO: this could be optimized to support an array of assessmentIds instead of all of them every time.
export default function loadStudentAssessmentsAndStudentAssessmentQuestions() {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { assessmentQuestionMaps, enrollment } } = getStore();

    //load studentAssessments
    let studentAssessments: Array<StudentAssessmentApi> = [];
    let studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas> = [];
    let newAssessmentQuestions: Array<AssessmentQuestionApi> = [];
    let assessmentIdsToReload = [] as Array<string>;
    if (enrollment) {
      studentAssessments = await apiNext.getStudentAssessmentsByEnrollment(enrollment.id);
      assessmentIdsToReload = studentAssessments.map(sa => sa.assessmentId);
      studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions(studentAssessments.map(sa => sa.id)) as Array<StudentAssessmentQuestionApiWithSaqas>;
      newAssessmentQuestions = await apiNext.getAssessmentQuestionMaps(assessmentIdsToReload);
    }

    //TODO: optimize updating state with only changes
    const updatedAQs = assessmentQuestionMaps.reduce((acc, cur) => {
      if (assessmentIdsToReload.includes(cur.assessmentId)) {
        return acc;
      } else {
        return [
          ...acc,
          cur,
        ];
      }
    }, [...newAssessmentQuestions]);

    // note that this combines what would normally be THREE actions into a single one.
    // There are some batch options available as part of Redux and the RTK probably has this built in. Future TODO
    dispatch(activeSlice.actions.setStudentAssessmentsAndStudentAssessmentQuestionsAndAqms({ studentAssessments, studentAssessmentQuestions, assessmentQuestionMaps: updatedAQs }));
  })();
}
