import styled, { css } from 'styled-components';

interface AssessmentWrapProps {
  readonly muddyClear: { [key: string]: boolean }
  readonly stage: string
}

const muddyClearCSS = css`
  position: absolute;
  right: 2px;
  top: 2px;
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
`;

export const AssessmentContainerWrap = styled.div<AssessmentWrapProps>`
  .overlay__button-wrap {
    display: flex;
    flex-direction: row;
  }
  .lrn-assess-ul.slides-vertical-pagination li {
    ${p => Object.values(p.muddyClear).map((isMuddy, i) => css`
      &:nth-child(${i + 1}) {
        .pagination-item-number {
          &::after {
            content: ' ';
            background-image: url(${isMuddy ? 'muddy.svg' : ''});
            ${muddyClearCSS}
          }
        }
      }
    `)}
  }
`;
